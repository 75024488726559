import './index.css'
import './sentry'
import './i18n'
import React, { Suspense } from 'react'

import App from './App'
import ReactDOM from 'react-dom/client'
import reportWebVitals from './reportWebVitals'
import { LoadingBolt } from './components/LoadingBolt'
import ZoomVideo from '@zoom/videosdk'
import { persistSessionParameters } from './helpers/urls'

ZoomVideo.preloadDependentAssets()
persistSessionParameters()

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
    <Suspense fallback={<LoadingBolt />}>
        <React.StrictMode>
            <App />
        </React.StrictMode>
    </Suspense>
)

reportWebVitals()
