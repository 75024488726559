import { useZoomContext } from '../../../../context/ZoomContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useCallback } from 'react'
import { SidebarModeType } from '../../types/types'
import { MediaBtnContainer } from '../../../pre-meeting/components/Buttons'
import { Tip } from '../../../../components/Tip'
import { useTranslation } from 'react-i18next'

export const ParticipantsIcon = (props: ChatIconProps) => {
    const { t } = useTranslation('meeting')
    const { sidebarMode, setSidebarMode } = useZoomContext()

    const onToggleSidebar = useCallback(
        (toggle: SidebarModeType) => {
            setSidebarMode(oldSidebarMode => {
                return oldSidebarMode === toggle ? null : toggle
            })
        },
        [setSidebarMode]
    )

    return (
        <div>
            <MediaBtnContainer
                active={sidebarMode === 'participants'}
                square
                onClick={e => {
                    e.stopPropagation()
                    onToggleSidebar('participants')
                }}
                data-tooltip-id="participants-pre-action"
            >
                <FontAwesomeIcon icon={['far', 'users']} fixedWidth />
            </MediaBtnContainer>
            <Tip
                id="participants-pre-action"
                content={
                    sidebarMode === 'participants'
                        ? t('participants.hideParticipants')
                        : t('participants.showParticipants')
                }
            />
        </div>
    )
}

interface ChatIconProps extends React.HTMLAttributes<HTMLButtonElement> {}
