import React, { createContext, useContext, useState, useEffect, useRef } from 'react'
import { BaseVideo, ComputedFields, OnboardingConfig, RootConfiguration } from '../pages/onboarding/types/types'
import { useUserCourses } from '../pages/meeting-view/hooks/useCourses'
import axios from 'axios'
import { useVideoPersistence } from '../pages/onboarding/hooks/useVideoPersistence'
import { getOnboardingData } from '../apis/api-content'
import { useMe } from '../pages/meeting-view/hooks/useMe'

interface OnboardingContextType {
    isLoading: boolean
    isCourseLoading: boolean
    error: Error | null
    config: RootConfiguration | null
    onboardingConfig: OnboardingConfig | null
    dailyContent: RootConfiguration['dailyContent'] | null
    computed: ComputedFields | null
    getAllVideos: () => BaseVideo[]
    getMandatoryVideos: () => BaseVideo[]
    getVideoById: (id: string) => BaseVideo | undefined
    updateVideoStatus: (id: string, completed: boolean) => void
    showCover: boolean
    setShowCover: (show: boolean) => void
    isInitialLoad: boolean
    setIsInitialLoad: (isInitial: boolean) => void
}

const OnboardingContext = createContext<OnboardingContextType | undefined>(undefined)

export const useOnboarding = () => {
    const context = useContext(OnboardingContext)
    if (!context) {
        throw new Error('useOnboarding must be used within an OnboardingProvider')
    }
    return context
}

interface OnboardingProviderProps {
    children: React.ReactNode
}

// Helper to check if data has full onboarding structure
const isFullOnboardingData = (data: any): boolean => {
    return data && data.dailyContent && data.dailyContent.onboarding
}

export const OnboardingProvider: React.FC<OnboardingProviderProps> = ({ children }) => {
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState<Error | null>(null)
    const [config, setConfig] = useState<RootConfiguration | null>(null)
    const [showCover, setShowCover] = useState(true)
    const [isInitialLoad, setIsInitialLoad] = useState(true)
    const { currentCourse, isLoading: isCourseLoading } = useUserCourses()
    const { setWatchedVideo, getWatchedVideos } = useVideoPersistence()
    useMe()

    const hasFetchedRef = useRef(false)
    const courseIdRef = useRef<string | null>(null)

    useEffect(() => {
        const shouldFetch =
            !hasFetchedRef.current || (currentCourse?.courseId && courseIdRef.current !== currentCourse.courseId)

        if (!currentCourse || isCourseLoading || !shouldFetch) {
            console.log('OnboardingContext: Skipping fetch', {
                hasFetched: hasFetchedRef.current,
                currentCourseId: currentCourse?.courseId,
                previousCourseId: courseIdRef.current,
                isCourseLoading,
            })
            return
        }
        console.log('OnboardingContext: Fetching config for course', { currentCourse })

        const fetchConfig = async () => {
            setIsLoading(true)
            console.log('OnboardingContext: Started loading config')

            try {
                let data
                const courseId = currentCourse.courseId
                courseIdRef.current = courseId

                try {
                    const onboardingData = await getOnboardingData()

                    // Check if onboarding is disabled
                    if (!onboardingData?.onboardingConfig?.enabled) {
                        console.log('OnboardingContext: Onboarding is disabled')
                        setConfig({
                            onboardingConfig: { enabled: false },
                            dailyContent: { onboarding: { videos: [] } },
                            computed: null,
                        } as unknown as RootConfiguration)
                        hasFetchedRef.current = true
                        return
                    }

                    // Handle full onboarding data
                    data = onboardingData
                    console.info('OnboardingContext: Retrieved onboarding data from API:', onboardingData)
                } catch (err) {
                    console.log('OnboardingContext: Falling back to default config')
                    const response = await axios.get(
                        `https://egg-meet-assets.s3.us-east-1.amazonaws.com/onboarding/onboarding-config-default.json`
                    )
                    data = response.data
                }

                // Verify we have the expected data structure
                if (!isFullOnboardingData(data)) {
                    throw new Error('Invalid onboarding data structure')
                }

                // Merge persisted completion status with config
                const watchedVideos = getWatchedVideos()
                console.log('OnboardingContext: Retrieved watched videos from storage:', watchedVideos)

                const updateVideoCompletion = (videos: BaseVideo[]) => {
                    return videos.map(video => ({
                        ...video,
                        completed: video.completed || watchedVideos.includes(video.id),
                    }))
                }

                const updatedConfig = {
                    ...data,
                    dailyContent: {
                        ...data.dailyContent,
                        onboarding: {
                            ...data.dailyContent.onboarding,
                            videos: updateVideoCompletion(data.dailyContent.onboarding.videos),
                        },
                    },
                }

                hasFetchedRef.current = true
                console.log('OnboardingContext: Setting config with persisted state')
                setConfig(updatedConfig)
            } catch (error) {
                console.error('OnboardingContext: Error fetching config:', error)
                setError(error as Error)
            } finally {
                console.log('OnboardingContext: Finished loading config')
                setIsLoading(false)
            }
        }

        fetchConfig()
    }, [currentCourse?.courseId, isCourseLoading])

    const getAllVideos = (): BaseVideo[] => {
        if (!config || !config.dailyContent?.onboarding?.videos) return []
        return config.dailyContent.onboarding.videos
    }

    const getMandatoryVideos = (): BaseVideo[] => {
        return getAllVideos().filter(video => video.type === 'mandatory')
    }

    const getVideoById = (id: string): BaseVideo | undefined => {
        return getAllVideos().find(video => video.id === id)
    }

    const updateVideoStatus = (id: string, completed: boolean) => {
        if (!config || !config.dailyContent?.onboarding?.videos) return

        if (completed) {
            setWatchedVideo(id)
        }

        setConfig(prevConfig => {
            if (!prevConfig) return null

            const newConfig = JSON.parse(JSON.stringify(prevConfig))
            const videos = newConfig.dailyContent.onboarding.videos
            const videoIndex = videos.findIndex((v: { id: string }) => v.id === id)

            if (videoIndex !== -1) {
                videos[videoIndex] = { ...videos[videoIndex], completed }
            }

            return newConfig
        })
    }

    const value = {
        isLoading,
        isCourseLoading,
        error,
        config,
        onboardingConfig: config?.onboardingConfig ?? null,
        dailyContent: config?.dailyContent ?? null,
        computed: config?.computed ?? null,
        getAllVideos,
        getMandatoryVideos,
        getVideoById,
        updateVideoStatus,
        showCover,
        setShowCover,
        isInitialLoad,
        setIsInitialLoad,
    }

    return <OnboardingContext.Provider value={value}>{children}</OnboardingContext.Provider>
}

export type { OnboardingContextType, BaseVideo, OnboardingConfig, ComputedFields }
