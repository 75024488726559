import { MeetingErrorType } from '../../components/Video/ErrorComponent'
import { useZoomContext } from '../../../../context/ZoomContext'

export const useMeetingError = () => {
    const { setSidebarMode } = useZoomContext()

    const handleError = ({ errorType, payload }: MeetingErrorType) => {
        setSidebarMode(null)
        return { errorType, payload }
    }

    return {
        handleError,
    }
}
