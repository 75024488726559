import * as Sentry from '@sentry/react'

interface SentryMessage {
    lastMessage: string
    lastTimestamp: number
}

interface SystemRequirements {
    audio: boolean
    video: boolean
    screen: boolean
}

interface FeatureRequirements {
    platform: string
    supportFeatures: string[]
    unSupportFeatures: string[]
}

interface ZoomContext {
    bVideoOn?: boolean
    isHost?: boolean
    topic?: string
    sessionId?: string
    userId?: number
    displayName?: string
    sharerOn?: boolean
}

interface SessionParameters {
    courseName?: string
    room?: string
    _classId?: string
    _algorithmId?: string
}

const messageCache: Record<string, SentryMessage> = {}
const DEBOUNCE_TIME = 5000

export const setSessionTags = (params: SessionParameters) => {
    try {
        Sentry.setTags({
            course_name: params.courseName || 'unknown',
            room: params.room || 'unknown',
            class_id: params._classId || 'unknown',
            algorithm_id: params._algorithmId || 'unknown',
        })
    } catch (error) {
        console.error('Failed to set Sentry tags:', error)
    }
}

export const setSentryContextAndTags = (context: ZoomContext) => {
    try {
        if (context.bVideoOn !== undefined) {
            Sentry.setTag('video_on', context.bVideoOn.toString())
        }
        if (context.isHost !== undefined) {
            Sentry.setTag('is_host', context.isHost.toString())
        }
        if (context.topic) {
            Sentry.setTag('topic', context.topic)
        }
        if (context.sessionId) {
            Sentry.setTag('session_id', context.sessionId)
        }

        const userContext: Record<string, unknown> = {}
        if (context.userId) userContext.zoomUserId = context.userId
        if (context.displayName) userContext.displayName = context.displayName
        if (context.isHost !== undefined) userContext.isHost = context.isHost
        if (context.bVideoOn !== undefined) userContext.bVideoOn = context.bVideoOn
        if (context.sharerOn !== undefined) userContext.sharerOn = context.sharerOn
        if (Object.keys(userContext).length) {
            Sentry.setContext('user', userContext)
        }

        const sessionContext: Record<string, string> = {}
        if (context.topic) sessionContext.topic = context.topic
        if (context.sessionId) sessionContext.sessionId = context.sessionId
        if (Object.keys(sessionContext).length) {
            Sentry.setContext('session', sessionContext)
        }
    } catch (error) {
        console.error('Failed to set Sentry context:', error)
    }
}

export const logSystemRequirements = (requirements: SystemRequirements) => {
    const missingRequirements = Object.entries(requirements)
        .filter(([, value]) => !value)
        .map(([key]) => key)

    if (missingRequirements.length > 0) {
        Sentry.withScope(scope => {
            scope.setTags({
                missing_requirements: missingRequirements.join(','),
                requirements_check: 'failed',
            })
            logToSentry(`Missing system requirements: ${missingRequirements.join(', ')}`, 'system_requirements')
        })
    }
}

export const logFeatureRequirements = (features: FeatureRequirements) => {
    if (features.unSupportFeatures.length > 0) {
        Sentry.withScope(scope => {
            scope.setTags({
                platform: features.platform,
                unsupported_features: features.unSupportFeatures.join(','),
                feature_check: 'failed',
            })
            logToSentry(
                `Unsupported features for ${features.platform}: ${features.unSupportFeatures.join(', ')}`,
                'feature_requirements'
            )
        })
    }
}

export const logToSentry = (
    message: string,
    category?: string,
    p0?: { error: string; errorName: string; status: string }
) => {
    const key = category ? `${category}:${message}` : message
    const now = Date.now()
    const cached = messageCache[key]

    if (cached && now - cached.lastTimestamp < DEBOUNCE_TIME) {
        return
    }

    messageCache[key] = {
        lastMessage: message,
        lastTimestamp: now,
    }

    const deviceType = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
        ? 'mobile'
        : 'desktop'

    Sentry.withScope(scope => {
        scope.setTag('device_type', deviceType)
        Sentry.captureMessage(message, 'log')
    })
}

export const logMediaError = (error: unknown, context?: string) => {
    if (!(error instanceof Error)) {
        logToSentry(`Unknown media error${context ? ` in ${context}` : ''}`, 'media')
        return
    }

    let message: string
    switch (error.name) {
        case 'NotReadableError':
        case 'TrackStartError':
            message = 'Media devices are busy or unavailable'
            break
        case 'NotFoundError':
            message = 'No media devices found'
            break
        case 'NotAllowedError':
        case 'PermissionDeniedError':
            message = 'Media permissions denied by user'
            break
        default:
            message = `Media error: ${error.name}`
    }

    if (context) {
        message = `${message} (${context})`
    }

    Sentry.withScope(scope => {
        scope.setTags({
            error_type: error.name,
            context: context || 'none',
        })
        logToSentry(message, 'media')
    })
}
