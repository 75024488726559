import { IconName, IconPrefix } from '@fortawesome/pro-light-svg-icons'
import styled, { css } from 'styled-components'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

type Props = {
    icon?: [IconPrefix, IconName]
    children?: React.ReactNode
    handleClick?: () => void | null | Promise<any>
    dark?: boolean
    muted?: boolean
    disabled?: boolean
    id?: string
    className?: string
    style?: React.CSSProperties
}

const SquareButton = ({ icon, handleClick, muted, disabled = false, children, ...rest }: Props) => {
    return (
        <SquareButtonStyled onClick={handleClick} mic={muted} disabled={disabled} {...rest}>
            {children ? children : icon ? <FontAwesomeIcon icon={icon} /> : null}
        </SquareButtonStyled>
    )
}

export default SquareButton

export const SquareButtonStyled = styled.button<{ dark?: boolean; mic?: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    width: 40px;
    height: 40px;
    font-size: 16px;
    border-radius: 12px;
    margin: 4px;
    background-color: ${props => props.theme.color.sidebar.gray1};
    border: 1px solid ${props => props.theme.color.sidebar.gray2};
    color: white;
    :not(:disabled) {
        cursor: pointer;
    }
    ${props =>
        props.mic &&
        css`
            color: ${props => props.theme.color.salmon};
            border: none;
        `}
    ${props =>
        props.dark &&
        css`
            background-color: ${props => props.theme.color.sidebar.gray1};
            border: 1px solid ${props => props.theme.color.sidebar.gray2};
            color: white;
            border: none;
        `}
    &:hover:not(:disabled) {
        background: ${props => props.theme.color.sidebar.gray3};
        color: white;
    }
`
