import { getItem, setItem } from '../../../helpers/localStorage'
import { useCallback, useEffect, useState } from 'react'

import ZoomVideo from '@zoom/videosdk'
import { usePreMeetingContext } from '../../../context/PreMeetingContext'
import { useUI } from '../../../context/UIContext'

export const useMyVideo = () => {
    const { localVideoTrack: video, activeCamera, setActiveCamera } = usePreMeetingContext()
    const { showToast } = useUI()
    const [cameras, setCameras] = useState<MediaDeviceInfo[]>([])

    const getCameras = useCallback(async () => {
        const devices = await ZoomVideo.getDevices().then(devices =>
            devices.filter(({ kind }) => kind === 'videoinput')
        )
        setCameras(devices)
        if (devices.length && !getItem('last-cam-id')) setActiveCamera(devices[0])
    }, [setActiveCamera])

    useEffect(() => {
        navigator.mediaDevices.addEventListener('devicechange', getCameras)
        return () => {
            navigator.mediaDevices.removeEventListener('devicechange', getCameras)
        }
    }, [getCameras])

    useEffect(() => {
        getCameras()
    }, [getCameras])

    const switchCamera = async (cameraId: string) => {
        if (!video) return
        try {
            await video.switchCamera(cameraId).catch(console.error)
            const camera = cameras.find(({ deviceId }) => deviceId === cameraId)
            setActiveCamera(camera || null)
            setItem('last-cam-id', camera)
            showToast(`Usando ${camera?.label}`, { id: `switch-${camera?.deviceId}` })
        } catch (error) {
            console.error('Error switching camera', error)
        }
    }

    return {
        activeCamera,
        cameras,
        switchCamera,
    }
}
