import './video-footer.scss'

import { Blur } from './Blur'
import { ChatIcon } from './ChatIcon'
import { ControlsStyled } from './ControlsStyled'
import { LeaveButton } from './Leave'
import { Microphone } from './Microphone'
import { MutableRefObject } from 'react'
import { ParticipantsIcon } from './ParticipantsIcon'
import { RaiseHand } from './RaiseHand'
import { Recording } from './Recording'
import { RoomName } from '../RoomName'
import { ScreenShare } from './ScreenShare'
import { VIDEO_FOOTER_ID } from '../../util/video-constants'
import { useZoomContext } from '../../../../context/ZoomContext'
import { getDeviceType } from '../../util/platform'
import CameraButton from './Camera'
import Separator from '../../../../components/Separator'
import { HelpRequest } from './HelpRequest'

const Controls = ({ selfShareRef }: Props) => {
    const { sidebarMode, isVideoStarted } = useZoomContext()
    const type: 'mobile' | 'desktop' = getDeviceType()

    return (
        <ControlsStyled showSidebar={sidebarMode} id={VIDEO_FOOTER_ID}>
            {type !== 'mobile' && <RoomName />}
            {type !== 'mobile' && <Separator />}
            {!isVideoStarted && <CameraButton />}
            <Microphone />
            {type !== 'mobile' && <Blur />}
            {type !== 'mobile' && <Recording />}
            {type !== 'mobile' && <ScreenShare selfShareRef={selfShareRef} />}
            {type !== 'mobile' && <RaiseHand />}
            {type !== 'mobile' && <HelpRequest />}
            {type !== 'mobile' && <ChatIcon />}
            {type !== 'mobile' && <ParticipantsIcon />}
            <LeaveButton />
        </ControlsStyled>
    )
}

interface Props {
    selfShareRef: MutableRefObject<HTMLCanvasElement | null>
}

export default Controls
