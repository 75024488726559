import Sidebar from '../pages/meeting-view/components/Sidebar/Sidebar'
import styled from 'styled-components'
import { useZoomContext } from '../context/ZoomContext'
import { LoadingBolt } from '../components/LoadingBolt'

type LayoutProps = {
    children: React.ReactNode
    token?: string
    isLoading?: boolean
}

const MeetingLayout = ({ children, isLoading = false }: LayoutProps) => {
    const { sidebarMode } = useZoomContext()

    if (isLoading) return <LoadingBolt />
    return (
        <StyledMeetingLayout>
            <div className="container">
                <div className="col-left">
                    <div className="content-display">{children}</div>
                </div>
                <div className={`col-right ${!sidebarMode && 'hidden'}`}>
                    <Sidebar />
                </div>
            </div>
        </StyledMeetingLayout>
    )
}

export default MeetingLayout

const StyledMeetingLayout = styled.div`
    background-color: ${props => props.theme.color.offWhite};
    background-color: aliceblue;
    .container {
        background-color: ${props => props.theme.color.offWhite};
        margin: 0 auto 0 auto;
        box-sizing: border-box;
        .col-left {
        }
        .col-right {
            display: block;
            padding: 20px;
            box-sizing: border-box;
            position: absolute;
            right: 0;
            top: 0;
            height: 100%;
            transition: all 0.5s ease-in-out;
            width: 420px;
            z-index: 20000;
            &.hidden {
                right: -420px;
                @media only screen and (max-width: 768px) {
                    display: none;
                }
            }
            @media only screen and (max-width: 768px) {
                padding-bottom: 60px;
            }
        }
    }
`
