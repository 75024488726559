import { useTranslation } from 'react-i18next'
import { CloseMeeting } from '../components/CloseMeeting'
import { Feedback } from '../components/Feedback'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SalmonBtn } from '../../../components/Buttons'
import { useChangeMeeting } from '../../../hooks/useChangeMeeting'

export const External = () => {
    const { t } = useTranslation('postmeeting')
    const { changeMeeting } = useChangeMeeting()

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '15px',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <div className="flex column">
                <div className="icon rounded-icon">
                    <FontAwesomeIcon icon={['far', 'video']} />
                </div>
                <h1>{t('external.joinGeneralMeeting')}</h1>
            </div>
            <Feedback />
            <div className="row-3">
                <p>{t('external.returnInstructions')}</p>
                <SalmonBtn onClick={() => changeMeeting('general', { immediate: true })}>
                    <FontAwesomeIcon icon={['fas', 'circle-video']} className="button-icon" />
                    {t('external.enterGeneralMeeting')}
                </SalmonBtn>
                <CloseMeeting />
            </div>
        </div>
    )
}
