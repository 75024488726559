import { Dispatch, SetStateAction, createContext, useContext, useRef, useState } from 'react'
import ZoomVideo, { LocalAudioTrack, LocalVideoTrack } from '@zoom/videosdk'
import { getItem } from '../helpers/localStorage'

const PreMeetingContext = createContext<PreMeetingContextType | undefined>(undefined)
PreMeetingContext.displayName = 'UIContext'

type Props = {
    children: React.ReactNode
}

const lastCam = getItem('last-cam-id') as MediaDeviceInfo | null
const lastCamId = lastCam ? lastCam.deviceId : undefined
let localVideoTrack = ZoomVideo.createLocalVideoTrack(lastCamId) as LocalVideoTrack & { isVideoStarted: boolean }
let localAudioTrack = ZoomVideo.createLocalAudioTrack()

export function PreMeetingProvider({ children }: Props) {
    const canvasRef = useRef<HTMLCanvasElement | null>(null)
    const videoRef = useRef<HTMLVideoElement | null>(null)

    // Always start with VB mode disabled, regardless of stored preferences
    const [isInVBMode, setIsInVBMode] = useState<boolean>(false)
    const [isStartedVideo, setIsStartedVideo] = useState(false)
    const [isAudioMuted, setIsAudioMuted] = useState(() => {
        const storedValue = localStorage.getItem('pre-mute-state')
        return storedValue !== null ? JSON.parse(storedValue) : false
    })

    const [activeCamera, setActiveCamera] = useState<MediaDeviceInfo | null>(() => getItem('last-cam-id'))
    const [activeMic, setActiveMic] = useState<MediaDeviceInfo | null>(() => getItem('last-mic-id'))
    const [activeSpeaker, setActiveSpeaker] = useState<MediaDeviceInfo | null>(() => getItem('last-speaker-id'))

    const values = {
        localVideoTrack,
        localAudioTrack,
        isInVBMode,
        setIsInVBMode,
        isStartedVideo,
        setIsStartedVideo,
        isAudioMuted,
        setIsAudioMuted,
        canvasRef,
        videoRef,
        activeCamera,
        setActiveCamera,
        activeMic,
        setActiveMic,
        activeSpeaker,
        setActiveSpeaker,
    }
    return <PreMeetingContext.Provider value={values}>{children}</PreMeetingContext.Provider>
}

export const usePreMeetingContext = () => {
    const context = useContext(PreMeetingContext)
    if (context === undefined) {
        throw new Error('usePreMeetingContext must be used within a PreMeetingProvider')
    }
    return context
}

export interface PreMeetingContextType {
    localVideoTrack: LocalVideoTrack & { isVideoStarted: boolean }
    localAudioTrack: LocalAudioTrack
    isInVBMode: boolean
    setIsInVBMode: Dispatch<SetStateAction<boolean>>
    isStartedVideo: boolean
    setIsStartedVideo: Dispatch<SetStateAction<boolean>>
    isAudioMuted: boolean
    setIsAudioMuted: Dispatch<SetStateAction<boolean>>
    canvasRef: React.MutableRefObject<HTMLCanvasElement | null>
    videoRef: React.MutableRefObject<HTMLVideoElement | null>
    activeCamera: MediaDeviceInfo | null
    setActiveCamera: Dispatch<SetStateAction<MediaDeviceInfo | null>>
    activeMic: MediaDeviceInfo | null
    setActiveMic: Dispatch<SetStateAction<MediaDeviceInfo | null>>
    activeSpeaker: MediaDeviceInfo | null
    setActiveSpeaker: Dispatch<SetStateAction<MediaDeviceInfo | null>>
}
