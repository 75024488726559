import { useTranslation } from 'react-i18next'
import { useLanguages } from '../hooks/useLanguages'
import styled from 'styled-components'
import { useState } from 'react'
import { MediaBtnContainer } from '../pages/pre-meeting/components/Buttons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Tip } from './Tip'
import { StyledDropdownOptions } from '../pages/meeting-view/components/Misc/StyledDropdownOptions'
import i18n from 'i18next'

export const LanguageSelector = ({ labels = 'names', onSelect, label }: LanguageSelectorProps) => {
    const { t } = useTranslation('translation')
    const { langs, userLang } = useLanguages()
    const [isOpen, setIsOpen] = useState(false)

    return (
        <LanguageSelectorStyled>
            <MediaBtnContainer className="wide accent" onClick={() => null} style={{ fontWeight: 'bold' }} as="div">
                <FontAwesomeIcon
                    className={'base-icon'}
                    icon={['far', 'language']}
                    fixedWidth
                    style={{ marginRight: '5px' }}
                />
                <button id="language-switch">
                    <FontAwesomeIcon icon={['fas', 'chevron-up']} flip={isOpen ? 'vertical' : undefined} fixedWidth />
                </button>
            </MediaBtnContainer>

            <Tip
                id="language-switch-ele"
                anchorSelect="#language-switch"
                positionStrategy="fixed"
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                clickable
                globalCloseEvents={{ escape: true, clickOutsideAnchor: true }}
                role="dialog"
                className="wide-tooltip"
            >
                <div className={'sd'}>
                    <StyledDropdownOptions>
                        <div className="devices-group">
                            <h3 className="label">{t('Preferred language')}</h3>
                            {Object.entries(langs ?? {}).map(([key, value]) => {
                                const isSelected = userLang.resolvedLanguage === key
                                return (
                                    <li key={key}>
                                        <button
                                            onClick={() => i18n.changeLanguage(key)}
                                            className={isSelected ? 'active' : ''}
                                        >
                                            {labels === 'keys' ? key : (value as { nativeName: string }).nativeName}
                                        </button>
                                    </li>
                                )
                            })}
                        </div>
                    </StyledDropdownOptions>
                </div>
            </Tip>
        </LanguageSelectorStyled>
    )
}

type LanguageSelectorProps = {
    labels?: 'names' | 'keys'
    onSelect?: (language: string) => void
} & any

const LanguageSelectorStyled = styled.fieldset`
    position: absolute;
    bottom: 15px;
    right: 15px;
    border: none;
`
