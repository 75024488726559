import Axios from 'axios'
import { ComponentProps } from 'react'
import { MeetingRoom } from '../pages/meeting-view/api'

const { REACT_APP_EGG_API } = process.env

export const ApiEgg = Axios.create({
    baseURL: REACT_APP_EGG_API,
})

export const getTimeline = (courseId?: string) =>
    ApiEgg.get<GetTimelineResponse>(`/class/${courseId}/timeline`).then(({ data }) => data.data)

export const updateTimelineEvent = ({
    courseId,
    event,
}: {
    courseId: string
    event: Partial<TimelineEventWithPartialSettings>
}) => ApiEgg.patch(`/class/${courseId}/timeline`, event)

export const signInToAdmin = (id: string, courseId: string) =>
    ApiEgg.get<GetSuperAdminToken>(`/user/${id}/customToken`).then(({ data }) => {
        const { adminUrl, customToken } = data.data
        window.open(
            `${adminUrl}/login/superadmin?token=${customToken}&redirect=/admin/overview/${courseId}?manage-meetings=true`,
            `admin-${id}-${courseId}`
        )
    })

export const getTeam = (courseID?: string) =>
    ApiEgg.get<GetTeamResponse>(`/v2/course/${courseID}`).then(({ data }) => data.data)

export const getTeamSettings = (courseId: string) =>
    ApiEgg.get<TeamSettings>(`/v2/course/${courseId}/settings`).then(({ data }) => data.data.settings)

export interface GetTimelineResponse {
    data: {
        timeline: TimelineEvent[]
        currentEvent?: TimelineEvent
        nextEvent?: TimelineEvent
        _courseId: string
    }
}

export interface GetSuperAdminToken {
    data: {
        user: {
            _id: string
            name: string
            lastname: string
            email: string
        }
        customToken: string
        adminUrl: string
    }
}

export interface TimelineEvent {
    room: MeetingRoom
    date: string
    status: 'past' | 'current' | 'future'
    _algorithmId?: string
    settings: {
        enabledShareScreen: boolean
        enabledMic: boolean
        externalContent?: {
            url: string
            type: 'video' | 'image' | 'link'
            mute?: boolean
        }
    }
}

export type TimelineEventWithPartialSettings = Omit<TimelineEvent, 'settings'> & {
    settings: Partial<TimelineEvent['settings']>
}

export interface TokenPayload {
    _id: string
    _profileId: string
    _classId: string
    _hqId: string
    role: string
    _teamId: string
    iat: number
    exp: number
}

export interface TextMessage extends ComponentProps<'p'> {
    type: 'text'
    message: React.ReactNode
}

export interface VideoMessage extends ComponentProps<'video'> {
    type: 'video'
    mute?: boolean
}

export type DiffusionMessage = (TextMessage | VideoMessage) & {
    timestamp?: number
}

export interface GetTeamResponse {
    data: {
        _id: string
        teamName: string
        course: {
            _id: string
        }
        hq: {
            _id: string
            name: string
        }
        subject: {
            _id: string
            name: string
        }
    }
}

export interface TeamSettings {
    data: {
        settings: {
            _hqId: string
            _id: string
            active?: boolean
            asyncCourse: boolean
            name: string
            hasSingleViewMeeting?: boolean
        }
    }
    message: string
}
