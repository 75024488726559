import { useTranslation } from 'react-i18next'
import { Participant } from '../types/video-types'
import { SharePermissionType } from '../components/VideoFooter/ScreenShare'
import ToastNotification from '../components/Misc/ToastNotification'
import { setSessionItem } from '../../../helpers/localStorage'
import { useSessionParams } from '../../../hooks/useSessionParams'
import { useTimeline } from '../../../hooks/useTimeline'
import { useUI } from '../../../context/UIContext'
import { useZoomContext } from '../../../context/ZoomContext'

export const useHostFunctions = () => {
    const { t } = useTranslation('hostFunctions')
    const zoom = useZoomContext()
    const { zoomClient, mediaStream, commandChannel, setSidebarMode } = zoom
    const { showToast, showError } = useUI()
    const { currentEvent, updateTimelineEvent } = useTimeline()
    const { _classId: courseId } = useSessionParams()

    const enableMic = () => {
        if (!courseId) return
        updateTimelineEvent({ courseId, event: { ...currentEvent, settings: { enabledMic: true } } })
        commandChannel?.send(JSON.stringify({ type: 'enableMic' }))
        showToast(t('micEnabled'))
    }

    const disableMic = async () => {
        if (!courseId || !mediaStream) return

        try {
            await mediaStream.muteAllAudio()

            updateTimelineEvent({ courseId, event: { ...currentEvent, settings: { enabledMic: false } } })

            showToast(t('allParticipantsMuted'))

            commandChannel?.send(JSON.stringify({ type: 'disableMic' }))
        } catch (error) {
            showToast(t('errorMutingAll'))
            throw new Error(t('errorMutingAll'))
        }
    }

    const muteParticipant = async ({ userId, audio, displayName }: Participant) => {
        if (!mediaStream) return
        try {
            const isMuted = mediaStream.isAudioMuted(userId)
            if (isMuted || !audio) return

            await mediaStream.muteAudio(userId)

            commandChannel?.send(t('micMutedByHost'), userId)

            showToast(t('participantMuted', { displayName }), {
                id: `${userId}`,
            })
            return t('participantMutedSuccess', { displayName })
        } catch (error) {
            showError(t('errorMutingParticipant', { displayName }))
            throw new Error(t('errorMutingParticipantWithReason', { displayName, error }))
        }
    }

    const makeHost = async ({ userId, displayName }: Participant) => {
        try {
            await zoomClient.makeHost(userId)

            commandChannel?.send(t('youAreNowHost'), userId)

            showToast(t('participantIsNowHost', { displayName }), {
                id: `${userId}`,
            })
            setSidebarMode(null)
            return t('participantIsNowHostSuccess', { displayName })
        } catch (error) {
            showError(t('errorMakingHost'))
            throw new Error(t('errorMakingHostWithReason', { displayName, error }))
        }
    }

    const makeManager = async (participant: Participant) => {
        try {
            await zoomClient?.makeManager(participant.userId).catch(() => {})
            commandChannel?.send(t('youAreNowCoHost'), participant.userId)
            showToast(t('participantIsNowCoHost', { displayName: participant.displayName }), {
                id: participant.userGuid,
            })
            setSidebarMode(null)
            return t('participantIsNowCoHostSuccess', { displayName: participant.displayName })
        } catch (error) {
            showError(t('errorMakingCoHost'))
            throw new Error(t('errorMakingCoHostWithReason', { displayName: participant.displayName, error }))
        }
    }

    const removeUser = async (participant: Participant) => {
        try {
            await zoomClient?.removeUser(participant.userId)
            return t('participantRemoved', { displayName: participant.displayName })
        } catch (error) {
            throw new Error(t('errorRemovingParticipant', { displayName: participant.displayName, error }))
        }
    }

    const chatUser = (participant: Participant) => {
        setSidebarMode('messages')
        setSessionItem('receiverId', participant.userId)
    }

    const switchSharePermissions = (shareType: SharePermissionType) => {
        switch (shareType) {
            case SharePermissionType.SHARE_ALL:
                mediaStream?.setSharePrivilege(3)
                showToast(
                    <ToastNotification
                        icon={['far', 'circle-info']}
                        iconStyle={{ color: '#48A0F8' }}
                        msg={t('allCanShareScreen')}
                    />,
                    {
                        id: shareType,
                    }
                )
                break
            case SharePermissionType.SHARE_COACH:
                mediaStream?.setSharePrivilege(1)
                showToast(
                    <ToastNotification
                        icon={['far', 'circle-info']}
                        iconStyle={{ color: '#48A0F8' }}
                        msg={t('onlyCoachAndHostCanShareScreen')}
                    />,
                    {
                        id: shareType,
                    }
                )
                break
        }
    }

    return {
        enableMic,
        disableMic,
        muteParticipant,
        makeHost,
        makeManager,
        removeUser,
        chatUser,
        switchSharePermissions,
    }
}
