import styled, { css } from 'styled-components'
interface Props {
    colorTheme?: 'light' | 'dark'
    fz?: 'sm' | 'md' | 'lg'
}

export const PrimaryButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    font-family: 'Poppins', sans-serif;
    height: 50px;
    width: 283px;
    background: ${props => props.theme.color.primary} 0% 0% no-repeat padding-box;
    border-radius: 8px;
    border: none;
    color: white;
    font-weight: 600;
    font-size: 16px;
    /* margin: 18px 0; */
    padding: 0px 22px;
    &:disabled {
        background: ${props => props.theme.color.lightGray} 0% 0% no-repeat padding-box;
        color: ${props => props.theme.color.gray};
    }
    &:hover:not(:disabled) {
        background-color: ${props => props.theme.color.blue};
    }
    cursor: pointer;
    .btn-icon {
        margin-right: 10px;
        font-size: 16px;
    }
    @media (max-width: 720px) {
        width: 100%;
    }
`

export const PrimaryBtnInverted = styled(PrimaryButton)`
    background: white;
    color: ${props => props.theme.color.electricBlue};
    margin: 0;
    border: 1px solid ${props => props.theme.color.borders};
    &:hover:not(:disabled) {
        background: ${props => props.theme.color.lightGray};
        color: white;
    }
`

export const PrimaryBtnBlue = styled(PrimaryButton)`
    background: #00a3ff;
    color: white;
    margin: 0;
    &:hover:not(:disabled) {
        background: #0095ea;
        color: white;
    }
`

export const PrimaryBtnBlueShort = styled(PrimaryBtnBlue)`
    width: auto;
    font-size: 14px;
    padding: 0px 10px;
    height: 45px;
`

export const PrimaryBtnInvertedBlue = styled(PrimaryButton)`
    background: white;
    color: ${props => props.theme.color.primaryOpacity};
    margin: 0;
    &:hover:not(:disabled) {
        color: ${props => props.theme.color.lightBlue};
        background: none;
    }
    &:disabled {
        background: 0;
    }
`

export const PrimaryBtnOutline = styled(PrimaryButton)<Props>`
    // border should be 1px for sm, 2 px for md, 3px for lg
    border: ${props => (props.fz === 'sm' ? '2px' : props.fz === 'md' ? '2px' : '3px')} solid
        ${props => (props.colorTheme === 'light' ? props.theme.color.sidebar.gray3 : props.theme.color.primary)};
    /* border: 3px solid ${props => (props.colorTheme === 'light' ? 'white' : props.theme.color.primary)}; */
    background-color: transparent;
    color: ${props => (props.colorTheme === 'light' ? 'white' : props.theme.color.primary)};
    // font size is 12 for sm 14 for md and 16 for lg
    font-size: ${props => (props.fz === 'sm' ? '11px' : props.fz === 'md' ? '14px' : '16px')};
    &:hover:not(:disabled) {
        background-color: ${props =>
            props.colorTheme === 'light' ? props.theme.color.sidebar.gray3 : props.theme.color.primaryOpacity};
    }
    ${({ colorTheme }) =>
        colorTheme !== 'light' &&
        css`
            &:hover {
                background-color: #0037500a !important;
            }
        `}
`

PrimaryBtnOutline.defaultProps = {
    colorTheme: 'light',
    fz: 'lg',
}

export const SalmonBtn = styled(PrimaryButton)`
    width: max-content;
    border-color: #ff647c;
    background: #ff647c;
    color: white;
    &:hover:not(:disabled) {
        background: #b44758;
        border-color: #b44758;
    }
`

export const LinkBtn = styled(PrimaryButton)`
    background: none;
    &:hover:not(:disabled) {
        text-decoration: underline;
        background: none;
    }
`

export const DangerButton = styled(PrimaryButton)`
    background: #ff647c;
    color: white;
    &:hover:not(:disabled) {
        background: #b44758;
    }
`

export const PrimarySdkButton = styled.button`
    background: none;
    border-radius: 10px;
    border: 1px solid #f2f2f2;
    background: #fff;
    box-shadow: 0px 0px 20px 0px rgba(55, 71, 79, 0.08);
    font-weight: 600;
    width: 100%;
    height: 40px;
    cursor: pointer;
    &:hover {
        background: #f2f2f2;
        color: #37474f;
    }
`

export const LinkBtnNotification = styled.button`
    background: none;
    border: none;
    color: ${props => props.theme.color.electricBlue};
    cursor: pointer;
    font-size: 15px;
    font-weight: bold;
    cursor: pointer;
    padding: 0px;
    margin-left: 8px;
    width: fit-content !important;
    text-decoration: underline;
    &:hover {
        color: rgba(255, 255, 255, 0.6);
    }
    &:disabled {
        cursor: not-allowed;
        opacity: 0.5;
    }
`
