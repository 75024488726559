import React, { useState } from 'react'

import styled from 'styled-components'

interface Props<T> {
    tabs: {
        label: string
        value: T
    }[]
    defaultSelected?: T
    onChange: (value: T) => void
}

export const Tabs = <T extends string>({ tabs, defaultSelected, onChange }: Props<T>) => {
    const [activeTab, setActiveTab] = useState(defaultSelected ?? tabs[0]?.value ?? '')

    const handleOnChange = (value: T) => {
        setActiveTab(value)
        onChange(value)
    }

    return (
        <DoubleTabStyled className="toggle-button">
            {tabs.map(({ label, value }) => (
                <button
                    key={label}
                    className={`flex center tab ${activeTab === value ? 'active' : ''}`}
                    onClick={() => handleOnChange(value)}
                >
                    {label}
                </button>
            ))}
        </DoubleTabStyled>
    )
}

export const DoubleTabStyled = styled.div`
    display: flex;
    border-radius: 8px;
    overflow: hidden;
    width: 100%;
    height: 2.6rem;
    padding: 3px;
    background-color: #383a40;
    gap: 3px;
    .tab {
        flex: 1;
        padding: 10px 20px;
        border: none;
        background-color: #383a40;
        cursor: pointer;
        transition: background-color 0.3s ease;
        font-family: Arial, sans-serif;
        font-size: 14px;
        color: white;
        outline: none;
        text-align: center;
        border-radius: 8px;
    }

    .tab:not(.active):hover {
        background-color: #383a40;
    }

    .tab.active {
        background-color: #57595f;
        color: white;
    }
`
