import { FailOver } from '../FailOver'
import { Participant } from '../../pages/meeting-view/types/video-types'

export const UserFailOver = ({ displayName }: Participant) => (
    <div className="user">
        <div className="top-left">
            <FailOver />
            <span>{displayName}</span>
        </div>
    </div>
)
