import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome'

export const LoadingBolt = (props: Omit<FontAwesomeIconProps, 'icon'>) => (
    <div
        style={{
            width: '100vw',
            height: '100vh',
            position: 'absolute',
            inset: '0',
            backgroundColor: '#141414',
            color: 'white',
            display: 'grid',
            placeItems: 'center',
        }}
    >
        <FontAwesomeIcon
            icon={['fal', 'bolt']}
            beat={true}
            style={{
                ...props.style,
                fontSize: '4em',
                transform: 'unset',
            }}
            {...props}
        />
    </div>
)
