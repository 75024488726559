import { ComponentProps, useEffect, useState } from 'react'

import { Bolt } from '../Bolt'
import { Participant } from '@zoom/videosdk'
import Styles from './PulseData.module.css'
import { usePrevious } from '../../pages/meeting-view/hooks/usePrevious'
import { usePulses } from '../../pages/meeting-view/hooks/usePulses'

export const animateDuration = 5000
export const animateClassName = 'animate-gradient'
export const animateStyle: PulseStyle = {
    '--animate-duration': `${animateDuration}ms`,
}

export const PulseData = ({ userIdentity, className, onClick }: Props) => {
    const [isBeingAnimated, setIsBeingAnimated] = useState(false)
    const { getPulseData } = usePulses()
    const pulse = getPulseData(userIdentity)
    const { received } = pulse ?? {}
    const prevReceived = usePrevious(received)

    // Animates the pulse when it's changed
    useEffect(() => {
        if (received === prevReceived || (!prevReceived && prevReceived !== 0)) return

        setIsBeingAnimated(true)

        new Promise(resolve => setTimeout(resolve, animateDuration)).then(() => setIsBeingAnimated(false))
    }, [received, prevReceived])

    if (!pulse) return null

    return (
        <div
            onClick={onClick}
            className={`${Styles.pulse} ${isBeingAnimated ? animateClassName : ''} ${className ?? ''}`}
            style={animateStyle}
        >
            <Bolt />
            {(received || 0) > 0 && <span>{received}</span>}
        </div>
    )
}

type Props = Pick<Participant, 'userIdentity'> & Pick<ComponentProps<'div'>, 'onClick' | 'className'>
type PulseStyle = React.CSSProperties & { '--animate-duration'?: string }
