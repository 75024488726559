import { Api } from './apis'
import Axios from 'axios'
import DayJS from '../helpers/DayJS'
import { DurationUnitType } from 'dayjs/plugin/duration'
const { REACT_APP_EGG_API_SIGNUP } = process.env

export const ApiSignUp = Axios.create({
    baseURL: REACT_APP_EGG_API_SIGNUP,
})

const tz = DayJS.tz.guess()

export const getMe = () => ApiSignUp.post<GetMeResponse>('/v1/users/me', { tz }).then(({ data }) => data.data)

export const getUserCourses = () =>
    ApiSignUp.get<GetUserCoursesResponse>('/v1/users/courses').then(({ data }) => data.data.courses)

export const getCourseSettings = (courseId: string) =>
    ApiSignUp.get<Api<GetCourseSettings>>(`/v1/courses/${courseId}/settings`).then(({ data }) => data.data)

interface GetCourseSettings {
    id: string
    settings: {
        hasSingleViewMeeting: boolean
    }
}

type GetMeResponse = {
    data: {
        user: {
            id: string
            userId: string
            email: string
            displayNameOrName: string
            name: string
            lastname: string
            displayName: string
            dateBirth: string | null
            gender: string | null
            provinceId: string | null
            dni: string | null
            phone: string
            urlImage: string
            isLegacyUser: boolean
            isVerified: boolean
            country: string
            countryId: number
            tz: string
            referrer: string | null
            intercomId: string
            createdAt: string
            requireMigration: boolean
            complete: boolean
            marketplace: string
            organizationId: string
            organizationName: string
            passwordExp: string | null
            hideMarketplace: boolean
        }
    }
}

export type GetUserCoursesResponse = { data: { courses: UserCoursesDTO[] } }

export type UserCoursesDTO = {
    role: 'student'
    isExpertMentor: boolean
    timesMentor: number
    timesFacilitator: number
    votes: number
    end: null | Date
    start: null | Date
    status: CourseStatus
    courseId: string
    terms: boolean
    profileId: string
    networkId: null | string
    networkName: null | string
    courseName: string
    slug: null | string
    enrolledAt: string
    'access-token': string
    requirePayment: boolean
    asyncCourse: boolean
    offlineCourse: boolean
    algorithmId: string | null
    alreadyAssigned: boolean
    nextClass: {
        open: Date
        start: Date
        delay: Date
        absent: Date
        end: Date
        duration: [number, DurationUnitType]
    } | null
    meetings: {
        general: MeetingDTO | null
        team: MeetingDTO | null
        useFirstStepAsGeneral?: boolean
    } | null
}

type CourseStatus =
    | 'unknown' // Without progressiveSchedule
    | 'enrolled' // With progressiveSchedule.start > today
    | 'studying' // With progressiveSchedule.start <= today & without progressiveSchedule.end
    | 'finished' // With progressiveSchedule.end > today
    | 'deserted' // With active = false

interface MeetingDTO {
    provider:
        | 'egg'
        | 'zoom-sdk'
        | 'zoom-sdk-auto'
        | 'zoom'
        | 'zoom-by-table'
        | 'zoom-hybrid'
        | 'meet'
        | 'teams'
        | 'whatsapp'
        | 'jitsi'
    url: string
}
