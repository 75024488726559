import { Outlet } from 'react-router-dom'
import { defaultTheme } from '../../styles/themes/defaultTheme'
import styled from 'styled-components'
import { useActions } from '../../hooks/useActions'
import { useEffect } from 'react'
import { useUI } from '../../context/UIContext'
import { useZoomContext } from '../../context/ZoomContext'

const PostMeeting = () => {
    const { zoomClient, setSidebarMode } = useZoomContext()
    const { showToast } = useUI()

    useActions()

    useEffect(() => {
        setSidebarMode(null)
        showToast.dismiss()
        zoomClient.leave().catch(() => {
            console.log('Handling normal leave error')
        })
    }, [setSidebarMode, showToast, zoomClient])

    return (
        <PostMeetingStyled>
            <div className="container">
                <Outlet />
            </div>
        </PostMeetingStyled>
    )
}

export default PostMeeting

const PostMeetingStyled = styled.section`
    width: 100vw;
    height: 100vh;
    background-color: ${defaultTheme.color.midnightBlack};
    color: ${defaultTheme.color.white};
    text-align: center;
    overflow: auto;
    ::-webkit-scrollbar {
        width: 0px;
    }
    display: flex;
    flex-direction: column;

    & > .container {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        @media (max-width: 768px) {
            padding: 20px;
        }
    }
`
