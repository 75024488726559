import React, { useCallback, useEffect, useState, useRef } from 'react'
import Styles from './MyVideo.module.css'
import { usePreMeetingContext } from '../../../context/PreMeetingContext'
import { getItem } from '../../../helpers/localStorage'
import { useZoomContext } from '../../../context/ZoomContext'
import { usePermissions } from '../../../context/PermissionsContext'
import { useVideoPlayback } from '../hooks/useVideoPlayback'

// Define common video constraints
const VIDEO_CONSTRAINTS = {
    high: {
        width: { ideal: 1280 },
        height: { ideal: 720 },
        aspectRatio: { ideal: 16 / 9 },
    },
    medium: {
        width: { ideal: 640 },
        height: { ideal: 480 },
        aspectRatio: { ideal: 4 / 3 },
    },
    low: {
        width: { ideal: 320 },
        height: { ideal: 240 },
    },
}

export const MyVideo: React.FC = () => {
    const { zoomClient } = useZoomContext()
    const { localVideoTrack: video, isInVBMode, videoRef, canvasRef } = usePreMeetingContext()
    const { microphone, camera } = usePermissions()
    const permissionGranted = microphone && camera

    const [isVideoReady, setIsVideoReady] = useState(false)
    const [isInitialized, setIsInitialized] = useState(false)

    const flags = useRef({
        isInitializing: false,
        isStartingVideo: false,
        lastVBMode: isInVBMode,
        currentQualityLevel: 'high' as keyof typeof VIDEO_CONSTRAINTS,
    })

    useVideoPlayback({ isVideoReady, setIsVideoReady })

    const initZoomClient = useCallback(async () => {
        if (flags.current.isInitializing || isInitialized) return
        flags.current.isInitializing = true
        try {
            await zoomClient.init('en-US', 'Global', {
                webEndpoint: 'zoom.us',
                stayAwake: true,
                leaveOnPageUnload: true,
                patchJsMedia: true,
            })
            setIsInitialized(true)
        } catch (error) {
            console.error('Error initializing Zoom client:', error)
            setIsInitialized(false)
        } finally {
            flags.current.isInitializing = false
        }
    }, [zoomClient, isInitialized])

    const getVBConfig = useCallback(() => {
        const setting = getItem('vfx')
        return setting === 'blur' ? undefined : setting ? { imageUrl: String(setting), cropped: false } : undefined
    }, [])

    const attemptVideoStart = useCallback(
        async (element: HTMLVideoElement | HTMLCanvasElement, vbConfig?: any) => {
            const qualities = ['high', 'medium', 'low'] as const
            let startedSuccessfully = false
            let currentQualityIndex = qualities.indexOf(flags.current.currentQualityLevel)

            while (!startedSuccessfully && currentQualityIndex < qualities.length) {
                const quality = qualities[currentQualityIndex]
                try {
                    await video.start(element, vbConfig)
                    flags.current.currentQualityLevel = quality
                    startedSuccessfully = true
                    break
                } catch (error) {
                    if (error instanceof Error && error.name === 'OverconstrainedError') {
                        console.log(`Failed with ${quality} quality, trying lower quality...`)
                        currentQualityIndex++
                    } else {
                        throw error
                    }
                }
            }

            if (!startedSuccessfully) {
                // If all qualities failed, try one last time with no constraints
                await video.start(element, vbConfig)
            }
        },
        [video]
    )

    const startVideo = useCallback(async () => {
        if (!videoRef.current || !canvasRef.current || flags.current.isStartingVideo) return
        flags.current.isStartingVideo = true

        try {
            if (video.isVideoStarted) {
                await video.stop()
                await new Promise(resolve => setTimeout(resolve, 500))
            }

            // Always start with video element initially
            await attemptVideoStart(videoRef.current)

            // If VB mode is active after initial load, switch to it
            if (isInVBMode) {
                await video.stop()
                await new Promise(resolve => setTimeout(resolve, 500))
                await attemptVideoStart(canvasRef.current, getVBConfig())
            }

            setIsVideoReady(true)
            flags.current.lastVBMode = isInVBMode
        } catch (error) {
            console.error('Error starting video:', error)
            setIsVideoReady(false)
        } finally {
            flags.current.isStartingVideo = false
        }
    }, [canvasRef, isInVBMode, video, videoRef, getVBConfig, attemptVideoStart])

    useEffect(() => {
        const initVideo = async () => {
            if (!permissionGranted || !videoRef.current || !canvasRef.current) return

            try {
                if (!isInitialized) await initZoomClient()
                await startVideo()
            } catch (error) {
                console.error('Error during video initialization:', error)
            }
        }

        initVideo()
    }, [permissionGranted, isInitialized, initZoomClient, startVideo])

    // Handle VB mode changes after initial load
    useEffect(() => {
        const handleVBModeChange = async () => {
            if (!video.isVideoStarted || flags.current.lastVBMode === isInVBMode) return

            try {
                await video.stop()
                await new Promise(resolve => setTimeout(resolve, 500))

                const targetElement = isInVBMode ? canvasRef.current : videoRef.current
                const config = isInVBMode ? getVBConfig() : undefined

                if (targetElement) {
                    await attemptVideoStart(targetElement, config)
                    flags.current.lastVBMode = isInVBMode
                }
            } catch (error) {
                console.error('Error switching VB mode:', error)
            }
        }

        handleVBModeChange()
    }, [isInVBMode, video, canvasRef, videoRef, getVBConfig, attemptVideoStart])

    // Cleanup on unmount
    useEffect(() => {
        return () => {
            if (video.isVideoStarted) {
                video.stop().catch(error => console.info('Error stopping video during cleanup:', error))
            }
        }
    }, [video])

    return (
        <div className={Styles.previewVideo}>
            <video
                ref={videoRef}
                className={!isInVBMode ? Styles.previewVideoShow : ''}
                muted
                autoPlay={false}
                playsInline
            />
            <canvas ref={canvasRef} className={isInVBMode ? Styles.previewVideoShow : ''} width="1280" height="720" />
        </div>
    )
}
