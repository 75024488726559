const isLocalhost = (): boolean => {
    if (typeof window !== 'undefined') {
        const hostname = window.location.hostname
        return hostname === 'localhost' || hostname === '127.0.0.1'
    }
    return false
}

interface LoggerOptions {
    component?: string
    type?: 'info' | 'warn' | 'error'
}

class Logger {
    private static formatMessage(message: string, options?: LoggerOptions): string {
        const prefix = options?.component ? `[${options.component}] ` : ''
        return `${prefix}${message}`
    }

    static log(message: any, options?: LoggerOptions): void {
        if (!isLocalhost()) return

        const type = options?.type || 'info'
        const formattedMessage = typeof message === 'string' ? this.formatMessage(message, options) : message

        switch (type) {
            case 'warn':
                console.warn(formattedMessage)
                break
            case 'error':
                console.error(formattedMessage)
                break
            default:
                console.log(formattedMessage)
        }
    }

    static debug(component: string, ...args: any[]): void {
        if (!isLocalhost()) return

        console.log(`[${component}]`, ...args)
    }
}

export default Logger
