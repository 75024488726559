import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { useOnboarding } from '../../../context/OnboardingContext'
import { useRemainingTime } from '../hooks/useRemainingTime'
import VideoPlayer from './VideoPlayer'
import { completeVideo } from '../../../apis/api-content'
import { useBackgroundMusic } from '../hooks/useBackgroundMusic'
import AudioControls from './AudioControls'

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 400px;
    padding: 2rem;
    text-align: center;
`

const Title = styled.h2`
    font-size: 2rem;
    color: white;
    margin-bottom: 1rem;
`

const TeamsReadyTime = styled.div`
    font-size: 1.2rem;
    color: #007bff;
    margin-bottom: 1rem;
    font-family: monospace;
`

const Subtitle = styled.p`
    font-size: 1.2rem;
    color: #999;
    margin-bottom: 2rem;
    max-width: 600px;
`

const TimerContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2rem;
`

const TimeDisplay = styled.div`
    font-size: 2.5rem;
    font-weight: bold;
    color: white;
    font-family: monospace;
    margin-bottom: 1rem;
`

const LoadingSpinner = styled.div`
    width: 70px;
    height: 70px;
    border: 5px solid #f3f3f3;
    border-top: 5px solid #007bff;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    margin-bottom: 30px;
    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`

const TeamsBuildingScreen: React.FC = () => {
    const { t } = useTranslation('onboarding')
    const navigate = useNavigate()
    const { config, updateVideoStatus } = useOnboarding()
    const { remainingTime, teamsReadyTime, isTimeUp } = useRemainingTime()
    const [showIcebreaker, setShowIcebreaker] = useState(false)

    const { isMuted, toggleMute, stopMusic } = useBackgroundMusic({
        isTimeUp,
    })

    // Find icebreaker video if it exists
    const icebreaker = config?.dailyContent.onboarding.videos.find(
        v => v.metadata.category === 'icebreaker' && !v.completed
    )

    // Handle time up
    React.useEffect(() => {
        if (isTimeUp) {
            if (icebreaker && !icebreaker.completed) {
                setShowIcebreaker(true)
            } else {
                navigate('/meeting')
            }
        }
    }, [isTimeUp, icebreaker, navigate])

    const handleIcebreakerComplete = () => {
        if (icebreaker) {
            try {
                completeVideo(icebreaker.id)
            } catch (error) {
                console.error('Could not complete icebreaker:', error)
            } finally {
                updateVideoStatus(icebreaker.id, true)
                // After completing the icebreaker, navigate to meeting
                navigate('/meeting')
            }
        }
    }

    const formatTime = (seconds: number | null): string => {
        if (seconds === null || seconds <= 0) return '0:00'
        const mins = Math.floor(seconds / 60)
        const secs = Math.floor(seconds % 60)
        return t('teamsBuilding.timer.format', {
            minutes: mins,
            seconds: secs.toString().padStart(2, '0'),
        })
    }

    const formatTeamsReadyTime = (timestamp: number): string => {
        const date = new Date(timestamp)
        return date.toLocaleTimeString('en-US', {
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
        })
    }

    // If showing icebreaker, render video player
    if (showIcebreaker && icebreaker) {
        return <VideoPlayer video={icebreaker} onComplete={handleIcebreakerComplete} />
    }

    return (
        <Container>
            <AudioControls isMuted={isMuted} onMuteToggle={toggleMute} className="absolute top-4 right-4" />
            <TimerContainer>
                <LoadingSpinner />
                <TimeDisplay>{formatTime(remainingTime)}</TimeDisplay>
            </TimerContainer>
            <Title>
                {t('teamsBuilding.title.untilTeams')}
                {teamsReadyTime && (
                    <TeamsReadyTime>
                        {t('teamsBuilding.title.teamsReadyAt', {
                            time: formatTeamsReadyTime(teamsReadyTime),
                        })}
                    </TeamsReadyTime>
                )}
                <br />
                {t('teamsBuilding.title.waitMessage')}
            </Title>
            <Subtitle>{t('teamsBuilding.subtitle')}</Subtitle>
        </Container>
    )
}

export default TeamsBuildingScreen
