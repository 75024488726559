import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { BaseVideo } from '../types/types'
import { PrimaryBtnBlueShort } from '../../../components/Buttons'
import NextVideoTimer, { AutoplayInfo } from './NextVideoTimer'
import { useTranslation } from 'react-i18next'
import { useVideoPersistence } from '../hooks/useVideoPersistence'

export const PlayerContainer = styled.div`
    width: 70%;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
`

export const VideoWrapper = styled.div`
    width: 100%;
    position: relative;
    aspect-ratio: 16/9;
    background: #000;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`

const PlayButton = styled.button`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80px;
    height: 80px;
    background: rgba(0, 0, 0, 0.6);
    border: none;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s;

    &:hover {
        background: rgba(0, 0, 0, 0.8);
    }

    &::before {
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 15px 0 15px 25px;
        border-color: transparent transparent transparent white;
        margin-left: 5px;
    }
`

const VideoTitle = styled.h2`
    font-size: 1.5rem;
    color: #999;
    margin-bottom: 30px;
`

const ProgressBar = styled.div`
    width: 100%;
    height: 4px;
    background: #ddd;
    margin-top: 1rem;
    border-radius: 2px;
`

const Progress = styled.div<{ width: number }>`
    width: ${props => (isNaN(props.width) ? 0 : props.width)}%;
    height: 100%;
    background: #007bff;
    border-radius: 2px;
    transition: width 0.3s ease;
`

const Controls = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 1rem;
`

const TimeDisplay = styled.span`
    font-size: 0.9rem;
    color: #666;
`

interface VideoPlayerProps {
    video: BaseVideo
    onComplete: () => void
    skippable?: boolean
    autoplayInfo?: AutoplayInfo
}

const VideoPlayer: React.FC<VideoPlayerProps> = ({ video, onComplete, skippable = false, autoplayInfo }) => {
    const { t } = useTranslation('onboarding')
    const [progress, setProgress] = useState(0)
    const [currentTime, setCurrentTime] = useState(0)
    const [canComplete, setCanComplete] = useState(false)
    const [duration, setDuration] = useState(0)
    const [isPlaying, setIsPlaying] = useState(false)
    const [showPlayButton, setShowPlayButton] = useState(true)
    const [showNextVideoTimer, setShowNextVideoTimer] = useState(false)
    const [countdown, setCountdown] = useState(5)
    const videoRef = useRef<HTMLVideoElement>(null)
    const countdownRef = useRef<NodeJS.Timeout>()
    const [showControls, setShowControls] = useState(video.type !== 'mandatory')
    const { isVideoWatched, setWatchedVideo } = useVideoPersistence()

    const handleCancelAutoplay = () => {
        if (countdownRef.current) {
            clearInterval(countdownRef.current)
            countdownRef.current = undefined
        }
        setShowNextVideoTimer(false)
        setShowControls(true)

        // If the video is ended, show the play button
        if (videoRef.current?.ended) {
            setShowPlayButton(true)
        }
    }

    const getTranslatedTitle = () => {
        // Convert video ID to translation key format (remove hyphens and numbers)
        const transformedId = video.id
            .split('-')
            .map((part, index) => {
                if (index === 0) return part
                return part.charAt(0).toUpperCase() + part.slice(1)
            })
            .join('')

        return t(`videos.${transformedId}.title`, { defaultValue: video.title })
    }

    const attemptAutoplay = async () => {
        if (!videoRef.current) return
        try {
            await videoRef.current.play()
            setIsPlaying(true)
            setShowPlayButton(false)
        } catch (error) {
            console.log('Autoplay failed:', error)
            setIsPlaying(false)
            setShowPlayButton(true)
        }
    }

    const handleVideoEnd = () => {
        console.log('Video ended:', video.id)
        setIsPlaying(false)
        setShowPlayButton(true)
        setCanComplete(true)

        // Mark the video as watched in sessionStorage
        setWatchedVideo(video.id)

        if (autoplayInfo?.hasNextVideo) {
            if (countdownRef.current) {
                clearInterval(countdownRef.current)
            }

            setShowNextVideoTimer(true)
            setCountdown(5)
            setShowControls(false)

            let timeLeft = 5
            countdownRef.current = setInterval(() => {
                timeLeft -= 1
                setCountdown(timeLeft)

                if (timeLeft <= 0) {
                    clearInterval(countdownRef.current)
                    setShowNextVideoTimer(false)
                    onComplete()
                }
            }, 1000)
        } else {
            onComplete()
        }
    }

    useEffect(() => {
        const checkVideoStatus = () => {
            // Check both the video's completed property and sessionStorage
            const wasWatched = isVideoWatched(video.id)
            console.log(`Video ${video.id} status check:`, {
                completed: video.completed,
                wasWatched,
            })

            if (video.completed || wasWatched) {
                console.log(`Video ${video.id} was previously completed, skipping...`)
                handleVideoEnd()
                return true
            }
            return false
        }

        // Check status immediately when the component mounts or video changes
        if (checkVideoStatus()) {
            return // Skip further setup if video was already watched
        }

        const videoElement = videoRef.current
        if (!videoElement) return

        console.log('Setting up video event listeners for video:', video.id)

        const handleLoadedMetadata = () => {
            console.log('Video metadata loaded')
            setDuration(videoElement.duration)
            attemptAutoplay()
        }

        const handleTimeUpdate = () => {
            const currentProgress = (videoElement.currentTime / videoElement.duration) * 100
            setProgress(currentProgress)
            setCurrentTime(videoElement.currentTime)

            if (currentProgress >= 99 && !canComplete) {
                setCanComplete(true)
            }
        }

        const handlePlay = () => {
            console.log('Video playing')
            setIsPlaying(true)
            setShowPlayButton(false)
        }

        const handlePause = () => {
            console.log('Video paused')
            setIsPlaying(false)
            setShowPlayButton(true)
        }

        // Add event listeners
        videoElement.addEventListener('loadedmetadata', handleLoadedMetadata)
        videoElement.addEventListener('timeupdate', handleTimeUpdate)
        videoElement.addEventListener('play', handlePlay)
        videoElement.addEventListener('pause', handlePause)
        videoElement.addEventListener('ended', handleVideoEnd)

        // Cleanup function
        return () => {
            console.log('Cleaning up video event listeners')
            videoElement.removeEventListener('loadedmetadata', handleLoadedMetadata)
            videoElement.removeEventListener('timeupdate', handleTimeUpdate)
            videoElement.removeEventListener('play', handlePlay)
            videoElement.removeEventListener('pause', handlePause)
            videoElement.removeEventListener('ended', handleVideoEnd)
        }
    }, [video.id]) // Added video.id to dependencies

    // Reset function updated to include controls state
    useEffect(() => {
        setProgress(0)
        setCurrentTime(0)
        setCanComplete(false)
        setIsPlaying(false)
        setShowPlayButton(true)
        setShowNextVideoTimer(false)
        setCountdown(5)
        setShowControls(video.type !== 'mandatory')

        if (countdownRef.current) {
            clearInterval(countdownRef.current)
            countdownRef.current = undefined
        }

        if (videoRef.current) {
            videoRef.current.currentTime = 0
            attemptAutoplay()
        }

        return () => {
            if (countdownRef.current) {
                clearInterval(countdownRef.current)
                countdownRef.current = undefined
            }
        }
    }, [video.id, video.type])

    const handleManualComplete = () => {
        if (countdownRef.current) {
            clearInterval(countdownRef.current)
        }
        setShowNextVideoTimer(false)
        onComplete()
    }

    const handlePlayClick = async () => {
        if (!videoRef.current) return
        await attemptAutoplay()
    }

    const handleSkip = () => {
        if (skippable) {
            handleManualComplete()
        }
    }

    const formatTime = (seconds: number): string => {
        const mins = Math.floor(seconds / 60)
        const secs = Math.floor(seconds % 60)
        return `${mins}:${secs.toString().padStart(2, '0')}`
    }

    const canContinue = skippable || canComplete

    return (
        <PlayerContainer>
            <VideoTitle>{getTranslatedTitle()}</VideoTitle>
            <VideoWrapper>
                <video ref={videoRef} width="100%" height="100%" controls={showControls} src={video.url} playsInline />
                {showPlayButton && !isPlaying && (
                    <PlayButton onClick={handlePlayClick} aria-label={t('videoPlayer.playButton')} />
                )}
                {showNextVideoTimer && autoplayInfo?.hasNextVideo && (
                    <NextVideoTimer
                        seconds={countdown}
                        nextVideoTitle={autoplayInfo.nextVideoTitle}
                        onCancel={handleCancelAutoplay}
                    />
                )}
            </VideoWrapper>

            <ProgressBar>
                <Progress width={progress} />
            </ProgressBar>

            <Controls>
                <TimeDisplay>
                    {t('videoPlayer.timeFormat', {
                        current: formatTime(currentTime),
                        total: formatTime(duration),
                    })}
                </TimeDisplay>

                <div style={{ display: 'flex' }}>
                    {skippable && (
                        <PrimaryBtnBlueShort onClick={handleSkip}>
                            {t('videoPlayer.actions.skipVideo')}
                        </PrimaryBtnBlueShort>
                    )}
                    <PrimaryBtnBlueShort
                        onClick={handleManualComplete}
                        disabled={!canContinue}
                        style={{ marginLeft: '1rem' }}
                    >
                        {canComplete ? t('videoPlayer.actions.continue') : t('videoPlayer.actions.watchMore')}
                    </PrimaryBtnBlueShort>
                </div>
            </Controls>
        </PlayerContainer>
    )
}

export default VideoPlayer
