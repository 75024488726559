import { MediaStream, ZoomClient } from '../types/video-types'
import { useCallback, useEffect, useState } from 'react'

import type { NetworkQuality } from '@zoom/videosdk'
import produce from 'immer'
import { useUI } from '../../../context/UIContext'
import { useTranslation } from 'react-i18next'

export function useNetworkQuality(zoomClient: ZoomClient, mediaStream: MediaStream | null) {
    const [networkQuality, setNetworkQuality] = useState<Record<string, NetworkQuality>>({})
    const { showToast } = useUI()
    const { t } = useTranslation('App')

    const checkNetworkQuality = useCallback(async () => {
        try {
            const userInfo = zoomClient.getCurrentUserInfo()
            if (!userInfo || typeof userInfo.userId === 'undefined') return
            const { userId } = userInfo
            const userNetworkQuality = networkQuality[userId]

            if (userNetworkQuality) {
                const uplink = userNetworkQuality.uplink ?? Number.MAX_VALUE
                const downlink = userNetworkQuality.downlink ?? Number.MAX_VALUE

                if (uplink <= 1 || downlink <= 1) {
                    showToast(t('networkIssues'), {
                        id: 'network-quality-info',
                    })
                } else {
                    showToast.dismiss('network-quality-info')
                }
            }
        } catch (error) {
            console.error('Error checking network quality:', error)
        }
    }, [networkQuality, showToast, t, zoomClient])

    const onNetworkChange = useCallback((payload: any) => {
        const { userId, type, level } = payload
        setNetworkQuality(
            produce(draft => {
                if (draft[`${userId}`] !== undefined) {
                    if (type === 'uplink') {
                        draft[`${userId}`].uplink = level
                    } else {
                        draft[`${userId}`].downlink = level
                    }
                } else {
                    Object.assign(draft, {
                        [`${userId}`]: {
                            [`${type}`]: level,
                        },
                    })
                }
            })
        )
    }, [])

    useEffect(() => {
        if (!zoomClient || !mediaStream) return
        checkNetworkQuality()
    }, [checkNetworkQuality, mediaStream, networkQuality, showToast, zoomClient])

    useEffect(() => {
        zoomClient.on('network-quality-change', onNetworkChange)
        return () => {
            zoomClient.off('network-quality-change', onNetworkChange)
        }
    }, [zoomClient, onNetworkChange])
    return networkQuality
}
