import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { MediaBtnContainer } from '../../../pre-meeting/components/Buttons'
import { Tip } from '../../../../components/Tip'
import { useZoomContext } from '../../../../context/ZoomContext'
import { useAutoStartMedia } from '../../hooks/useAutoStartMedia'

const CameraButton = () => {
    const { isVideoStarted, zoomClient, mediaStream } = useZoomContext()
    const { autoStartVideo } = useAutoStartMedia({ zoomClient, mediaStream })

    const onCameraClick = async () => {
        if (isVideoStarted) {
            await mediaStream?.stopVideo()
        } else {
            await autoStartVideo()
        }
    }

    return (
        <div>
            <MediaBtnContainer active square as="div" pulse>
                <button
                    data-tooltip-id="cam-pre-action"
                    onClick={e => {
                        e.stopPropagation()
                        onCameraClick()
                    }}
                >
                    {isVideoStarted ? (
                        <FontAwesomeIcon icon={['far', 'camera-slash']} fixedWidth />
                    ) : (
                        <FontAwesomeIcon icon={['far', 'video']} fixedWidth />
                    )}
                </button>
            </MediaBtnContainer>
            <Tip id="cam-pre-action" content={isVideoStarted ? `Iniciar cámara` : 'Detener cámara'} />
        </div>
    )
}
export default CameraButton
