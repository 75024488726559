import { useTranslation } from 'react-i18next'
import { CloseMeeting } from '../components/CloseMeeting'
import { Feedback } from '../components/Feedback'

export const ExpeledByHost = () => {
    const { t } = useTranslation('postmeeting')
    return (
        <div className="row-3">
            <h1>{t('removedByHost.title')}</h1>
            <Feedback />
            <CloseMeeting />
        </div>
    )
}
