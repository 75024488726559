import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { MediaBtnContainer } from '../../../pre-meeting/components/Buttons'
import { Tip } from '../../../../components/Tip'
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useZoomContext } from '../../../../context/ZoomContext'
import { useTranslation } from 'react-i18next'
import { useSessionParams } from '../../../../hooks/useSessionParams'

export const LeaveButton = () => {
    const { t } = useTranslation('meeting')
    const {
        mediaStream,
        zoomClient,
        setConfirmationDialog,
        isAudioStarted,
        isVideoStarted,
        setIsVideoStarted,
        setIsAudioStarted,
        isStartedScreenShare,
    } = useZoomContext()
    const navigate = useNavigate()
    const { room } = useSessionParams()

    const stopMediaStream: () => void = useCallback(async () => {
        try {
            if (isAudioStarted) setIsAudioStarted(false)
            if (isStartedScreenShare) await mediaStream?.stopShareScreen()
            if (isVideoStarted) {
                setIsVideoStarted(false)
                mediaStream
                    ?.stopVideo()
                    .then(() => {
                        console.log('Component unmounted, video stopped')
                    })
                    .catch(error => {
                        console.error('Error stopping video when unmounting', error)
                    })
            }
        } catch (error) {
            console.log(error)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAudioStarted, isStartedScreenShare, isVideoStarted, mediaStream])

    const onLeaveClick = useCallback(async () => {
        try {
            if (zoomClient.isHost() && room === 'general') {
                setConfirmationDialog('QUIT_MEETING')
                return
            }
            stopMediaStream()
            await zoomClient.leave(false).catch(() => {
                console.log('Handling normal leave error')
            })
            navigate('/post-meeting/ended')
        } catch (error) {
            console.log('Error leaving meeting', error)
        }
    }, [zoomClient, room, stopMediaStream, navigate, setConfirmationDialog])

    return (
        <div>
            <MediaBtnContainer wide active accent onClick={onLeaveClick} data-tooltip-id="leave-pre-action">
                <FontAwesomeIcon className="icon" icon={['fas', 'phone-hangup']} />
            </MediaBtnContainer>

            <Tip id="leave-pre-action" content={t('quitModal.quitButton')} />
        </div>
    )
}
