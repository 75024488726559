import styled from 'styled-components'
import { PrimaryBtnBlue } from '../../../components/Buttons'

type Props = {}

const DevicesBusy = (props: Props) => {
    return (
        <NoPermissionContainer>
            <h1>All devices are busy</h1>
            <PrimaryBtnBlue style={{ fontSize: '1rem', width: 'auto' }}>
                Try closing another tabs or windows that could be using your microphone or camera.
            </PrimaryBtnBlue>
        </NoPermissionContainer>
    )
}

export default DevicesBusy

const NoPermissionContainer = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    background: var(--color-primary);
    border-radius: var(--video-border-radius);
    overflow: hidden;
    aspect-ratio: 16 / 9;
    box-shadow: 0 0 100px 0 rgba(255, 100, 124, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    padding: 15px;
    box-sizing: border-box;
    flex-direction: column;
    gap: 20px;
    h1 {
        font-size: 1.24rem;
        text-align: center;
        font-weight: normal;
    }
`
