import Axios from 'axios'

export const ApiWidget = Axios.create({
    baseURL: process.env.REACT_APP_WIDGET_API,
})

export const requestHelp = async (tableNumber: number) => {
    ApiWidget.post<any>('/v1/help-request/activationTeam', {
        tableNumber,
    }).then(({ data }) => data.data)
}
