import { SidebarModeType } from '../../types/types'
import styled from 'styled-components'

export const ControlsStyled = styled.div<{ showSidebar?: SidebarModeType }>`
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    border-radius: 0;
    background-color: rgba(20, 20, 20, 0.8);
    backdrop-filter: blur(30px);
    box-shadow: 0px 0px 16px #0037504d;
    display: flex;
    gap: 12px;
    justify-content: center;
    padding: 10px;
    box-sizing: border-box;
    margin-top: 10px;
    margin: 10px auto 0 auto;
    border: ${props => `1px solid rgba(50, 50, 50, 0.8)`};
    transition: all 0.3s ease-in-out;
    @media only screen and (min-width: 768px) {
        position: absolute;
        bottom: 20px;
        left: ${props => (props.showSidebar ? 'calc((100% - 400px) / 2)' : '50%')};
        transform: translateX(-50%);
        width: auto;
        border-radius: 10px;
        padding: 8px;
        gap: 10px;
    }
    ul {
        display: flex;
        list-style: none;
        margin-left: 0;
        padding-left: 0;
        align-items: center;
        margin-top: 5px;
        margin-bottom: 5px;
        li {
            padding: 3px 6px;
            margin: 0 2px;
            position: relative;
            @media only screen and (max-width: 768px) {
                padding: 0px;
            }
            &.active {
                background-color: rgba(100, 100, 100, 0.2);
                border-radius: 5px;
                button {
                    color: ${props => props.theme.color.electricBlue};
                }
            }
        }
    }
`
