import styled, { css, keyframes } from 'styled-components'

type MediaBtnContainerProps = {
    active?: boolean
    accent?: boolean
    square?: boolean
    wide?: boolean
    pulse?: boolean
    as?: React.ElementType
}

const pulseAnimation = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
`

const baseStyles = css<MediaBtnContainerProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${props => {
        if (props.active) {
            return props.accent ? props.theme.color.accent : props.theme.color.electricBlue
        }
        return props.theme.color.midnightBlack
    }};
    ${props =>
        props.square &&
        css`
            width: 47px;
            height: 40px;
            padding: 0 0;
        `}
    ${props =>
        props.wide &&
        css`
            padding: 0 22px;
        `}
  border-radius: 8px;
    padding: 0;
    min-height: 40px;
    border: none;
    color: white;
    cursor: pointer;
    font-size: 1.2rem;
    padding: ${props => (props.wide ? '0 40px' : '0 10px')};

    ${props =>
        props.pulse &&
        props.active &&
        css`
            animation: ${pulseAnimation} 2s infinite ease-in-out;
        `}

    &:disabled {
        cursor: not-allowed;
        opacity: 0.5;
    }
    &:hover {
        background-color: ${props => props.theme.color.sidebar.gray2};
    }
    ${props =>
        props.active &&
        props.accent &&
        css`
            &:hover {
                background-color: ${props.theme.color.accentDark};
            }
        `}
    ${props =>
        props.active &&
        !props.accent &&
        css`
            &:hover {
                background-color: ${props.theme.color.electricBlueDark};
            }
        `}
  
  button, .anchor {
        background: none;
        border: none;
        color: white;
        cursor: pointer;
        width: fit-content;
        height: fit-content;
        font-size: 1.25rem;
        padding: 0 2px;
        &:hover:not(:disabled) {
            color: white !important;
        }
        &:not(:first-child) {
            font-size: 1.05rem;
            &:hover {
                color: rgba(255, 255, 255, 0.6) !important;
            }
        }
        &:disabled {
            cursor: not-allowed;
            opacity: 0.5;
        }
    }
    .dropdown {
    }
`

export const MediaBtnContainer = styled.button<MediaBtnContainerProps>`
    ${baseStyles}
`
