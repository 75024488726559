import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { LoadingBolt } from '../../components/LoadingBolt'
import { useOnboarding } from '../../context/OnboardingContext'
import { useSessionParams } from '../../hooks/useSessionParams'

const AppHub = () => {
    const navigate = useNavigate()
    const { role } = useSessionParams()
    const { isLoading, isCourseLoading, error, onboardingConfig, config } = useOnboarding()

    useEffect(() => {
        console.log('AppHub: Initial render state', {
            isLoading,
            isCourseLoading,
            hasConfig: !!onboardingConfig,
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (role !== 'student') {
            navigate('/meeting')
            return
        }

        if (!isLoading && !isCourseLoading && config) {
            console.info('AppHub: Finished loading config', config)
            if (onboardingConfig?.enabled) {
                navigate('/onboarding')
            } else {
                navigate('/meeting')
            }
        }
    }, [isLoading, isCourseLoading, config, onboardingConfig?.enabled, navigate, role])

    console.log('AppHub: Rendering with state', {
        isLoading,
        isCourseLoading,
        hasError: !!error,
    })

    // Don't show loading state for non-students
    if (role !== 'student') {
        return (
            <div className="min-h-screen flex items-center justify-center bg-gray-900">
                <div className="text-white">Redirecting to meeting...</div>
            </div>
        )
    }

    if (isLoading || isCourseLoading || !config) {
        return <LoadingBolt />
    }

    if (error) {
        return (
            <div
                style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: 'white',
                }}
            >
                <div style={{ color: 'white' }}>Error loading configuration: {error.message}</div>
            </div>
        )
    }

    return null
}

export default AppHub
