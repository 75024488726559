const STORAGE_KEY = 'watched_videos'

export const useVideoPersistence = () => {
    const getWatchedVideos = (): string[] => {
        try {
            const stored = sessionStorage.getItem(STORAGE_KEY)
            return stored ? JSON.parse(stored) : []
        } catch (error) {
            console.error('Error reading from sessionStorage:', error)
            return []
        }
    }

    const setWatchedVideo = (videoId: string) => {
        try {
            const watched = getWatchedVideos()
            if (!watched.includes(videoId)) {
                watched.push(videoId)
                sessionStorage.setItem(STORAGE_KEY, JSON.stringify(watched))
            }
        } catch (error) {
            console.error('Error writing to sessionStorage:', error)
        }
    }

    const isVideoWatched = (videoId: string): boolean => {
        const watched = getWatchedVideos()
        return watched.includes(videoId)
    }

    const clearWatchedVideos = () => {
        try {
            sessionStorage.removeItem(STORAGE_KEY)
        } catch (error) {
            console.error('Error clearing sessionStorage:', error)
        }
    }

    return {
        getWatchedVideos,
        setWatchedVideo,
        isVideoWatched,
        clearWatchedVideos,
    }
}
