import { Comment } from './Comment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ThankYouFeedback } from './ThankYouFeedback'
import { defaultTheme } from '../../../styles/themes/defaultTheme'
import styled from 'styled-components'
import { useScore } from '../../meeting-view/hooks/useScore'
import { useTranslation } from 'react-i18next'

const scores = [1, 2, 3, 4, 5]

export const Feedback = () => {
    const { t } = useTranslation('postmeeting')
    const { maxScore, scoreSent, scoreSelected, isLoading, chooseScore, sendScore, resetScore } = useScore()

    if (isLoading) return <p>{t('feedback.sending')}</p>

    if (scoreSent) return <ThankYouFeedback />

    return (
        <Section>
            <p className="feedback-title">{t('feedback.title')}</p>
            <div className="stars">
                {scores.map(score => (
                    <FontAwesomeIcon
                        key={score}
                        className={`star-icon ${score <= maxScore ? 'active' : ''}`}
                        icon={[score <= maxScore ? 'fas' : 'far', 'star']}
                        onClick={() => sendScore({ score })}
                        onMouseEnter={() => chooseScore(score)}
                        onMouseLeave={resetScore}
                    />
                ))}
            </div>
            {scoreSelected && <Comment sendScore={sendScore} />}
        </Section>
    )
}

const Section = styled.section`
    & > .feedback-title {
        margin: 0;
        font-size: 18px;
        font-weight: 600;
    }

    .stars {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 12px;
    }
    .star-icon {
        cursor: pointer;
        font-size: 30px;
        color: ${defaultTheme.color.white};
        padding: 10px;
    }
    .star-icon.active {
        color: #ffcd00;
    }
`
