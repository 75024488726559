import { useQuery } from '@tanstack/react-query'
import { useSessionParams } from '../../../hooks/useSessionParams'
import { getUserCourses as queryFn } from '../../../apis/api-signup'

export const useUserCourses = () => {
    const { _id, _classId, role } = useSessionParams()

    const queryKey = ['User', 'Courses', _id ?? 'Unknown']

    const { data: courses, ...rest } = useQuery({
        queryKey,
        queryFn,
        enabled: role === 'student',
    })

    const getCourseData = (courseId?: string) => courses?.find(course => course.courseId === courseId)

    const currentCourse = getCourseData(_classId)

    const currentCourseName = currentCourse?.networkName ?? currentCourse?.courseName

    return {
        courses,
        currentCourse,
        currentCourseName,
        getCourseData,
        ...rest,
    }
}
