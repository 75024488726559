import Axios from 'axios'
import { updateSessionParams } from '../helpers/urls'

export const ApiHCA = Axios.create({
    baseURL: process.env.REACT_APP_HCA_API,
})

export const getMeetingData = async (algorithm_id?: string) =>
    ApiHCA.post<GetTokensDTO>('/sdk_session/get_sdk_tokens', {
        algorithm_id,
    }).then(({ data }) => {
        const { general_room, teams_room } = data
        const generalToken = general_room?.token
        const teamsToken = teams_room?.token
        updateSessionParams({ generalToken, teamsToken })
        return data
    })

interface GetTokensDTO {
    general_room?: {
        // Undefined if the course's settings are not set to use the general r  oom
        token?: string
        url: string
    }
    teams_room?: {
        // Undefined if there is a not valid algorithm_id
        token?: string
        room_number: number
        url: string
    }
}
