import { useCallback, useEffect, useMemo } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { NotificationWithCross } from '../components/NotificationWithCross'
import { Participant } from '@zoom/videosdk'
import useParticipants from './useParticipants'
import { useTranslation } from 'react-i18next'
import { useUI } from '../context/UIContext'
import { useZoomContext } from '../context/ZoomContext'

const useRaisedHands = () => {
    const { currentUserInfo, commandChannel, raisedHands, setRaisedHands, setHasRaisedHands } = useZoomContext()
    const { participants } = useParticipants()
    const { showError, showToast } = useUI()
    const { t } = useTranslation('meeting')

    const isUserHandRaised = useMemo(
        () => (userId: number) => raisedHands.some(item => item.userId === userId),
        [raisedHands]
    )
    const IRaisedMyHand = isUserHandRaised(currentUserInfo?.userId ?? 0)

    const toggleRaiseHand = useCallback(
        (
            userId?: number,
            options: RaiseHandOptions = {
                force: false,
                notify: true,
            }
        ) => {
            if (!commandChannel) return showError(t('toggleRaiseHand.errorRaiseHand'))
            if (!userId) userId = currentUserInfo?.userId
            if (!userId) return showError(t('toggleRaiseHand.errorRaiseHand'))

            const user = participants.find(item => item.userId === userId)
            if (!user) {
                console.error(`User with ID ${userId} not found in participants`)
                return showError(t('toggleRaiseHand.errorRaiseHand'))
            }

            const isHandRaised = raisedHands.some(item => item.userId === userId)

            setRaisedHands(prevRaisedHands => {
                let newRaisedHands
                if (isHandRaised) {
                    newRaisedHands = prevRaisedHands
                        .filter(item => item.userId !== userId)
                        .sort((a, b) => a.order - b.order)
                        .map((item, index) => ({ ...item, order: index + 1 }))
                } else {
                    newRaisedHands = [...prevRaisedHands, { userId, order: prevRaisedHands.length + 1 }]
                }

                // Update hasRaisedHands based on the new raisedHands state
                setHasRaisedHands(prevHasRaisedHands => {
                    if (isHandRaised) {
                        return prevHasRaisedHands.filter(p => p.userId !== userId)
                    } else {
                        const newParticipant = { ...user, raiseHandOrder: newRaisedHands.length }
                        return [...prevHasRaisedHands, newParticipant]
                    }
                })

                return newRaisedHands
            })

            if (!isHandRaised) {
                const id = `${userId}`
                const message =
                    user.userId === currentUserInfo?.userId
                        ? t('toggleRaiseHand.youRaisedHand')
                        : t('toggleRaiseHand.userRaisedHand', { displayName: user.displayName })

                showToast(<NotificationWithCross id={id}>{message}</NotificationWithCross>, {
                    id,
                    icon: <FontAwesomeIcon icon={['far', 'hand']} style={{ color: '#00A3FF' }} />,
                })
            }

            if ((options.notify && userId === currentUserInfo?.userId) || options.force) {
                commandChannel.send(
                    JSON.stringify({ type: 'toggle-raise-hand', userId, isHandRaised: !isHandRaised, options }),
                    typeof options.notify === 'number' ? options.notify : undefined
                )
            }
        },
        [
            commandChannel,
            currentUserInfo?.userId,
            raisedHands,
            showError,
            showToast,
            t,
            participants,
            setRaisedHands,
            setHasRaisedHands,
        ]
    )

    useEffect(() => {
        // This effect ensures that hasRaisedHands stays in sync with raisedHands
        const updateHasRaisedHands = async () => {
            const updatedHasRaisedHands = await Promise.all(
                raisedHands.map(async raisedHand => {
                    const participant = participants.find(p => p.userId === raisedHand.userId)
                    return participant ? { ...participant, raiseHandOrder: raisedHand.order } : null
                })
            )

            setHasRaisedHands(
                updatedHasRaisedHands
                    .filter((p): p is Participant & { raiseHandOrder: number } => p !== null)
                    .sort((a, b) => a.raiseHandOrder - b.raiseHandOrder)
            )
        }

        updateHasRaisedHands()
    }, [raisedHands, participants, setHasRaisedHands])

    return {
        toggleRaiseHand,
        isUserHandRaised,
        IRaisedMyHand,
    }
}

export default useRaisedHands

interface RaiseHandOptions {
    force?: boolean
    notify?: boolean | number
}
