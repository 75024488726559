import styled from 'styled-components'
import { PrimaryBtnBlue } from '../../../components/Buttons'
import NoPermissionsModal from '../../../components/Modals/NoPermissionsModal'
import { useState } from 'react'
import ModalContainer from '../../../components/Modals/ModalContainer'
import { useTranslation } from 'react-i18next'

type Props = {}

const NoPermission = (props: Props) => {
    const [modalOpen, setModalOpen] = useState(false)
    const { t } = useTranslation('premeeting')

    return (
        <NoPermissionContainer>
            <h1>{t('permissions.enableCameraAndMicrophoneRequired')}</h1>
            <PrimaryBtnBlue style={{ fontSize: '1rem', width: 'auto' }} onClick={() => setModalOpen(true)}>
                {t('permissions.allowCameraAndMicrophoneAccess')}
            </PrimaryBtnBlue>
            <ModalContainer isOpen={modalOpen} onClose={() => setModalOpen(false)}>
                <NoPermissionsModal />
            </ModalContainer>
        </NoPermissionContainer>
    )
}

export default NoPermission

const NoPermissionContainer = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    background: var(--color-primary);
    border-radius: var(--video-border-radius);
    overflow: hidden;
    aspect-ratio: 16 / 9;
    box-shadow: 0 0 100px 0 rgba(255, 100, 124, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    padding: 15px;
    box-sizing: border-box;
    flex-direction: column;
    gap: 20px;
    h1 {
        font-size: 1.24rem;
        text-align: center;
        font-weight: normal;
    }
`
