import React from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const AudioButton = styled.button`
    background: rgba(0, 0, 0, 0.6);
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.2s ease;
    padding: 0;
    color: white;

    &:hover {
        background: rgba(0, 0, 0, 0.8);
    }

    svg {
        font-size: 18px;
    }
`

const AudioContainer = styled.div<{ className?: string }>`
    position: ${props => (props.className?.includes('absolute') ? 'absolute' : 'relative')};
    top: 10px;
    right: 10px;
`

interface AudioControlsProps {
    isMuted: boolean
    onMuteToggle: () => void
    className?: string
    icon?: 'volume' | 'volume-slash' | 'volume-xmark'
}

const AudioControls: React.FC<AudioControlsProps> = ({ isMuted, onMuteToggle, className = '', icon = 'volume' }) => {
    return (
        <AudioContainer className={className}>
            <AudioButton onClick={onMuteToggle} aria-label={isMuted ? 'Unmute music' : 'Mute music'}>
                <FontAwesomeIcon icon={['far', icon]} />
            </AudioButton>
        </AudioContainer>
    )
}

export default AudioControls
