import { MyPulseDataDTO, PulseDTO, getPulses, givePulse as givePulseFn } from '../../../apis/api-pulses'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import { myPulseDataQueryKey } from './useMyPulseData'
import { useMemo } from 'react'
import useParticipants from '../../../hooks/useParticipants'
import { useUI } from '../../../context/UIContext'

const mutationKey = ['Give Pulse']

export const usePulses = () => {
    const { participants } = useParticipants()
    const { showError } = useUI()
    const queryClient = useQueryClient()

    const profileIds = useMemo(
        () => participants.flatMap(({ userIdentity }) => userIdentity ?? []).toSorted(),
        [participants]
    )

    const queryKey = ['Pulses', ...profileIds]
    const { data: pulses, ...rest } = useQuery({
        queryKey,
        queryFn: async () => {
            // Only fetch the pulses that are not already in the cache
            const snapshot = [
                ...new Set(
                    queryClient
                        .getQueriesData<PulseDTO[]>({ queryKey: ['Pulses'], exact: false, type: 'all' })
                        .flatMap(([, data]) => data ?? [])
                ),
            ]

            const newProfiles = profileIds.filter(id => !snapshot.some(pulse => pulse.id === id))

            return newProfiles.length ? getPulses(newProfiles).then(pulses => snapshot.concat(pulses)) : snapshot
        },
        enabled: !!profileIds.length,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        staleTime: 1000 * 60 * 60 * 4, // 4 hours,
    })

    const { mutate: givePulse } = useMutation({
        mutationKey,
        mutationFn: async (...args: Parameters<typeof givePulseFn>) => givePulseFn(...args),
        onMutate: async profileToId => {
            if (!profileToId) return

            const pulsesSnapshot = queryClient.getQueryData<PulseDTO[]>(queryKey)
            const myPulseDataSnapshot = queryClient.getQueryData<MyPulseDataDTO>(myPulseDataQueryKey)

            // Update the user's pulse count
            if (pulsesSnapshot)
                queryClient.setQueriesData<typeof pulsesSnapshot>(
                    { queryKey: ['Pulses'], exact: false, type: 'all' },
                    old =>
                        old?.map(pulse => {
                            if (pulse.id === profileToId)
                                return {
                                    ...pulse,
                                    received: pulse.received + 1,
                                }
                            return pulse
                        })
                )

            // Update my remaining pulses
            if (myPulseDataSnapshot) {
                const remainingPulses = myPulseDataSnapshot.remainingPulses - 1
                queryClient.setQueryData<typeof myPulseDataSnapshot>(myPulseDataQueryKey, {
                    ...myPulseDataSnapshot,
                    remainingPulses,
                    canGivePulse: remainingPulses > 0,
                })
            }

            return { pulsesSnapshot, myPulseDataSnapshot }
        },
        onError: (_, __, context) => {
            if (!context) return

            showError('No tienes más pulsos disponibles por el momento')

            queryClient.setQueryData(queryKey, context.pulsesSnapshot)
            queryClient.setQueryData(myPulseDataQueryKey, context.myPulseDataSnapshot)
        },

        onSuccess: ({ availability }) => queryClient.setQueryData(myPulseDataQueryKey, availability),
    })

    const getPulseData = (profileId?: string) => pulses?.find(({ id }) => id === profileId)

    return {
        pulses,
        getPulseData,
        givePulse,
        ...rest,
    }
}
