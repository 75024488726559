import * as Sentry from '@sentry/react'

import { deleteParamsFromURL, getSessionParameters, updateSessionParams } from '../../../helpers/urls'
import { useEffect, useState } from 'react'

import { ErrorType } from '../components/Video/ErrorComponent'
import { MeetingParams } from '../types/types'
import ReactGA from 'react-ga4'
import ZoomVideo from '@zoom/videosdk'
import { capitalizeAll } from '../../../helpers/formatters'
import { decodeMeetingToken } from '../api'
import { useMe } from './useMe'
import { useMeetingError } from './meeting-errors/useMeetingError'
import { useNavigate } from 'react-router-dom'
import useParticipants from '../../../hooks/useParticipants'
import { useTimer } from './useTimer'
import { useTranslation } from 'react-i18next'
import { useUI } from '../../../context/UIContext'
import { useZoomContext } from '../../../context/ZoomContext'
import { setSentryContextAndTags } from '../../../helpers/sentry'

export const useJoinSDK = () => {
    const { t } = useTranslation('notifications')
    const navigate = useNavigate()
    const { handleError } = useMeetingError()
    const { showToast } = useUI()
    const { user } = useMe()
    const [isJoining, setIsJoining] = useState(false)
    const [error, setError] = useState<ErrorType | null>(null)
    const {
        setCurrentUserInfo,
        setCurrentSessionInfo,
        zoomClient,
        setChatClient,
        setCommandChannel,
        setRecordingClient,
        setMediaStream,
    } = useZoomContext()
    const { setParticipants } = useParticipants()
    const urlParams = getSessionParameters<MeetingParams>()
    const { name = 'Anónimo', widgetToken: token } = urlParams

    const { time, isActive } = useTimer({
        initTime: 30,
        onFinish: () => {
            zoomClient.leave().catch(() => {
                console.log('Handling normal leave error')
            })
            ZoomVideo.destroyClient()
            navigate('/post-meeting/ended')
        },
    })

    user && Sentry.setUser({ email: user.email, id: user.id, username: user.displayNameOrName })

    const join = async () => {
        if (isJoining) return
        setIsJoining(true)
        showToast(t('joinMessage'), {
            id: 'joining-info',
            duration: Infinity,
        })

        const { room, ...rest } = getSessionParameters()
        if (!room) return setError(handleError({ errorType: 'default', payload: null }))

        const token = rest[`${room}Token` as keyof typeof rest]
        if (!token || typeof token !== 'string') return setError(handleError({ errorType: 'default', payload: null }))

        const { tpc: topic } = decodeMeetingToken(token)
        if (rest.topic !== topic) updateSessionParams({ topic })

        const password = ''

        try {
            await zoomClient.init('en-US', 'Global', {
                webEndpoint: 'zoom.us',
                stayAwake: true,
                leaveOnPageUnload: true,
                patchJsMedia: true,
            })

            console.log('Leaving before joining...')
            await zoomClient.leave().catch(() => {
                console.log('Handling normal leave error')
            })

            console.log('Joining...')
            await zoomClient.join(topic, token, capitalizeAll(name), password)

            deleteParamsFromURL()

            const stream = zoomClient.getMediaStream()
            setMediaStream(stream)

            const chatClient = zoomClient.getChatClient()
            setChatClient(chatClient)

            const commandChannel = zoomClient.getCommandClient()
            setCommandChannel(commandChannel)

            const recordingClient = zoomClient.getRecordingClient()
            setRecordingClient(recordingClient)

            setParticipants(zoomClient.getAllUser())

            ReactGA.event({
                category: 'Performance',
                action: 'user-joined',
            })

            const currentUserInfo = zoomClient.getCurrentUserInfo()
            const currentSessionInfo = zoomClient.getSessionInfo()
            setCurrentUserInfo(currentUserInfo)
            setCurrentSessionInfo(currentSessionInfo)
            setSentryContextAndTags({
                isHost: currentUserInfo.isHost,
                topic: currentSessionInfo.topic,
                sessionId: currentSessionInfo.sessionId,
                userId: currentUserInfo.userId,
                displayName: currentUserInfo.displayName,
                sharerOn: currentUserInfo.sharerOn,
            })
            Sentry.setContext('session', {
                topic: currentSessionInfo.topic,
                sessionId: currentSessionInfo.sessionId,
            })
        } catch (error) {
            setError(handleError({ errorType: 'default', payload: null }))
            console.error('Hubo un error al unirse a la sesión.', error)
        } finally {
            setIsJoining(false)
            showToast.dismiss('joining-info')
        }
    }

    useEffect(() => {
        if (error && error?.errorType !== 'permissions-denied') return
        if (isActive && time > 0) setError(handleError({ errorType: 'permissions-denied', payload: { time } }))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [time])

    return {
        join,
        isJoining: isJoining && !error,
        errorType: error,
        token,
    }
}
