import { MutableRefObject, useCallback, useEffect, useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { MediaBtnContainer } from '../../../pre-meeting/components/Buttons'
import ReactGA from 'react-ga4'
import { SharePrivilege } from '@zoom/videosdk'
import { StyledDropdownOptions } from '../Misc/StyledDropdownOptions'
import { Tip } from '../../../../components/Tip'
import ToastNotification from '../Misc/ToastNotification'
import Tooltip from '../../../../components/Tooltip/Tooltip.component'
import { isAndroidOrIOSBrowser } from '../../util/platform'
import { useHostFunctions } from '../../hooks/useHostFunctions'
import { useUI } from '../../../../context/UIContext'
import { useZoomContext } from '../../../../context/ZoomContext'
import { useTranslation } from 'react-i18next'
import CloseFloatingBtn from '../../../../components/CloseFloatingBtn'

export enum SharePermissionType {
    SHARE_ALL = 'Permitir que todos los participantes compartan',
    SHARE_COACH = 'Permitir que solo el coach comparta',
}

const ScreenShare = ({ selfShareRef }: { selfShareRef: MutableRefObject<HTMLCanvasElement | null> }) => {
    const { t } = useTranslation('meeting')
    const menuItems = [SharePermissionType.SHARE_ALL, SharePermissionType.SHARE_COACH]

    const { mediaStream, isStartedScreenShare, setIsStartedScreenShare, zoomClient, isHostOrManager } = useZoomContext()
    const { switchSharePermissions } = useHostFunctions()
    const [isOpen, setIsOpen] = useState(false)
    const { showToast } = useUI()
    const [shareEnabled, setShareEnabled] = useState<boolean>(!mediaStream?.isShareLocked())
    const [canShareAudioSimultaneously, setCanShareAudioSimultaneously] = useState<boolean>(false)

    useEffect(() => {
        // Check audio sharing capability when mediaStream is available
        if (mediaStream) {
            const canShareAudio = mediaStream.isSupportMicrophoneAndShareAudioSimultaneously()
            setCanShareAudioSimultaneously(canShareAudio)
        }
    }, [mediaStream])

    const getActiveOption = () => {
        const privilege = mediaStream?.getSharePrivilege()
        if (privilege === SharePrivilege.Locked) {
            return SharePermissionType.SHARE_COACH
        }
        return SharePermissionType.SHARE_ALL
    }
    const [activeOption, setActiveOption] = useState(getActiveOption())

    // Set up audio change listener
    useEffect(() => {
        const handleAudioChange = (payload: { state: 'on' | 'off' }) => {
            if (payload.state === 'on') {
                showToast(
                    <ToastNotification
                        icon={['fal', 'circle-info']}
                        iconStyle={{ color: '#48A0F8' }}
                        msg={t('screenShare.tabAudioEnabled', {
                            defaultValue: 'Switched audio source to Share Chrome Tab Audio',
                        })}
                    />,
                    { duration: 3000 }
                )
            } else if (payload.state === 'off') {
                showToast(
                    <ToastNotification
                        icon={['fal', 'circle-info']}
                        iconStyle={{ color: '#48A0F8' }}
                        msg={t('screenShare.microphoneEnabled', {
                            defaultValue: 'Switched audio source to your microphone',
                        })}
                    />,
                    { duration: 3000 }
                )
            }
        }

        zoomClient.on('share-audio-change', handleAudioChange)
        return () => {
            zoomClient.off('share-audio-change', handleAudioChange)
        }
    }, [zoomClient, showToast, t])

    const onScreenShareClick = useCallback(async () => {
        try {
            if (!isStartedScreenShare && selfShareRef && selfShareRef.current) {
                const shareOptions: any = {
                    requestReadReceipt: true,
                }

                // Configure audio sharing based on browser capability
                if (!canShareAudioSimultaneously) {
                    shareOptions.audio = {
                        share: false, // Disable tab audio sharing for incompatible browsers
                    }

                    showToast(
                        <ToastNotification
                            icon={['fal', 'circle-info']}
                            iconStyle={{ color: '#48A0F8' }}
                            msg={t('screenShare.audioLimitationWarning', {
                                defaultValue:
                                    'Your browser does not support sharing tab audio and using microphone simultaneously. You can toggle between them during sharing.',
                            })}
                        />,
                        { duration: 5000 }
                    )
                }

                await mediaStream
                    ?.startShareScreen(selfShareRef.current, shareOptions)
                    .then(() => {
                        setIsStartedScreenShare(true)
                        const icon = <FontAwesomeIcon icon={['far', 'screencast']} style={{ color: '#48A0F8' }} />
                        showToast(t('screenShare.sharingScreen'), { id: 'screen-sharing', duration: Infinity, icon })
                    })
                    .catch(() => {})
            } else if (isStartedScreenShare) {
                await mediaStream?.stopShareScreen().then(() => {
                    showToast.dismiss('screen-sharing')
                })
                setIsStartedScreenShare(false)
            }
            ReactGA.event('Screenshare Clicked')
        } catch (error) {
            setIsStartedScreenShare(false)
            console.error(error)
            ReactGA.event({
                category: 'Screenshare start failed',
                action: 'Error sharing screen',
            })
        }
    }, [
        isStartedScreenShare,
        selfShareRef,
        mediaStream,
        setIsStartedScreenShare,
        showToast,
        t,
        canShareAudioSimultaneously,
    ])

    const onMenuItemClick = (item: SharePermissionType) => {
        setActiveOption(item)
        switchSharePermissions(item)
    }

    const determineShareEnabled = useCallback(
        (privilege: SharePrivilege): boolean => {
            switch (privilege) {
                case SharePrivilege.Locked:
                    return zoomClient.isHost() || zoomClient.isManager()
                case SharePrivilege.MultipleShare:
                case SharePrivilege.Unlocked:
                    return true
                default:
                    return false
            }
        },
        [zoomClient]
    )

    const onSharePrivilegeChange = useCallback(
        (payload: { privilege: SharePrivilege }) => {
            const shareEnabled = determineShareEnabled(payload.privilege)
            setShareEnabled(shareEnabled)
            if (!zoomClient.isHost()) {
                switch (payload.privilege) {
                    case SharePrivilege.Locked:
                        showToast(
                            <ToastNotification
                                icon={['fal', 'circle-info']}
                                iconStyle={{ color: '#48A0F8' }}
                                msg={t('screenShare.onlyCoachAndHostCanShare')}
                            />,
                            {
                                id: payload.privilege.toString(),
                            }
                        )
                        break
                    case SharePrivilege.MultipleShare:
                    case SharePrivilege.Unlocked:
                        showToast(
                            <ToastNotification
                                icon={['fal', 'circle-info']}
                                iconStyle={{ color: '#48A0F8' }}
                                msg={t('screenShare.allParticipantsCanShare')}
                            />,
                            {
                                id: payload.privilege.toString(),
                            }
                        )
                        break
                    default:
                        return false
                }
            }
        },
        [determineShareEnabled, showToast, zoomClient, t]
    )

    const onPassivelyStopShare = useCallback(() => {
        setIsStartedScreenShare(false)
        showToast.dismiss('screen-sharing')
    }, [setIsStartedScreenShare, showToast])

    useEffect(() => {
        zoomClient.on('share-privilege-change', onSharePrivilegeChange)
        zoomClient.on('passively-stop-share', onPassivelyStopShare)
        return () => {
            zoomClient.off('share-privilege-change', onSharePrivilegeChange)
            zoomClient.off('passively-stop-share', onPassivelyStopShare)
        }
    }, [zoomClient, onSharePrivilegeChange, onPassivelyStopShare])

    const handleChevronClick = (e: React.MouseEvent) => {
        e.preventDefault()
        e.stopPropagation()
        setIsOpen(() => !isOpen)
    }

    if (isAndroidOrIOSBrowser()) return null

    return (
        <div>
            {isHostOrManager && (
                <MediaBtnContainer active={isStartedScreenShare} as="div">
                    <button
                        disabled={!shareEnabled}
                        data-tooltip-id="cast-pre-action"
                        onClick={e => {
                            e.stopPropagation()
                            onScreenShareClick()
                        }}
                    >
                        <FontAwesomeIcon icon={['far', 'screencast']} fixedWidth />
                    </button>
                    <button id="cast-pre-tooltip" disabled={!shareEnabled} onClick={handleChevronClick}>
                        <FontAwesomeIcon
                            icon={['fas', 'chevron-up']}
                            flip={isOpen ? 'vertical' : undefined}
                            fixedWidth
                        />
                    </button>
                </MediaBtnContainer>
            )}

            {!isHostOrManager && (
                <MediaBtnContainer
                    active={isStartedScreenShare}
                    disabled={!shareEnabled}
                    data-tooltip-id="cast-pre-action"
                    onClick={e => {
                        e.stopPropagation()
                        onScreenShareClick()
                    }}
                >
                    <FontAwesomeIcon icon={['far', 'screencast']} fixedWidth />
                </MediaBtnContainer>
            )}

            <Tip
                id="cast-pre-action"
                content={
                    isStartedScreenShare
                        ? t('screenShare.stopSharing')
                        : t('screenShare.shareScreen', { disabled: !shareEnabled ? t('screenShare.disabled') : '' })
                }
            />

            <Tip
                id="mic-pre-tooltip-ele"
                anchorSelect="#mic-pre-tooltip"
                isOpen={isOpen}
                setIsOpen={() => setIsOpen(prev => !prev)}
                globalCloseEvents={{ escape: true, clickOutsideAnchor: true }}
                imperativeModeOnly={true}
                clickable
                role="dialog"
                className="wide-tooltip"
                openOnClick={true}
            >
                <div>
                    <StyledDropdownOptions>
                        <div className="devices-group">
                            <h3 className="label">{t('screenShare.sharingOptions')}</h3>
                            {menuItems.map(option => {
                                return (
                                    <li key={option}>
                                        <button
                                            className={activeOption === option ? 'active' : ''}
                                            onClick={() => onMenuItemClick(option)}
                                        >
                                            {t(option)}
                                        </button>
                                    </li>
                                )
                            })}
                        </div>
                        <CloseFloatingBtn onClose={() => setIsOpen(false)} />
                    </StyledDropdownOptions>
                </div>
            </Tip>
        </div>
    )
}

interface ScreenShareLockButtonProps {
    isLockedScreenShare: boolean
    onScreenShareLockClick: () => void
}

const ScreenShareLockButton = (props: ScreenShareLockButtonProps) => {
    const { isLockedScreenShare, onScreenShareLockClick } = props

    return (
        <Tooltip text={isLockedScreenShare ? 'unlock screen share' : ' lock screen share'} placement="topCenter">
            <button onClick={onScreenShareLockClick}>Lock screen</button>
        </Tooltip>
    )
}

export { ScreenShare, ScreenShareLockButton }
