import './video.scss'

import { Participant, ZoomClient } from '../../types/video-types'
import { VideoCapturingState, VideoQuality } from '@zoom/videosdk'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { MediaContext } from '../../types/video-types'
import { SELF_VIDEO_ID } from '../../util/video-constants'
import UserVideo from '../../../../components/UserVideo/UserVideo'
import _ from 'lodash'
import { isShallowEqual } from '../../util/util'
import { useCanvasDimension } from '../../hooks/useCanvasDimension'
import useParticipants from '../../../../hooks/useParticipants'
import { usePrevious } from '../../hooks/usePrevious'
import { useShare } from '../../hooks/useShare'
import { useTranslation } from 'react-i18next'
import { useZoomContext } from '../../../../context/ZoomContext'
import { useAutoStartMedia } from '../../hooks/useAutoStartMedia'
import { useUI } from '../../../../context/UIContext'
import ToastNotification from '../Misc/ToastNotification'
import { NotificationWithCross } from '../../../../components/NotificationWithCross'

type Props = {
    zoomClient: ZoomClient
    selfShareRef: any
    mediaContext: MediaContext
}

const VideoSingle = ({ selfShareRef, mediaContext }: Props) => {
    const {
        video: { decode: isVideoDecodeReady },
    } = mediaContext
    const { t } = useTranslation('meeting')
    const { zoomClient, mediaStream, currentUserInfo } = useZoomContext()
    useAutoStartMedia({ zoomClient, mediaStream })
    const { participants } = useParticipants()
    const videoRef = useRef<HTMLCanvasElement | null>(null)
    const shareRef = useRef<HTMLCanvasElement | null>(null)
    const shareContainerRef = useRef<HTMLDivElement | null>(null)
    const [activeVideo, setActiveVideo] = useState<number>(mediaStream?.getActiveVideoId() ?? 0)
    const previousActiveUser = useRef<Participant>()
    const canvasDimension = useCanvasDimension(mediaStream, videoRef)
    const previousCanvasDimension = usePrevious(canvasDimension)
    const { showToast } = useUI()
    const {
        isRecieveSharing,
        isStartedShare: isSendSharing,
        sharedContentDimension,
    } = useShare(zoomClient, mediaStream, shareRef)
    const [containerDimension, setContainerDimension] = useState({
        width: 0,
        height: 0,
    })
    const [shareViewDimension, setShareViewDimension] = useState({
        width: 0,
        height: 0,
    })

    const isSharing = isRecieveSharing || isSendSharing

    useEffect(() => {
        if (!isShallowEqual(shareViewDimension, sharedContentDimension)) {
            mediaStream?.updateSharingCanvasDimension(shareViewDimension.width, shareViewDimension.height)
        }
    }, [mediaStream, sharedContentDimension, shareViewDimension])

    const activeUser = useMemo(
        () => participants.find(user => user.userId === activeVideo),
        [participants, activeVideo]
    )

    useEffect(() => {
        if (!mediaStream?.isSupportMultipleVideos() || !mediaStream?.isSupportVirtualBackground()) {
            showToast(
                <ToastNotification icon={['far', 'circle-info']} iconStyle={{ color: '#00A3FF' }}>
                    <NotificationWithCross id="device-compatibility">
                        {t('Your will join to a simplified version due to device resources')}
                    </NotificationWithCross>
                </ToastNotification>,
                { id: 'device-compatibility', duration: 10000 }
            )
        }
    }, [])

    useEffect(() => {
        if (mediaStream && videoRef.current && isVideoDecodeReady && activeUser?.userId !== currentUserInfo?.userId) {
            const handleVideoRendering = () => {
                if (activeUser?.bVideoOn) {
                    mediaStream.renderVideo(
                        videoRef.current!,
                        activeUser.userId,
                        canvasDimension.width,
                        canvasDimension.height,
                        0,
                        0,
                        VideoQuality.Video_360P as any
                    )
                } else if (previousActiveUser.current?.bVideoOn) {
                    mediaStream.stopRenderVideo(videoRef.current!, previousActiveUser.current?.userId)
                }
            }

            const handleVideoPositioning = () => {
                if (activeUser?.bVideoOn && previousActiveUser.current?.bVideoOn) {
                    if (activeUser.userId !== previousActiveUser.current.userId) {
                        mediaStream.stopRenderVideo(videoRef.current!, previousActiveUser.current?.userId)
                        mediaStream.renderVideo(
                            videoRef.current!,
                            activeUser.userId,
                            canvasDimension.width,
                            canvasDimension.height,
                            0,
                            0,
                            VideoQuality.Video_360P as any
                        )
                    } else if (!isShallowEqual(canvasDimension, previousCanvasDimension)) {
                        mediaStream.adjustRenderedVideoPosition(
                            videoRef.current!,
                            activeUser.userId,
                            canvasDimension.width,
                            canvasDimension.height,
                            0,
                            0
                        )
                    }
                }
            }

            handleVideoRendering()
            handleVideoPositioning()

            previousActiveUser.current = activeUser
        }
    }, [mediaStream, activeUser, isVideoDecodeReady, canvasDimension, previousCanvasDimension, currentUserInfo])

    const handleCaptureChange = useCallback(
        (payload: { state: VideoCapturingState }) => {
            const canvasSelfElement = document.querySelector(`#${SELF_VIDEO_ID}`) as HTMLCanvasElement
            if (canvasSelfElement) {
                if (payload.state === 'Stopped') {
                    mediaStream?.stopRenderVideo(canvasSelfElement, zoomClient.getSessionInfo().userId).catch(() => {
                        console.log('Error stopping render self video on video single')
                    })
                } else if (payload.state === 'Started') {
                    mediaStream
                        ?.renderVideo(canvasSelfElement, zoomClient.getSessionInfo().userId, 450, 253, 0, 0, 3)
                        .catch(() => console.log('Error rendering self video on video single'))
                }
            }
        },
        [mediaStream, zoomClient]
    )

    const onActiveVideoChange = useCallback(
        (payload: any) => {
            const { userId } = payload
            if (userId !== currentUserInfo?.userId) {
                setActiveVideo(userId)
            }
        },
        [currentUserInfo]
    )

    useEffect(() => {
        zoomClient.on('video-active-change', onActiveVideoChange)
        zoomClient.on('video-capturing-change', handleCaptureChange)
        return () => {
            zoomClient.off('video-active-change', onActiveVideoChange)
            zoomClient.off('video-capturing-change', handleCaptureChange)
        }
    }, [handleCaptureChange, onActiveVideoChange, zoomClient])

    useEffect(() => {
        const currentRef = shareContainerRef.current
        if (currentRef && isSharing) {
            const resizeObserver = new ResizeObserver(
                _.debounce(() => {
                    const { width, height } = currentRef.getBoundingClientRect()
                    setContainerDimension({ width, height })
                }, 100)
            )

            resizeObserver.observe(currentRef)
            return () => resizeObserver.disconnect()
        }
    }, [isSharing])

    useEffect(() => {
        if (isSharing && shareContainerRef.current) {
            const { width, height } = sharedContentDimension
            const { width: containerWidth, height: containerHeight } = containerDimension
            const ratio = Math.min(containerWidth / width, containerHeight / height, 1)
            // Add validation
            const newWidth = Math.max(1, Math.floor(width * ratio))
            const newHeight = Math.max(1, Math.floor(height * ratio))
            setShareViewDimension({
                width: newWidth,
                height: newHeight,
            })
        }
    }, [isSharing, sharedContentDimension, containerDimension])

    return (
        <div>
            <div className="viewportsingle">
                <div className={`share-container ${isSharing ? 'in-sharing' : ''}`} ref={shareContainerRef}>
                    <div
                        className="share-container-viewport"
                        style={{
                            width: `${shareViewDimension.width}px`,
                            height: `${shareViewDimension.height}px`,
                        }}
                    >
                        <canvas
                            id="them"
                            className={`share-canvas ${isSendSharing && !isRecieveSharing ? 'hidden' : ''}`}
                            ref={shareRef}
                        />
                        {mediaStream?.isStartShareScreenWithVideoElement() ? (
                            <video
                                id="self"
                                className={`share-canvas ${isRecieveSharing && !isSendSharing ? 'hidden' : ''}`}
                                ref={selfShareRef}
                            />
                        ) : (
                            <canvas
                                id="self"
                                className={`share-canvas ${isRecieveSharing && !isSendSharing ? 'hidden' : ''}`}
                                ref={selfShareRef}
                            />
                        )}
                    </div>
                </div>
                <div className={`video-container ${isSharing ? 'in-sharing' : ''}`}>
                    <canvas className="video-canvas" id="video-canvas" width="800" height="600" ref={videoRef} />
                    {mediaStream?.isRenderSelfViewWithVideoElement() ? (
                        <video id={SELF_VIDEO_ID} width="450" height="253" className={`self-video`} />
                    ) : (
                        <canvas id={SELF_VIDEO_ID} width="450" height="253" className={`self-video`} />
                    )}
                    {activeUser && activeUser.userId !== currentUserInfo?.userId && (
                        <UserVideo
                            {...activeUser}
                            style={{
                                width: '100%',
                                height: '100%',
                                position: 'absolute',
                                top: '0',
                                left: '0',
                            }}
                        />
                    )}
                    {participants.length === 1 && (
                        <div className="solo-participant-message">{t('singleView.waitingForRest')}</div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default VideoSingle
