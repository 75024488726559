import { useEffect, useState } from 'react'
import DayJS from '../helpers/DayJS'
import { MeetingRoom } from '../pages/meeting-view/api'
import { getSessionParameters } from '../helpers/urls'
import { useTranslation } from 'react-i18next'

export const ChangeMeetingText = ({ room, duration }: { duration: number; room: MeetingRoom }) => {
    const { t } = useTranslation('notifications')
    const [timer, setTimer] = useState(duration)
    const { room: currentRoom } = getSessionParameters()

    useEffect(() => {
        setTimer(duration)
    }, [duration])

    useEffect(() => {
        const interval = setInterval(() => setTimer(prev => prev - 1000), 1000)
        return () => clearInterval(interval)
    }, [])

    const roomName =
        room === 'general'
            ? t('changeMeeting.generalRoom')
            : currentRoom === 'teams'
            ? t('changeMeeting.newTeamRoom')
            : t('changeMeeting.teamRoom')

    const time = DayJS().add(timer, 'milliseconds').toNow(true)

    return <>{t('changeMeeting.willBeSentTo', { roomName, time })}</>
}
