import SquareButton from '../../../../components/SquareButton'
import { GivePulseItem } from './GivePulseItem'
import React, { useState, useEffect, useRef, ReactNode } from 'react'
import { createPortal } from 'react-dom'
import styled from 'styled-components'
import { useHostFunctions } from '../../hooks/useHostFunctions'
import { useZoomContext } from '../../../../context/ZoomContext'
import { useTranslation } from 'react-i18next'
import { Participant } from '../../types/video-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Tooltip } from 'react-tooltip'
interface ParticipantItemProps {
    participant: Participant
}

const ParticipantItem: React.FC<ParticipantItemProps> = ({ participant }) => {
    const { t } = useTranslation('sidebar')
    const { isHost, isHostOrManager, currentUserInfo } = useZoomContext()
    const { muteParticipant, makeHost, makeManager, removeUser, chatUser } = useHostFunctions()
    const [showOptions, setShowOptions] = useState(false)
    const buttonRef = useRef<HTMLDivElement>(null)
    const { displayName } = participant

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (buttonRef.current && !buttonRef.current.contains(event.target as Node)) {
                setShowOptions(false)
            }
        }

        if (showOptions) {
            document.addEventListener('mousedown', handleClickOutside)
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [showOptions])

    return (
        <ParticipantItemStyled>
            <div className="flex">
                <div className="name">
                    {displayName}{' '}
                    {participant.isHost
                        ? `(${t('participants.host')})`
                        : participant.isManager
                        ? `(${t('participants.cohost')})`
                        : ''}
                </div>
            </div>
            {participant.userId !== currentUserInfo?.userId && (
                <div className="options">
                    <GivePulseItem {...participant} />
                    <OptionBtn
                        data-tooltip-id="option-btn-tip"
                        data-tooltip-content="Audio participante"
                        disabled={!isHost}
                        muted={participant.muted === true || participant.muted === undefined}
                        onClick={() => (isHostOrManager ? muteParticipant(participant) : null)}
                    >
                        <FontAwesomeIcon
                            className="icon-option"
                            icon={['far', participant.muted ? 'microphone-slash' : 'microphone']}
                        />
                    </OptionBtn>
                    {isHost && !participant.isHost && (
                        <OptionBtn
                            data-tooltip-id="option-btn-tip"
                            data-tooltip-content="Hacer anfitrión"
                            onClick={() => makeHost(participant)}
                        >
                            <FontAwesomeIcon className="icon-option" icon={['far', 'crown']} />
                        </OptionBtn>
                    )}
                    {isHost && !participant.isManager && (
                        <OptionBtn
                            data-tooltip-id="option-btn-tip"
                            data-tooltip-content="Hacer Co-anfitrión"
                            onClick={() => makeManager(participant)}
                        >
                            <FontAwesomeIcon className="icon-option" icon={['far', 'medal']} />
                        </OptionBtn>
                    )}
                    <OptionBtn
                        data-tooltip-id="option-btn-tip"
                        data-tooltip-content="Chat"
                        onClick={() => chatUser(participant)}
                    >
                        <FontAwesomeIcon className="icon-option" icon={['far', 'message-dots']} />
                    </OptionBtn>
                    {isHostOrManager && (
                        <OptionBtn
                            data-tooltip-id="option-btn-tip"
                            data-tooltip-content="Expulsar"
                            onClick={() => removeUser(participant)}
                        >
                            <FontAwesomeIcon className="icon-option" icon={['far', 'ban']} />
                        </OptionBtn>
                    )}
                    <Tooltip id="option-btn-tip" />
                </div>
            )}
        </ParticipantItemStyled>
    )
}

const OptionBtn = styled.button<{ muted?: boolean }>`
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    margin: 0 5px;
    color: ${props => (props.muted ? props.theme.color.salmon : 'white')};
    font-size: 16px;
    display: flex;
    align-items: center;
`

const ParticipantItemStyled = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 13px;
    font-weight: 500;
    min-height: 44px;
    color: white;
    gap: 5px;

    .flex {
        display: flex;
        flex: 1;
        overflow: hidden;
    }

    .name {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        flex-shrink: 1; /* Allows the name to shrink if space is tight */
        flex-grow: 1;
    }

    .options {
        display: flex;
        gap: 3px;
        justify-content: flex-end;
        flex-shrink: 1; /* Options can shrink if space becomes very limited */
    }
`

export default ParticipantItem
