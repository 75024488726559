import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { PrimaryBtnBlueShort } from '../../../components/Buttons'
import { useOnboarding } from '../../../context/OnboardingContext'
import { PlayerContainer, VideoWrapper } from './VideoPlayer'

const CoverImage = styled.div<{ imageUrl: string }>`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(${props => props.imageUrl});
    background-size: cover;
    background-position: center;
`

const Controls = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    margin-top: 1rem;
`

interface CourseCoverProps {
    onProceed: () => void
    courseTitle?: string
    courseDescription?: string
    coverImageUrl?: string
}

const CourseCover: React.FC<CourseCoverProps> = ({ onProceed }) => {
    const { t } = useTranslation('onboarding')
    const { config } = useOnboarding()
    const cover = config?.onboardingConfig.courseCover.coverImageUrl

    return (
        <PlayerContainer>
            <VideoWrapper>
                <CoverImage imageUrl={cover || ''} />
            </VideoWrapper>
            <Controls>
                <div>
                    <PrimaryBtnBlueShort onClick={onProceed}>{t('courseCover.startButton')}</PrimaryBtnBlueShort>
                </div>
            </Controls>
        </PlayerContainer>
    )
}

export default CourseCover
