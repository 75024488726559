import { MediaShape } from '../pages/meeting-view/hooks/useMediaContext'

export const capitalize = (text?: string) => `${text}`.trim().replace(/^\w/, c => c.toUpperCase())
export const capitalizeAll = (text?: string) => `${text}`.split(' ').map(capitalize).join(' ')

export const checkAllEncodingsTrue = (shape: MediaShape) => {
    return Object.values(shape).every(item => item.encode)
}

export function extractRoomFromTopic(topic?: string) {
    if (!topic) return null
    const regex = /@t(\d+)_/
    const match = topic.match(regex)
    return match ? Number(match[1]) + 1 : null
}

export const filterMessage = (message: string) => {
    return message.length > 1000 ? message.slice(0, 1000) + '...' : message
}
