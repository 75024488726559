import { useEffect } from 'react'
import * as Sentry from '@sentry/react'
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom'

const getEnvironment = () => {
    const hostname = window.location.hostname
    if (hostname === 'localhost') {
        return 'local'
    } else if (hostname.includes('develop')) {
        return 'development'
    } else if (hostname.includes('staging')) {
        return 'staging'
    } else if (hostname.includes('demo')) {
        return 'demo'
    } else {
        return 'production'
    }
}

Sentry.init({
    dsn: 'https://287fd254265b76500afd8e08117b645d@o4507827436716032.ingest.de.sentry.io/4507827467649104',
    integrations: [
        Sentry.reactRouterV6BrowserTracingIntegration({
            useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes,
        }),
        Sentry.sessionTimingIntegration(),
        Sentry.browserProfilingIntegration(),
    ],
    tracesSampleRate: 0.25,
    profilesSampleRate: 0.15,
    tracePropagationTargets: [/^\//, /^https:\/\/egg\.live/],
    environment: getEnvironment(),
    release: 'egg-video-sdk@3.0',
})

export const SentryFeedbackIntegration = Sentry.feedbackIntegration({
    name: 'feedbackIntegration',
    autoInject: false,
    colorScheme: 'system',
    // Translation
    triggerLabel: 'Enviar error',
    triggerAriaLabel: 'Enviar error',
    buttonLabel: 'Feedback',
    submitButtonLabel: 'Enviar Feedback',
    formTitle: 'Enviar Feedback',
    cancelButtonLabel: 'Cancelar',
    addScreenshotButtonLabel: 'Adjuntar captura de pantalla',
    nameLabel: 'Nombre',
    namePlaceholder: 'Tu nombre',
    emailLabel: 'Correo electrónico',
    emailPlaceholder: 'Tu correo electrónico',
    messageLabel: 'Descripción del error',
    messagePlaceholder: 'Describe el error que encontraste',
    successMessageText: '¡Gracias por tu feedback!',
})

Sentry.addIntegration(SentryFeedbackIntegration)
