import { getMyPulseData } from '../../../apis/api-pulses'
import { useQuery } from '@tanstack/react-query'

export const myPulseDataQueryKey = ['My Pulse Data']

export const useMyPulseData = () => {
    const { data, ...rest } = useQuery({
        queryKey: myPulseDataQueryKey,
        queryFn: getMyPulseData,
        retry: 3,
        staleTime: 1000 * 60 * 60 * 4, // 4 hours
    })

    return {
        ...data,
        ...rest,
    }
}
