import React, { ReactNode, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useOnboarding } from '../../context/OnboardingContext'
import { LoadingBolt } from '../../components/LoadingBolt'
import TeamsBuildingScreen from './components/TeamsBuildingScreen'
import CourseCover from './components/CourseCover'
import VideoPlayer from './components/VideoPlayer'
import { useVideoFlow } from './hooks/useVideoFlow'
import TeamsReadyTimer from './components/TeamsReadyTimer'
import styled from 'styled-components'
import { AutoplayInfo } from './components/NextVideoTimer'
import { LanguageSelector } from '../../components/LanguageSelector'
import CourseStartScreen from './components/CourseStartScreen'
import { completeVideo } from '../../apis/api-content'
import { IntercomSelector } from '../../components/IntercomSelector'

const OnboardingContainerStyled = styled.main`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

const Onboarding = () => {
    const { t } = useTranslation('onboarding')
    const {
        isLoading,
        error,
        config,
        updateVideoStatus,
        getAllVideos,
        showCover,
        setShowCover,
        isInitialLoad,
        setIsInitialLoad,
    } = useOnboarding()
    const { currentVideo, isPrePhase, shouldShowTeamsBuilding, shouldShowCourseStart, loading } = useVideoFlow()

    // Function to get next video information
    const getNextVideoInfo = (): AutoplayInfo => {
        if (!currentVideo || !config) return { hasNextVideo: false }
        const allVideos = getAllVideos()
        const currentIndex = allVideos.findIndex(v => v.id === currentVideo.id)
        const nextVideo = allVideos[currentIndex + 1]
        return {
            hasNextVideo: !!nextVideo,
            nextVideoTitle: nextVideo?.title,
        }
    }

    // Reset states when component unmounts
    useEffect(() => {
        return () => {
            setShowCover(true)
            setIsInitialLoad(true)
        }
    }, [setShowCover, setIsInitialLoad])

    // Reset cover state when moving to teams building
    useEffect(() => {
        if (shouldShowTeamsBuilding) {
            setShowCover(false)
        }
    }, [shouldShowTeamsBuilding, setShowCover])

    if (isLoading || loading) {
        return <LoadingBolt />
    }

    if (error) {
        return (
            <div className="flex items-center justify-center min-h-screen">
                <div className="text-red-500">{t('error.configLoading', { message: error.message })}</div>
            </div>
        )
    }

    if (!config) {
        return (
            <div className="flex items-center justify-center min-h-screen">
                <div className="text-red-500">{t('error.configNotFound')}</div>
            </div>
        )
    }

    const handleProceed = () => {
        setShowCover(false)
        setIsInitialLoad(false)
    }

    const handleVideoComplete = async () => {
        if (currentVideo) {
            try {
                await completeVideo(currentVideo.id)
            } catch (error) {
                console.error('Could not complete video:', error)
            } finally {
                updateVideoStatus(currentVideo.id, true)
            }
        }
    }

    // First, check if we should show the course start screen
    if (shouldShowCourseStart) {
        return (
            <OnboardingContainer>
                <CourseStartScreen onCourseStart={() => {}} />
                <LanguageSelector />
            </OnboardingContainer>
        )
    }

    if ((isInitialLoad || showCover) && !shouldShowTeamsBuilding) {
        return (
            <OnboardingContainer>
                <TeamsReadyTimer />
                <CourseCover
                    onProceed={handleProceed}
                    courseTitle={config.onboardingConfig.courseCover.coverImageUrl}
                    courseDescription={
                        isPrePhase ? t('courseCover.prePhaseDescription') : t('courseCover.sessionDescription')
                    }
                />
                <LanguageSelector />
            </OnboardingContainer>
        )
    }

    if (shouldShowTeamsBuilding) {
        return (
            <OnboardingContainer>
                <TeamsBuildingScreen />
            </OnboardingContainer>
        )
    }

    if (currentVideo) {
        return (
            <OnboardingContainer>
                <TeamsReadyTimer />
                <VideoPlayer
                    video={currentVideo}
                    onComplete={handleVideoComplete}
                    skippable={currentVideo.type !== 'mandatory'}
                    autoplayInfo={getNextVideoInfo()}
                />
            </OnboardingContainer>
        )
    }

    return null
}

export default Onboarding

const OnboardingContainer = ({ children }: { children: ReactNode }) => {
    return (
        <OnboardingContainerStyled>
            <IntercomSelector />
            {children}
        </OnboardingContainerStyled>
    )
}
