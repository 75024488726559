import { BrowserClient, feedbackIntegration, getClient } from '@sentry/react'
import { MediaBtnContainer } from '../../../pre-meeting/components/Buttons'
import { Tip } from '../../../../components/Tip'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import { getDeviceType } from '../../util/platform'

type FeedbackIntegration = ReturnType<typeof feedbackIntegration>

export function SentryFeedbackButton() {
    const { t } = useTranslation('App')
    const client = getClient<BrowserClient>()
    const feedback = client?.getIntegrationByName<FeedbackIntegration>('Feedback')

    // Don't render custom feedback button if Feedback integration isn't installed
    if (!feedback || getDeviceType() === 'mobile') {
        return null
    }

    return (
        <div>
            <MediaBtnContainer as="div" style={{ position: 'fixed', bottom: '20px', left: '20px', opacity: '.5' }}>
                <button
                    type="button"
                    onClick={async () => {
                        const form = await feedback.createForm()
                        form.appendToDom()
                        form.open()
                    }}
                    data-tooltip-id="sentry-feedback-action"
                >
                    <FontAwesomeIcon icon={['far', 'bug']} fixedWidth />
                </button>
                <Tip id="sentry-feedback-action" content={t('sentry.feedbackTooltip')} />
            </MediaBtnContainer>
        </div>
    )
}
