import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { MediaBtnContainer } from './Buttons'
import { StyledDropdownOptions } from '../../meeting-view/components/Misc/StyledDropdownOptions'
import Styles from './PreMeetingAudioControls.module.css'
import { Tip } from '../../../components/Tip'
import { useMyVideo } from '../hooks/useMyVideo'
import { usePreMeetingContext } from '../../../context/PreMeetingContext'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useBackgroundEffects } from '../../../hooks/useBackgroundEffects'
import { usePermissions } from '../../../context/PermissionsContext'

export const PreMeetingCameraControls = () => {
    const { cameras, switchCamera } = useMyVideo()
    const { activeCamera } = usePreMeetingContext()
    const [isOpen, setIsOpen] = useState(false)
    const { t } = useTranslation(['premeeting', 'meeting'])
    const { bgs, onFxClick, isFx, isVirtualBackgroundSupported, selectedEffect } = useBackgroundEffects(true)
    const { camera: permissionGranted } = usePermissions()

    return (
        <>
            <MediaBtnContainer id="cam-pre-tooltip" disabled={!permissionGranted}>
                <div data-tooltip-id="cam-pre-action">
                    <FontAwesomeIcon icon={['far', 'stars']} fixedWidth />
                </div>
                <div className={`anchor ${Styles.chevron}`}>
                    <FontAwesomeIcon icon={['fas', 'chevron-up']} flip={isOpen ? 'vertical' : undefined} fixedWidth />
                </div>
            </MediaBtnContainer>
            <Tip id="cam-pre-action" content={t('cameraControls.cameraOptions')} />
            {permissionGranted && (
                <Tip
                    id="cam-pre-tooltip-element"
                    anchorSelect="#cam-pre-tooltip"
                    positionStrategy="fixed"
                    clickable
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    globalCloseEvents={{ escape: true, clickOutsideAnchor: true }}
                    role="dialog"
                    className="wide-tooltip"
                >
                    <div className={Styles.tooltip}>
                        <StyledDropdownOptions>
                            <div className="devices-group">
                                <h3 className="label">{t('cameraControls.cameras')}</h3>
                                {cameras.map(({ deviceId, label }) => {
                                    const isSelected = activeCamera?.deviceId === deviceId
                                    return (
                                        <li key={deviceId} className={isSelected ? Styles.selected : undefined}>
                                            <button
                                                className={isSelected ? 'active' : ''}
                                                onClick={() => switchCamera(deviceId)}
                                            >
                                                {label}
                                            </button>
                                        </li>
                                    )
                                })}
                            </div>
                        </StyledDropdownOptions>
                        {isVirtualBackgroundSupported && (
                            <StyledDropdownOptions>
                                <div className="devices-group">
                                    <h3 className="label">{t('cameraControls.effects')}</h3>
                                    {bgs.map(fx => (
                                        <li key={fx.label}>
                                            <button
                                                className={isFx && fx.deviceId === selectedEffect ? 'active' : ''}
                                                onClick={() => onFxClick(fx)}
                                            >
                                                {fx.label}
                                            </button>
                                        </li>
                                    ))}
                                </div>
                            </StyledDropdownOptions>
                        )}
                    </div>
                </Tip>
            )}
        </>
    )
}
