import { useTranslation } from 'react-i18next'
import { CloseMeeting } from '../components/CloseMeeting'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GoBackToMeeting } from '../components/GoBackToMeeting'
import { Timer } from '../../../components/Timer'
import { Feedback } from '../components/Feedback'
import styled from 'styled-components'

export const NeedABreak = () => {
    const { t } = useTranslation('postmeeting')
    const initTime = 300

    return (
        <StyledPostMeetingContainer>
            <div className="icon rounded-icon">
                <FontAwesomeIcon icon={['far', 'cup-togo']} />
            </div>
            <h1>{t('needABreak.title')}</h1>
            <h2 style={{ fontWeight: 'normal' }}>
                {t('needABreak.rememberToReturn')}
                <br />
                {t('needABreak.participantsCannotSeeYou')}
            </h2>
            <Feedback />
            <div className="row-2">
                <Timer initTime={initTime} onFinish={undefined} />
                <div className="row-2">
                    <GoBackToMeeting />
                    <CloseMeeting />
                </div>
            </div>
        </StyledPostMeetingContainer>
    )
}

const StyledPostMeetingContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
    justify-content: center;
    align-items: center;
    h1 {
        font-size: 24px;
    }
    @media (max-width: 768px) {
        .rounded-icon {
            display: none;
        }
        h2 {
            font-size: 16px;
        }
    }
`
