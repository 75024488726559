/* eslint-disable no-throw-literal */
import { PropsWithChildren, createContext, useContext, useEffect, useRef } from 'react'
import { Socket, io } from 'socket.io-client'

import { getSessionParameters } from '../helpers/urls'
import { useActions } from '../hooks/useActions'
import { useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useUI } from './UIContext'

const server = process.env.REACT_APP_URL_WEBSOCKET
// eslint-disable-next-line no-restricted-globals
const debug = location.hostname === 'localhost'

const SocketsContext = createContext<ContextTypes | null>(null)
SocketsContext.displayName = 'SocketContext'

export const SocketProvider = ({ children }: PropsWithChildren) => {
    const [params] = useSearchParams()
    const urlToken = params.get('widgetToken')?.replaceAll('"', '').replaceAll("'", '')
    const sessionToken = getSessionParameters().widgetToken
    const token = urlToken || sessionToken

    const { t } = useTranslation('App')
    const { showToast, showError } = useUI()
    const { actionDispatcher } = useActions()
    const socket = useRef<Socket | null>(null)

    const emit = (eventName: string, payload?: Record<string, unknown>) => {
        if (!socket.current) throw '❗ Socket connection not available.'
        return socket.current.emit(eventName, payload)
    }
    const volatileEmit = (eventName: string, payload?: Record<string, unknown>) => {
        if (!socket.current) throw '❗ Socket connection not available.'
        return socket.current.volatile.emit(eventName, payload)
    }

    /* Manage connection */
    useEffect(() => {
        if (!token && process.env.NODE_ENV !== 'test') return
        if (!server) {
            debug && showError('❗ Server not available')
            return
        }

        // Create connection
        socket.current = io(server, {
            query: { token },
        })

        socket.current.on('connect', () => debug && showToast.success(t('Connected to socket server')))

        socket.current.on('disconnect', () => debug && showError('Disconnected from socket server'))

        return () => {
            socket.current?.disconnect()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token])

    /* Manage events */
    useEffect(() => {
        socket.current?.onAny((event, payload) => actionDispatcher({ event, payload }))

        return () => {
            socket.current?.offAny()
        }
    }, [actionDispatcher])

    return <SocketsContext.Provider value={{ emit, volatileEmit }}>{children}</SocketsContext.Provider>
}

export const useSocket = () => {
    const context = useContext(SocketsContext)
    if (context === undefined) {
        throw new Error('useSocket must be used within a SocketProvider')
    }
    return context
}

interface ContextTypes {
    // eslint-disable-next-line no-unused-vars
    emit: (eventName: string, payload?: Record<string, unknown>) => Socket
    // eslint-disable-next-line no-unused-vars
    volatileEmit: (eventName: string, payload?: Record<string, unknown>) => Socket
}
