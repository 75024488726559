import styled from 'styled-components'

export const StyledDropdownOptions = styled.ul`
    list-style: none;
    margin-left: 0;
    padding-left: 0;
    display: flex;
    flex-direction: column;
    gap: 7px;
    align-items: flex-start !important;
    .devices-group {
        width: 100%;
        li {
            overflow: hidden;
            box-sizing: border-box;
            width: 100%;
            padding: 7px;
            margin-left: 0;
            padding-left: 0;
            text-align: left;
            &:not(:last-child) {
                border-bottom: ${props => `1px solid ${props.theme.color.slateGray}`};
                border-right: none;
            }
            button {
                font-size: 13px;
                background: none;
                border: none;
                color: white;
                cursor: pointer;
                text-align: left !important;
                &:hover {
                    font-weight: bold;
                }
                &.active {
                    font-weight: bold;
                    color: ${props => props.theme.color.electricBlue} !important;
                }
                &.danger {
                    color: ${props => props.theme.color.salmon};
                    &:hover {
                        color: ${props => props.theme.color.salmonBorders};
                    }
                }
            }
        }
    }
`
