import { useState, useEffect, useCallback } from 'react'
import { useRemainingTime } from './useRemainingTime'
import { BaseVideo, useOnboarding } from '../../../context/OnboardingContext'
import Logger from '../../../helpers/logger'

interface VideoFlowState {
    currentVideo: BaseVideo | null
    isPrePhase: boolean
    shouldShowTeamsBuilding: boolean
    shouldShowCourseStart: boolean
    loading: boolean
}

export const useVideoFlow = () => {
    const { config, getMandatoryVideos } = useOnboarding()
    const { remainingTime } = useRemainingTime()
    const [state, setState] = useState<VideoFlowState>({
        currentVideo: null,
        isPrePhase: true,
        shouldShowTeamsBuilding: false,
        shouldShowCourseStart: false,
        loading: true,
    })

    const checkCourseStart = useCallback(() => {
        if (!config) return true

        const courseStartMs = config.onboardingConfig.courseStartTime * 1000
        const currentTimeMs = Date.now()
        const hasStarted = currentTimeMs >= courseStartMs

        Logger.debug('VideoFlow', 'Course start check:', {
            startTimeUTC: new Date(courseStartMs).toUTCString(),
            startTimeLocal: new Date(courseStartMs).toLocaleString(),
            currentTimeUTC: new Date(currentTimeMs).toUTCString(),
            currentTimeLocal: new Date(currentTimeMs).toLocaleString(),
            hasStarted,
            debug: {
                courseStartMs,
                currentTimeMs,
                comparison: `${currentTimeMs} >= ${courseStartMs}`,
            },
        })

        return hasStarted
    }, [config])

    const areVideosCompleted = useCallback((videos: BaseVideo[]) => {
        const completed = videos.every(video => video.completed)
        Logger.debug('VideoFlow', 'Checking video completion:', {
            totalVideos: videos.length,
            completedCount: videos.filter(v => v.completed).length,
            allCompleted: completed,
        })
        return completed
    }, [])

    const hasTimeForVideo = useCallback(
        (video: BaseVideo): boolean => {
            if (video.type === 'mandatory') {
                Logger.debug('VideoFlow', 'Mandatory video - always has time', { videoId: video.id })
                return true
            }
            if (!remainingTime) {
                Logger.debug('VideoFlow', 'No remaining time available for video', { videoId: video.id })
                return false
            }
            const hasTime = remainingTime > video.length
            Logger.debug('VideoFlow', 'Checking time for video', {
                videoId: video.id,
                videoLength: video.length,
                remainingTime,
                hasTime,
            })
            return hasTime
        },
        [remainingTime]
    )

    const getNextVideo = useCallback(() => {
        if (!config || !checkCourseStart()) return { video: null, isPrePhase: false }

        Logger.debug('VideoFlow', 'Starting next video selection')

        const allVideos = config.dailyContent.onboarding.videos
        const prePhaseVideos = allVideos.filter(v => v.metadata.phase === 'pre')

        Logger.debug('VideoFlow', 'Pre-phase videos status:', {
            total: prePhaseVideos.length,
            completed: prePhaseVideos.filter(v => v.completed).length,
            remaining: prePhaseVideos.filter(v => !v.completed).length,
        })

        const prePhaseCompleted = areVideosCompleted(prePhaseVideos)

        if (!prePhaseCompleted) {
            const nextPrePhase = prePhaseVideos.find(v => !v.completed)
            if (nextPrePhase) {
                Logger.debug('VideoFlow', 'Selected next pre-phase video:', {
                    videoId: nextPrePhase.id,
                    title: nextPrePhase.title,
                    type: nextPrePhase.type,
                })
                return { video: nextPrePhase, isPrePhase: true }
            }
        }

        const mainPhaseVideos = allVideos.filter(
            v => v.metadata.phase === 'main' && v.metadata.category !== 'icebreaker'
        )
        const nextMandatory = mainPhaseVideos.find(v => !v.completed && v.type === 'mandatory')
        if (nextMandatory) return { video: nextMandatory, isPrePhase: false }

        const nextOptional = mainPhaseVideos.find(v => !v.completed && v.type !== 'mandatory' && hasTimeForVideo(v))
        if (nextOptional) return { video: nextOptional, isPrePhase: false }

        return { video: null, isPrePhase: false }
    }, [config, checkCourseStart, areVideosCompleted, hasTimeForVideo])

    useEffect(() => {
        if (!config) {
            Logger.debug('VideoFlow', 'No config available yet')
            return
        }

        const courseStarted = checkCourseStart()
        if (!courseStarted) {
            Logger.debug('VideoFlow', 'Course has not started yet, showing start screen')
            setState({
                currentVideo: null,
                isPrePhase: true,
                shouldShowTeamsBuilding: false,
                shouldShowCourseStart: true,
                loading: false,
            })
            return
        }

        const { video, isPrePhase } = getNextVideo()
        const mandatoryVideosCompleted = areVideosCompleted(getMandatoryVideos())

        Logger.debug('VideoFlow', 'Updating state', {
            nextVideo: video
                ? {
                      id: video.id,
                      title: video.title,
                      type: video.type,
                  }
                : null,
            isPrePhase,
            mandatoryVideosCompleted,
            shouldShowTeamsBuilding: !video && mandatoryVideosCompleted,
            shouldShowCourseStart: !courseStarted,
        })

        setState({
            currentVideo: video,
            isPrePhase,
            shouldShowTeamsBuilding: !video && mandatoryVideosCompleted,
            shouldShowCourseStart: !courseStarted,
            loading: false,
        })
    }, [config, getNextVideo, getMandatoryVideos, areVideosCompleted, checkCourseStart])

    return state
}
