import { decodeMeetingToken } from '../pages/meeting-view/api'
import { setNewToken } from '../apis/apis'
import { MeetingParams } from '../pages/meeting-view/types/types'
import { setSessionTags } from './sentry'

const KEY = 'URLSearchParams'

export const decodeSearchParams = <T = Record<string, string>>(searchParams: any): T => {
    return [...searchParams.entries()].reduce((acc, [key, val]) => {
        try {
            return {
                ...acc,
                [key]: JSON.parse(val),
            }
        } catch {
            return {
                ...acc,
                [key]: val,
            }
        }
    }, {})
}

export const persistSessionParameters = () => {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const searchParams = decodeSearchParams(urlParams)
    const accessToken = searchParams.widgetToken ?? getSessionParameters().widgetToken
    if (accessToken) setNewToken(accessToken)
    if (!!searchParams.reason) return
    if (searchParams.zoomToken) {
        const { room } = decodeMeetingToken(searchParams.zoomToken)
        searchParams.room = room
        searchParams[`${room}Token`] = searchParams.zoomToken
        delete searchParams.zoomToken
    }
    if (Object.keys(searchParams).length) {
        persistOnSession(searchParams)
        setSessionTags({
            courseName: searchParams.courseName,
            room: searchParams.room,
            _classId: searchParams._classId,
            _algorithmId: searchParams._algorithmId,
        })
    }
}

export const persistOnSession = (params: Params) => sessionStorage.setItem(KEY, JSON.stringify(params))

export const updateSessionParams = (params: Params) => persistOnSession({ ...getSessionParameters(), ...params })

export const getSessionParameters = <T = MeetingParams>(): Partial<T> => {
    try {
        const params = sessionStorage.getItem(KEY)
        if (!params || !params.startsWith('{') || !params.endsWith('}')) return {}
        return JSON.parse(params)
    } catch (error) {
        console.log(error)
        return {}
    }
}

export const deleteParamsFromURL = () => window.history.pushState({}, '', window.location.pathname)

export const isUrl = (str: string) => str.startsWith('http')

export const isEuropeanSite = ['https://telefonica.egg.live'].includes(window?.location?.origin ?? '')

type Params = Record<string, string | number | boolean | undefined | null>
