import { ConfirmationDialogStyled } from './ConfirmationDialog'
import { DangerButton, PrimaryBtnOutline } from '../Buttons'
import { useZoomContext } from '../../context/ZoomContext'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const QuitModal = () => {
    const { zoomClient, setConfirmationDialog } = useZoomContext()
    const navigate = useNavigate()
    const { t } = useTranslation('meeting')

    const handleQuit = async () => {
        setConfirmationDialog(undefined)
        await zoomClient
            .leave(false)
            .then(() => {})
            .catch(() => {
                console.log('Handling normal leave error')
            })
        navigate('/post-meeting/ended')
    }

    return (
        <ConfirmationDialogStyled>
            <div className="info">
                <h2>{t('quitModal.title')}</h2>
                <div>
                    <p>{t('quitModal.confirmQuit')}</p>
                </div>
            </div>
            <div className="actions">
                <DangerButton onClick={handleQuit}>{t('quitModal.quitButton')}</DangerButton>
                <PrimaryBtnOutline onClick={() => setConfirmationDialog(undefined)} colorTheme={'light'}>
                    {t('quitModal.returnButton')}
                </PrimaryBtnOutline>
            </div>
        </ConfirmationDialogStyled>
    )
}

export default QuitModal
