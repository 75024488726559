import { useState, useEffect, useCallback, useRef } from 'react'

interface UseBackgroundMusicProps {
    isTimeUp?: boolean
    autoplay?: boolean
}

interface UseBackgroundMusicReturn {
    isMuted: boolean
    toggleMute: () => void
    stopMusic: () => void
    isPlaying: boolean
}

export const useBackgroundMusic = ({
    isTimeUp = false,
    autoplay = true,
}: UseBackgroundMusicProps = {}): UseBackgroundMusicReturn => {
    const audioRef = useRef<HTMLAudioElement | null>(null)
    const [isMuted, setIsMuted] = useState(false)
    const [isPlaying, setIsPlaying] = useState(false)

    // Initialize audio
    useEffect(() => {
        const audioUrl = 'https://assets.mixkit.co/music/preview/mixkit-tech-house-vibes-130.mp3'
        audioRef.current = new Audio(audioUrl)

        if (audioRef.current) {
            audioRef.current.loop = true
            audioRef.current.volume = 0.3

            // Only start playing immediately if autoplay is true
            if (autoplay) {
                const playPromise = audioRef.current.play()
                if (playPromise !== undefined) {
                    playPromise
                        .then(() => {
                            setIsPlaying(true)
                        })
                        .catch(error => {
                            console.error('Auto-play failed:', error)
                            setIsPlaying(false)
                        })
                }
            }
        }

        return () => {
            if (audioRef.current) {
                audioRef.current.pause()
                audioRef.current = null
            }
        }
    }, [autoplay])

    // Stop music when time is up
    useEffect(() => {
        if (isTimeUp && audioRef.current) {
            audioRef.current.pause()
            setIsPlaying(false)
        }
    }, [isTimeUp])

    // Toggle mute and play/pause
    const toggleMute = useCallback(() => {
        if (!audioRef.current) return

        if (!isPlaying) {
            // If not playing, start playing
            const playPromise = audioRef.current.play()
            if (playPromise !== undefined) {
                playPromise
                    .then(() => {
                        setIsPlaying(true)
                        audioRef.current!.muted = false
                        setIsMuted(false)
                    })
                    .catch(error => {
                        console.error('Play failed:', error)
                    })
            }
        } else {
            // If already playing, just toggle mute
            audioRef.current.muted = !audioRef.current.muted
            setIsMuted(!isMuted)
        }
    }, [isMuted, isPlaying])

    // Stop music
    const stopMusic = useCallback(() => {
        if (!audioRef.current) return

        audioRef.current.pause()
        audioRef.current.currentTime = 0
        setIsPlaying(false)
    }, [])

    return {
        isMuted,
        toggleMute,
        stopMusic,
        isPlaying,
    }
}
