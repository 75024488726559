import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { MediaBtnContainer } from '../../../pre-meeting/components/Buttons'
import { StyledDropdownOptions } from '../Misc/StyledDropdownOptions'
import { Tip } from '../../../../components/Tip'
import { useBackgroundEffects } from '../../../../hooks/useBackgroundEffects'
import { useTranslation } from 'react-i18next'
import CloseFloatingBtn from '../../../../components/CloseFloatingBtn'

export const Blur = () => {
    const { t } = useTranslation('meeting')
    const [isOpen, setIsOpen] = useState(false)
    const { isFx, bgs, handleSetFxClick, onFxClick, isVirtualBackgroundSupported, selectedEffect } =
        useBackgroundEffects(false)

    if (!isVirtualBackgroundSupported) return null

    const handleChevronClick = (e: React.MouseEvent) => {
        e.preventDefault()
        e.stopPropagation()
        setIsOpen(() => !isOpen)
    }

    return (
        <div>
            <MediaBtnContainer active={isFx} as="div">
                <button
                    data-tooltip-id="fx-pre-action"
                    onClick={e => {
                        e.stopPropagation()
                        handleSetFxClick()
                    }}
                >
                    <FontAwesomeIcon icon={['far', 'sparkles']} fixedWidth />
                </button>
                <button id="mic-pre-tooltip" onClick={handleChevronClick}>
                    <FontAwesomeIcon icon={['fas', 'chevron-up']} flip={isOpen ? 'vertical' : undefined} fixedWidth />
                </button>
            </MediaBtnContainer>

            <Tip id="fx-pre-action" content={isFx ? t('blur.deactivateEffect') : t('blur.activateEffect')} />

            <Tip
                id="mic-pre-tooltip-ele"
                anchorSelect="#mic-pre-tooltip"
                isOpen={isOpen}
                setIsOpen={() => setIsOpen(prev => !prev)}
                globalCloseEvents={{ escape: true, clickOutsideAnchor: true }}
                imperativeModeOnly={true}
                clickable
                role="dialog"
                className="wide-tooltip"
                openOnClick={true}
            >
                <div onClick={e => e.stopPropagation()}>
                    <StyledDropdownOptions>
                        <div className="devices-group">
                            <h3 className="label">{t('blur.effects')}</h3>
                            {bgs.map(device => {
                                const { deviceId, label } = device
                                const isSelected = selectedEffect === deviceId
                                return (
                                    <li key={deviceId}>
                                        <button
                                            className={isSelected ? 'active' : ''}
                                            onClick={() => onFxClick(device)}
                                        >
                                            {label}
                                        </button>
                                    </li>
                                )
                            })}
                        </div>
                        <CloseFloatingBtn onClose={() => setIsOpen(false)} />
                    </StyledDropdownOptions>
                </div>
            </Tip>
        </div>
    )
}
