import { animateClassName, animateDuration } from '../../../../components/Pulse/PulseData'

import GradientBolt from '../../../../assets/bolts/gradient.svg'
import { Participant } from '../../types/video-types'
import SquareButton from '../../../../components/SquareButton'
import Styles from './GivePulseItem.module.css'
import WhiteBolt from '../../../../assets/bolts/white.svg'
import { useMyPulseData } from '../../hooks/useMyPulseData'
import { usePulses } from '../../hooks/usePulses'
import { useSessionParams } from '../../../../hooks/useSessionParams'
import { useState } from 'react'

export const GivePulseItem = ({ userIdentity }: Pick<Participant, 'userIdentity'>) => {
    const { canGivePulse, isPending } = useMyPulseData()
    const { givePulse } = usePulses()
    const { room } = useSessionParams()
    const [isBeingAnimated, setIsBeingAnimated] = useState(false)

    if (room !== 'teams') return null

    const disabled = !canGivePulse || isPending
    const onClick = () => {
        if (disabled) return
        givePulse(userIdentity)
        setIsBeingAnimated(true)

        new Promise(resolve => setTimeout(resolve, animateDuration)).then(() => setIsBeingAnimated(false))
    }

    return (
        <SquareButton disabled={disabled} handleClick={onClick} className={isBeingAnimated ? animateClassName : ''}>
            <img
                className={`${Styles.pulseImage}`}
                alt={userIdentity}
                src={isBeingAnimated ? WhiteBolt : GradientBolt}
            />
        </SquareButton>
    )
}
