import { useTranslation } from 'react-i18next'
import { CloseMeeting } from '../components/CloseMeeting'
import { Feedback } from '../components/Feedback'
import { useOnboarding } from '../../../context/OnboardingContext'
import { useEffect, useState } from 'react'
import VideoPlayer from '../../onboarding/components/VideoPlayer'

export const EndedByHost = () => {
    const { t } = useTranslation('postmeeting')
    const { config } = useOnboarding()
    const [showPostMeetingVideo, setShowPostMeetingVideo] = useState(false)

    console.log(config)

    const closureVideo = config?.dailyContent.onboarding.videos.find(v => v.metadata.phase === 'post' && !v.completed)

    useEffect(() => {
        if (config) {
            const { enabled } = config.onboardingConfig
            if (enabled && closureVideo) {
                setShowPostMeetingVideo(true)
            }
        }
    }, [config, closureVideo])

    if (showPostMeetingVideo && closureVideo) {
        return (
            <VideoPlayer
                video={closureVideo}
                onComplete={() => {
                    // Mark video as completed and navigate
                    // updateVideoStatus(icebreaker.id, true)
                    setShowPostMeetingVideo(false)
                }}
            />
        )
    }

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '15px',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <h1>{t('endedByHost.title')}</h1>
            <Feedback />
            <CloseMeeting />
        </div>
    )
}
