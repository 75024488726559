import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'

export const Moving = () => {
    const { t } = useTranslation('postmeeting')
    return (
        <div className="row-2">
            <div className="icon rounded-icon">
                <FontAwesomeIcon icon={['far', 'arrow-right']} style={{ color: '#00A3FF' }} />
            </div>
            <h1>{t('moving.title')}</h1>
        </div>
    )
}
