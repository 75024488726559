import { useEffect, useRef, useState } from 'react'
import { PrimaryBtnOutline } from '../../../components/Buttons'
import { SendScoreProps } from '../../meeting-view/hooks/useScore'
import { defaultTheme } from '../../../styles/themes/defaultTheme'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

export const Comment = ({ sendScore }: { sendScore: (props?: SendScoreProps | undefined) => Promise<void> }) => {
    const { t } = useTranslation('postmeeting')
    const textAreaRef = useRef<HTMLTextAreaElement | null>(null)
    const placeholderMessage = t('feedback.comment.placeholder')
    const [placeholder, setPlaceholder] = useState(placeholderMessage)
    const [comment, setComment] = useState<string | null>(null)

    const resizeTextArea = () => {
        const current = textAreaRef.current
        if (!current) return
        current.style.height = 'fit-content'
        current.style.height = `${current.scrollHeight}px`
    }

    useEffect(() => {
        resizeTextArea()
        window.addEventListener('resize', resizeTextArea)
    }, [])

    return (
        <Section>
            <p className="bold">{t('feedback.comment.leaveCommentPrompt')}</p>
            <textarea
                placeholder={placeholder}
                value={comment ?? ''}
                ref={textAreaRef}
                onFocus={() => setPlaceholder('')}
                onBlur={() => setPlaceholder(placeholderMessage)}
                onChange={({ target: { value } }) => {
                    setComment(value)
                    resizeTextArea()
                }}
            />
            <PrimaryBtnOutline onClick={() => sendScore({ comment })} colorTheme="light">
                {t('feedback.comment.sendRating')}
            </PrimaryBtnOutline>
        </Section>
    )
}

const Section = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-top: 2em;
    & > p {
        padding: 0;
        margin: 0;
        font-size: 16px;
        font-weight: 600;
        line-height: normal;
    }
    & > textarea {
        resize: none;
        overflow: hidden;
        width: 580px;
        height: 30px;
        box-sizing: border-box;
        border-radius: 10px;
        background: #2f2f31;
        padding: 14px 24px;
        border: none;
        color: ${defaultTheme.color.white};
        margin: 20px 0;
    }
    & > textarea,
    & > textarea::placeholder,
    & > textarea:focus-visible {
        font-family: Poppins;
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
    & > textarea:focus-visible {
        border: 1px solid #444;
        outline: none;
    }
    & > button {
        border-radius: 10px;
        margin-bottom: 0;
        &:hover:not(:disabled) {
            background-color: ${defaultTheme.color.white};
            color: ${defaultTheme.color.slateGray};
        }
    }
`
