import { Api } from './apis'
import Axios from 'axios'

const { REACT_APP_EGG_API_PULSES } = process.env

export const ApiPulses = Axios.create({
    baseURL: REACT_APP_EGG_API_PULSES,
})

export const getMyPulseData = () =>
    ApiPulses.get<Api<MyPulseDataDTO>>('/v1/profiles/availability').then(({ data }) => data.data)

export const getPulses = async (profileIds: string[]): Promise<PulseDTO[]> =>
    ApiPulses.get<Api<PulseDTO[]>>(`/v1/profiles?profiles=${profileIds.join(',')}`).then(({ data }) => data.data)

export const givePulse = (profileToId?: string) =>
    ApiPulses.post<Api<GivePulseResponse>>('/v1/pulses', {
        profileToId,
    }).then(({ data }) => data.data)

export interface PulseDTO {
    id: string
    received: number
    given: number
}

export interface MyPulseDataDTO extends PulseDTO {
    algorithmId: string
    remainingPulses: number
    pidGivenTo: string[]
    canGivePulse: boolean
}

interface GivePulseResponse extends PulseDTO {
    availability: MyPulseDataDTO
}
