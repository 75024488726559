import { PulseData } from '../Pulse/PulseData'
import { RaisedHand } from '../RaisedHand'
import { UserMic } from './UserMic'
import { useZoomContext } from '../../context/ZoomContext'

export const MyUserVideo = () => {
    const { currentUserInfo: me } = useZoomContext()

    if (!me) return null

    return (
        <div className="user">
            <div className="top-left">
                <UserMic {...me} />
                <span>{me.displayName}</span>
            </div>
            <div className="top-right">
                <RaisedHand {...me} />
                <PulseData {...me} />
            </div>
        </div>
    )
}
