import { CountDown } from '../../../components/CountDown'
import DayJS from '../../../helpers/DayJS'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { extractRoomFromTopic } from '../../../helpers/formatters'
import { getSessionParameters } from '../../../helpers/urls'
import { useTimeline } from '../../../hooks/useTimeline'
import { useZoomContext } from '../../../context/ZoomContext'
import { useTranslation } from 'react-i18next'
import { getSessionItem } from '../../../helpers/localStorage'

export const RoomName = () => {
    const { t } = useTranslation('meeting')
    const { nextEvent } = useTimeline()
    const { isRecording } = useZoomContext()
    const { room: currentRoom, topic } = getSessionParameters()
    const teamNumber = extractRoomFromTopic(topic)
    const roomName = currentRoom === 'teams' ? `${t('room_teams')} ${teamNumber}` : t('room_general')
    const manuallySwitched = getSessionItem('manually-switched')

    // Only show recording indicator in general room
    const showRecordingIndicator = isRecording && currentRoom === 'general'

    return (
        <div
            style={{
                color: 'white',
                fontWeight: '600',
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '0 10px',
                width: 'max-content',
            }}
        >
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '10px',
                }}
            >
                {roomName}
                {showRecordingIndicator && (
                    <FontAwesomeIcon icon={['fas', 'circle']} style={{ color: '#FF647C', fontSize: '10px' }} />
                )}
            </div>
            {nextEvent && !manuallySwitched && (
                <CountDown duration={DayJS(nextEvent.date).diff(DayJS(), 'milliseconds')} />
            )}
        </div>
    )
}
