import { useMemo } from 'react'
import styled from 'styled-components'
import chrome from '../../assets/images/chrome.png'
import edge from '../../assets/images/edge.png'
import { useTranslation } from 'react-i18next'

const NoPermissionsModal = () => {
    const { t } = useTranslation('premeeting')
    const browserInfo = useMemo(() => {
        const userAgent = navigator.userAgent.toLowerCase()
        const isChrome = /chrome/.test(userAgent) && !/edg/.test(userAgent)
        const isEdge = /edg/.test(userAgent)
        return {
            image: isEdge ? edge : chrome,
            helpUrl: isEdge
                ? 'https://support.microsoft.com/en-us/microsoft-edge/camera-and-microphone-permissions-in-microsoft-edge-9c7fb3c4-f19f-4f73-9656-0f182b86dee'
                : 'https://support.google.com/chrome/answer/2693767?hl=es',
            browserName: isEdge ? 'Microsoft Edge' : 'Google Chrome',
        }
    }, [])

    return (
        <NoPermissionModalStyled>
            <div className="img">
                <img
                    src={browserInfo.image}
                    alt={t('permissionsModal.browserSettingsAlt', { browser: browserInfo.browserName })}
                />
            </div>
            <div className="content">
                <h2>{t('permissionsModal.allowAccessTitle')}</h2>
                <ol>
                    <li>{t('permissionsModal.clickIconInstructions')}</li>
                    <li>{t('permissionsModal.enableCamera')}</li>
                    <li>{t('permissionsModal.enableMicrophone')}</li>
                </ol>
                <a href={browserInfo.helpUrl} target="_blank" rel="noopener noreferrer">
                    {t('permissionsModal.moreInfoLink')}
                </a>
            </div>
        </NoPermissionModalStyled>
    )
}

const NoPermissionModalStyled = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 30px 30px 20px 30px;
    box-sizing: border-box;
    color: white;
    text-align: left;
    position: relative;

    @media (max-height: 500px) {
        flex-direction: row;
        align-items: flex-start;
        .img {
            flex: 0 0 45%;
        }
        .content {
            flex: 1;
            padding-left: 20px;
        }
    }

    img {
        width: 100%;
    }

    .content {
        font-size: 0.95rem;
        display: flex;
        flex-direction: column;
        gap: 15px;

        h2 {
            font-size: 1.25rem;
            font-weight: normal;
            margin: 0;
        }

        ol {
            padding: 0;
            margin: 0;
            margin-left: 10px;
            li {
                margin: 5px 0;
            }
        }
    }
`

export default NoPermissionsModal
