import DayJS from '../helpers/DayJS'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { defaultTheme } from '../styles/themes/defaultTheme'
import styled from 'styled-components'
import { useEffect } from 'react'
import { useTimer } from '../pages/meeting-view/hooks/useTimer'

export const Timer = (props: Parameters<typeof useTimer>[0]) => {
    const { start, time } = useTimer(props)

    useEffect(() => {
        start()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const remainingMinutes = DayJS.duration(time, 'seconds').format('m:ss')
    return (
        <TimerStyle>
            <FontAwesomeIcon icon={['far', 'clock']} className="icon" />
            <p>{remainingMinutes} m</p>
        </TimerStyle>
    )
}

const TimerStyle = styled.div`
    width: 140px;
    min-width: 110px;
    padding: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background-color: ${defaultTheme.color.slateGray};
    border-radius: 14px;
    & > .icon {
        width: 22px;
        height: 100%;
    }
    & > p {
        padding: 0;
        margin: 0;
        font-size: 26px;
        font-weight: 600;
    }
`
