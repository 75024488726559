import { MediaStream, Participant } from '../types/video-types'
import { MutableRefObject, useEffect } from 'react'

import { CellLayout } from '../types/video-types'
import { isShallowEqual } from '../util/util'
import { usePersistFn } from './usePersistFn'
import { usePrevious } from './usePrevious'

export function useRenderVideo(
    mediaStream: MediaStream | null,
    isVideoDecodeReady: boolean,
    videoRef: MutableRefObject<HTMLCanvasElement | null>,
    layout: CellLayout[],
    subscribedVideos: number[],
    participants: Participant[],
    currentUserId?: number
) {
    const previousSubscribedVideos = usePrevious(subscribedVideos)
    const previousLayout = usePrevious(layout)
    const previousParticipants = usePrevious(participants)
    const previousIsVideoDecodeReady = usePrevious(isVideoDecodeReady)
    /**
     * gallery view without SharedArrayBuffer mode, self video is present by Video Element
     */
    const isSkipSelfVideo = false
    useEffect(() => {
        if (videoRef.current && layout && layout.length > 0 && isVideoDecodeReady) {
            const addedSubscribers = subscribedVideos.filter(id => !(previousSubscribedVideos || []).includes(id))
            const removedSubscribers = (previousSubscribedVideos || []).filter(
                (id: number) => !subscribedVideos.includes(id)
            )
            const unalteredSubscribers = subscribedVideos.filter(id => (previousSubscribedVideos || []).includes(id))
            if (removedSubscribers.length > 0) {
                removedSubscribers.forEach(async (userId: number) => {
                    if (!isSkipSelfVideo || (isSkipSelfVideo && userId !== currentUserId)) {
                        await mediaStream?.stopRenderVideo(videoRef.current as HTMLCanvasElement, userId).catch(() => {
                            console.log('Error stopping render video from removed subscribers')
                        })
                    }
                })
            }
            if (addedSubscribers.length > 0) {
                // console.log('Entra por added subscribers')
                addedSubscribers.forEach(async userId => {
                    const index = participants.findIndex(user => user.userId === userId)
                    const cellDimension = layout[index]
                    if (cellDimension && (!isSkipSelfVideo || (isSkipSelfVideo && userId !== currentUserId))) {
                        const { width = 300, height, x, y, quality } = cellDimension
                        await mediaStream
                            ?.renderVideo(videoRef.current as HTMLCanvasElement, userId, width, height, x, y, quality)
                            .then(() => {
                                // console.log('render video from useRenderVideo.ts addedsubscribers')
                            })
                    }
                })
            }
            if (unalteredSubscribers.length > 0) {
                // console.log('Entra por unaltered subscribers')
                // layout changed
                if (
                    previousLayout &&
                    (layout.length !== previousLayout.length || !isShallowEqual(layout[0], previousLayout[0]))
                ) {
                    unalteredSubscribers.forEach(userId => {
                        // console.log('rendering again unaltered Subs')
                        const index = participants.findIndex(user => user.userId === userId)
                        const cellDimension = layout[index]
                        if (cellDimension && (!isSkipSelfVideo || (isSkipSelfVideo && userId !== currentUserId))) {
                            const { width, height, x, y, quality } = cellDimension
                            if (previousLayout?.[index] && previousLayout[index].quality !== quality) {
                                mediaStream
                                    ?.renderVideo(
                                        videoRef.current as HTMLCanvasElement,
                                        userId,
                                        width,
                                        height,
                                        x,
                                        y,
                                        quality
                                    )
                                    .then(() => {
                                        // console.log('render video from useRenderVideo.ts')
                                    })
                            }
                            mediaStream
                                ?.adjustRenderedVideoPosition(
                                    videoRef.current as HTMLCanvasElement,
                                    userId,
                                    width,
                                    height,
                                    x,
                                    y
                                )
                                .then(() => {
                                    // console.log('adjustingVideoPosition')
                                })
                                .catch(() => {})
                        }
                    })
                }
                // the order of participants changed
                const participantsIds = participants.map(user => user.userId)
                const previousParticipantsIds = previousParticipants?.map(user => user.userId)
                if (participantsIds.join('-') !== previousParticipantsIds?.join('-')) {
                    // console.log('Entra por unaltered subscribers')
                    unalteredSubscribers.forEach(userId => {
                        const index = participantsIds.findIndex(id => id === userId)
                        const previousIndex = previousParticipantsIds?.findIndex(id => id === userId)
                        if (index !== previousIndex) {
                            const cellDimension = layout[index]
                            if (cellDimension && (!isSkipSelfVideo || (isSkipSelfVideo && userId !== currentUserId))) {
                                const { width, height, x, y } = cellDimension
                                mediaStream?.adjustRenderedVideoPosition(
                                    videoRef.current as HTMLCanvasElement,
                                    userId,
                                    width,
                                    height,
                                    x,
                                    y
                                )
                            }
                        }
                    })
                }
            }
        }
    }, [
        mediaStream,
        isVideoDecodeReady,
        videoRef,
        layout,
        previousLayout,
        participants,
        previousParticipants,
        subscribedVideos,
        previousSubscribedVideos,
        isSkipSelfVideo,
        currentUserId,
    ])

    useEffect(() => {
        if (previousIsVideoDecodeReady === false && isVideoDecodeReady === true && subscribedVideos.length > 0) {
            subscribedVideos.forEach(async userId => {
                const index = participants.findIndex(user => user.userId === userId)
                const cellDimension = layout[index]
                if (cellDimension && (!isSkipSelfVideo || (isSkipSelfVideo && userId !== currentUserId))) {
                    const { width, height, x, y, quality } = cellDimension
                    await mediaStream?.renderVideo(
                        videoRef.current as HTMLCanvasElement,
                        userId,
                        width,
                        height,
                        x,
                        y,
                        quality
                    )
                }
            })
        }
    }, [
        mediaStream,
        videoRef,
        layout,
        participants,
        subscribedVideos,
        isVideoDecodeReady,
        previousIsVideoDecodeReady,
        isSkipSelfVideo,
        currentUserId,
    ])
    const stopAllVideos = usePersistFn((videoCanvasDOM: HTMLCanvasElement) => {
        if (subscribedVideos.length > 0) {
            subscribedVideos.forEach(userId => mediaStream?.stopRenderVideo(videoCanvasDOM, userId).catch(() => {}))
        }
    })
    useEffect(() => {
        const videoCanvasDOM = videoRef.current
        return () => {
            stopAllVideos(videoCanvasDOM)
        }
    }, [videoRef, stopAllVideos])
}
