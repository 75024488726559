import { IconName, IconPrefix } from '@fortawesome/pro-light-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Timer } from '../../../../components/Timer'
import { defaultTheme } from '../../../../styles/themes/defaultTheme'
import styled from 'styled-components'

const ErrorComponent = ({ errorType, payload }: ErrorType) => {
    const errors: MeetingError[] = [
        {
            errorType: 'permissions-denied',
            title: 'No tienes habilitado los permisos de tu cámara y/o micrófono.',
            msg: (
                <>
                    Para una experiencia en cooperación, es importante que actives tu cámara, así podrás volver al
                    encuentro.
                    <br />
                    {payload && payload.time > 0 && <Timer initTime={payload.time} />}
                </>
            ),
            icon: ['far', 'video-slash'],
        },
        {
            errorType: 'default',
            title: 'Hubo un error al unirse a la sesión.',
            msg: 'Intente nuevamente más tarde.',
            icon: ['far', 'circle-exclamation'],
        },
    ]

    const { title, msg, icon } = errors.find(e => e.errorType === errorType) || errors[4]
    return (
        <ErrorComponentStyled>
            {icon && (
                <div className="icon rounded-icon">
                    <FontAwesomeIcon icon={icon} />
                </div>
            )}
            <div className="content">
                <h1 className="title">{title}</h1>
                <h6 className="message">{msg}</h6>
            </div>
        </ErrorComponentStyled>
    )
}

export default ErrorComponent

export interface ErrorType {
    errorType: string
    payload: { time: number } | null
}

interface MeetingError {
    title: string | React.ReactNode
    msg: string | React.ReactNode
    icon: [IconPrefix, IconName]
    errorType: MeetingErrorType['errorType']
}

export type MeetingErrorType =
    | { errorType: 'no-cameras'; payload: null }
    | { errorType: 'permissions-denied'; payload: { time: number } }
    | { errorType: 'default'; payload: null }

const ErrorComponentStyled = styled.div`
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 30px;
    padding: 30px;
    box-sizing: border-box;
    background-color: ${defaultTheme.color.midnightBlack};
    color: ${defaultTheme.color.white};
    & > .icon {
        color: ${defaultTheme.color.salmon};
    }
    & > .content {
        & > .title {
            font-size: 26px;
            font-weight: 600;
            line-height: 40px;
            margin-bottom: 5px;
        }
        & > .message {
            font-size: 16px;
            font-weight: 400;
            margin: 0;
            & > button {
                width: auto;
                margin: 40px auto;
                color: ${defaultTheme.color.slateGray};
                background-color: ${defaultTheme.color.white};
                font-weight: 700;
                font-size: 16px;
                & > svg {
                    width: 17.5px;
                    height: 17.5px;
                }
            }
        }
    }
`
