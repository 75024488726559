import ZoomVideo, { LocalAudioTrack } from '@zoom/videosdk'
import { getItem, setItem } from '../../../helpers/localStorage'
import { useCallback, useEffect, useRef, useState } from 'react'
import { usePreMeetingContext } from '../../../context/PreMeetingContext'
import { useTranslation } from 'react-i18next'
import { useUI } from '../../../context/UIContext'
import { usePermissions } from '../../../context/PermissionsContext'
import toast from 'react-hot-toast'

export const useMyAudio = () => {
    const { t } = useTranslation('notifications')
    const { showToast, showError } = useUI()
    const { activeMic, setActiveMic, setActiveSpeaker, isAudioMuted, setIsAudioMuted } = usePreMeetingContext()
    const [myAudio, setMyAudio] = useState<LocalAudioTrack | null>(null)
    const [audioStream, setAudioStream] = useState<MediaStream | null>(null)
    const audioStreamRef = useRef<MediaStream | null>(null)
    const [microphones, setMicrophones] = useState<MediaDeviceInfo[]>([])
    const [speakers, setSpeakers] = useState<MediaDeviceInfo[]>([])
    const { camera, microphone } = usePermissions()
    const permissionGranted = camera && microphone
    const initialized = useRef(false)

    const stopCurrentAudioStream = useCallback(() => {
        if (audioStreamRef.current) {
            audioStreamRef.current.getTracks().forEach(track => track.stop())
            audioStreamRef.current = null
            setAudioStream(null)
        }
    }, [])

    const getMics = useCallback(async () => {
        try {
            const devices = await ZoomVideo.getDevices()
            const [mics, spkrs] = devices.reduce(
                (acc, device) => {
                    if (device.kind === 'audioinput') acc[0].push(device)
                    if (device.kind === 'audiooutput') acc[1].push(device)
                    return acc
                },
                [[], []] as [MediaDeviceInfo[], MediaDeviceInfo[]]
            )

            setMicrophones(mics)
            setSpeakers(spkrs)
            if (mics.length && !getItem('last-mic-id')) setActiveMic(mics[0])
            if (spkrs.length && !getItem('last-speaker-id')) setActiveSpeaker(spkrs[0])
        } catch (error) {
            console.error('Failed to enumerate devices:', error)
            showError(t('myAudio.unableToGetDevices'))
        }
    }, [setActiveMic, setActiveSpeaker, showError, t])

    useEffect(() => {
        if (!permissionGranted || initialized.current) return

        const init = async () => {
            try {
                const stream = await navigator.mediaDevices.getUserMedia({
                    audio: activeMic?.deviceId ? { deviceId: activeMic.deviceId } : true,
                })

                const audioTrack = ZoomVideo.createLocalAudioTrack()
                await audioTrack.start()
                await audioTrack.unmute()

                audioStreamRef.current = stream
                setAudioStream(stream)
                setMyAudio(audioTrack)
            } catch (error) {
                console.error('Failed to initialize audio:', error)
                showError(t('myAudio.unableToInitializeAudio'))
            }
        }

        initialized.current = true
        getMics()
        init()

        return () => {
            stopCurrentAudioStream()
            if (myAudio) {
                myAudio.stop().catch(console.error)
            }
        }
    }, []) // Empty deps to run only once

    const switchMicrophone = async (microphoneId: string) => {
        if (!myAudio) {
            showError(t('myAudio.unableToChangeMicrophone'))
            return
        }
        try {
            stopCurrentAudioStream()

            const selectedDevice = microphones.find(({ deviceId }) => deviceId === microphoneId)
            if (!selectedDevice) return showError(t('myAudio.selectedMicrophoneNotAvailable'))

            await myAudio.stop()
            const newAudioTrack = ZoomVideo.createLocalAudioTrack(microphoneId)
            await newAudioTrack.start()
            await newAudioTrack.unmute()
            setMyAudio(newAudioTrack)

            const stream = await navigator.mediaDevices.getUserMedia({
                audio: { deviceId: microphoneId },
            })
            audioStreamRef.current = stream
            setAudioStream(stream)

            showToast(t('myAudio.microphoneSelected', { label: selectedDevice.label }), {
                id: `switch-${selectedDevice.deviceId}`,
            })
            setActiveMic(selectedDevice)
            setItem('last-mic-id', selectedDevice)
        } catch (error) {
            stopCurrentAudioStream()
            const newAudio = ZoomVideo.createLocalAudioTrack('default')
            newAudio.start().then(() => newAudio.unmute())
            setMyAudio(newAudio)
            showError(t('myAudio.unableToChangeMicrophone'))
        }
    }

    const toggleMute = async () => {
        setIsAudioMuted(prev => !prev)
        try {
            if (isAudioMuted) {
                await myAudio?.unmute()
                showToast(t('myAudio.joiningWithMicrophoneEnabled'), { id: 'audio-enabled' })
                toast.dismiss('audio-disabled')
                setItem('pre-mute-state', false)
            } else {
                await myAudio?.mute()
                showToast(t('myAudio.joiningWithMicrophoneDisabled'), { id: 'audio-disabled' })
                toast.dismiss('audio-enabled')
                setItem('pre-mute-state', true)
            }
        } catch (error) {
            console.error(t('myAudio.errorTogglingMute'), error)
        }
    }

    return {
        myAudio,
        microphones,
        speakers,
        switchMicrophone,
        toggleMute,
        audioStream,
    }
}
