import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Participant } from '../pages/meeting-view/types/video-types'
import Styles from './RaisedHand.module.css'
import useRaisedHands from '../hooks/useRaisedHands'

export const RaisedHand = ({ userId }: Pick<Participant, 'userId'>) => {
    const { isUserHandRaised } = useRaisedHands()

    return isUserHandRaised(userId) ? <FontAwesomeIcon icon={['far', 'hand']} className={Styles.hand} /> : null
}
