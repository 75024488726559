import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import { Tip } from './Tip'
import { MediaBtnContainer } from '../pages/pre-meeting/components/Buttons'
import { useIntercom } from '../hooks/useIntercom'

export function IntercomSelector() {
    const { t } = useTranslation('intercom')
    const { openChat } = useIntercom()

    return (
        <div>
            <MediaBtnContainer as="div" style={{ position: 'fixed', bottom: '20px', left: '20px', opacity: '.5' }}>
                <button type="button" onClick={openChat} data-tooltip-id="intercom-action">
                    <FontAwesomeIcon icon={['fab', 'intercom']} fixedWidth />
                </button>
                <Tip id="intercom-action" content={t('supportClick')} />
            </MediaBtnContainer>
        </div>
    )
}
