import styled, { css } from 'styled-components'

import { MyUserVideo } from './MyUserVideo'
import { Participant } from '../../pages/meeting-view/types/video-types'
import { UserFailOver } from './UserFailOver'
import { UserGeneral } from './UserGeneral'
import { UserSkeleton } from './UserSkeleton'
import { UserTeams } from './UserTeams'
import { useActiveSpeaker } from '../../pages/meeting-view/hooks/useActiveVideo'
import { useSessionParams } from '../../hooks/useSessionParams'
import { useZoomContext } from '../../context/ZoomContext'

const UserVideo = ({ style, ...user }: Participant & { style?: React.CSSProperties }) => {
    const { zoomClient, currentUserInfo } = useZoomContext()
    const { room } = useSessionParams()
    const activeSpeakerId = useActiveSpeaker(zoomClient)
    const { bVideoOn, isInFailover } = user

    return (
        <UserWrapper style={style} isActive={activeSpeakerId === user.userId}>
            {!bVideoOn ? (
                <UserSkeleton {...user} />
            ) : isInFailover ? (
                <UserFailOver {...user} />
            ) : currentUserInfo?.userId === user.userId ? (
                <MyUserVideo />
            ) : room === 'teams' ? (
                <UserTeams {...user} />
            ) : room === 'general' ? (
                <UserGeneral {...user} />
            ) : null}
        </UserWrapper>
    )
}

export default UserVideo

const UserWrapper = styled.div<{ isActive: boolean }>`
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: visible;
    border-radius: 0px;
    top: 0;
    ${props =>
        props.isActive &&
        css`
            outline: ${props => `4px solid ${props.theme.color.electricBlue}`};
        `};
`
