import { useTranslation } from 'react-i18next'
import { SalmonBtn } from '../../../components/Buttons'
import { useReJoin } from '../../meeting-view/hooks/useReJoin'

export const GoBackToMeeting = () => {
    const reJoin = useReJoin()
    const { t } = useTranslation('postmeeting')

    return <SalmonBtn onClick={reJoin}>{t('backToMeeting')}</SalmonBtn>
}
