import Axios from 'axios'
import { BaseVideo, RootConfiguration } from '../pages/onboarding/types/types'

export const ApiContent = Axios.create({
    baseURL: process.env.REACT_APP_CONTENT_API,
})

export const getOnboardingData = async () =>
    ApiContent.get<any>('/v1/profiles/onboarding').then(result => {
        // console.info('ApiContent: getOnboardingData', result.data.data)
        return result.data.data
    })

export const completeVideo = async (videoId: BaseVideo['id']) =>
    ApiContent.patch<RootConfiguration>('/v1/profiles/onboarding/video/watched', {
        videoId,
    }).then(({ data }) => {
        console.log('ApiContent: Patch getOnboardingData', data)
        return data
    })
