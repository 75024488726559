import { useCallback, useEffect, useReducer, useState } from 'react'
import produce from 'immer'
import { ZoomClient, MediaStream } from '../pages/meeting-view/types/video-types'

export default function usePermissionChange(zoomClient: ZoomClient, mediaStream: MediaStream | null) {
    const [permissionState] = useReducer(mediaReducer, permissionShape)
    const [permissionGranted, setPermissionGranted] = useState(true)
    const [checking] = useState(true)

    const onDevicePermissionChange = useCallback(
        async (payload: { name: PermissionShapeKey; state: 'denied' | 'granted' | 'prompt' }) => {
            const { state } = payload
            if (mediaStream) {
                if (state === 'denied') {
                    console.info('Permission denied')
                    await mediaStream.muteAllUserAudioLocally().catch(() => {})
                    setPermissionGranted(false)
                    try {
                        const currentUserInfo = zoomClient.getCurrentUserInfo()
                        currentUserInfo.bVideoOn && (await mediaStream.stopVideo())
                        currentUserInfo.audio !== '' && (await mediaStream.stopAudio())
                    } catch (error) {
                        console.info('Error stopping media when disabling permissions', error)
                    }
                }
            }
        },
        [mediaStream, zoomClient]
    )

    useEffect(() => {
        zoomClient.on('device-permission-change', onDevicePermissionChange)
        return () => {
            zoomClient.off('device-permission-change', onDevicePermissionChange)
        }
    }, [onDevicePermissionChange, zoomClient])

    return {
        permissionState,
        permissionGranted,
        checking,
    }
}

type PermissionShapeKey = keyof typeof permissionShape

const permissionShape = {
    microphone: {
        state: false,
    },
    camera: {
        state: false,
    },
}

const mediaReducer = produce((draft, action) => {
    switch (action.type) {
        case 'microphone': {
            draft.microphone.state = action.payload
            break
        }
        case 'camera': {
            draft.camera.state = action.payload
            break
        }
        case 'reset-permission': {
            Object.assign(draft, { ...permissionShape })
            break
        }
        default:
            break
    }
}, permissionShape)
