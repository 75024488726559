import { useEffect, useRef } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { PrimaryBtnBlue } from './Buttons'
import Styles from './Pip.module.css'
import { Tip } from './Tip'
import { VideoMessage } from '../apis/api-egg'
import { useUI } from '../context/UIContext'

export const Pip = () => {
    const { pip, closePip } = useUI()
    const ref = useRef<HTMLVideoElement | null>(null)

    useEffect(() => {
        const video = ref.current
        if (!video) return

        video.addEventListener('ended', closePip)

        return () => video.removeEventListener('ended', closePip)
    }, [closePip])

    if (!pip?.src) return null

    const { mute, onMute, onUnmute, ...rest } = pip

    const goPip = () => {
        if (document.pictureInPictureElement) document.exitPictureInPicture()
        else if (document.pictureInPictureEnabled) ref.current?.requestPictureInPicture()
    }

    return (
        <section className={Styles.pip}>
            <video ref={ref} autoPlay controls width={800} {...rest} />

            <span className={`${Styles.audioNotice} ${mute ? '' : Styles.enabled}`}>
                Sonido de participantes {mute ? ' deshabilitado' : ' habilitado'}
            </span>

            <div className={Styles.controls}>
                <PrimaryBtnBlue data-tooltip-id="pip-close" className="primary" onClick={closePip}>
                    <FontAwesomeIcon icon={['fas', 'times']} />
                </PrimaryBtnBlue>
                <Tip id="pip-close" content="Cerrar video" place={'left'} />

                <PrimaryBtnBlue data-tooltip-id="pip-pip" className="primary" onClick={goPip}>
                    <FontAwesomeIcon icon={['fas', 'arrow-up-right-from-square']} />
                </PrimaryBtnBlue>
                <Tip id="pip-pip" content="Modo Picture-in-Picture" place={'left'} />

                {onMute && onUnmute && (
                    <>
                        <PrimaryBtnBlue
                            data-tooltip-id="pip-enable-audio"
                            className="primary"
                            onClick={mute ? onUnmute : onMute}
                        >
                            <FontAwesomeIcon
                                icon={['far', mute ? 'volume-slash' : 'volume']}
                                color={mute ? 'rgba(255, 100, 124, 0.8)' : 'white'}
                            />
                        </PrimaryBtnBlue>
                        <Tip
                            id="pip-enable-audio"
                            content={`${mute ? 'Habilitar' : 'Deshabilitar'} el sonido de los participantes`}
                            place={'left'}
                        />
                    </>
                )}
            </div>
        </section>
    )
}

export type PipType = Omit<VideoMessage, 'type'> & {
    onClose?: () => unknown
    onMute?: () => void
    onUnmute?: () => void
}
