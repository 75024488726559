import React, { useState, useRef, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'
import { Participant } from '@zoom/videosdk'
import ParticipantItem from './ParticipantItem'
import { useTranslation } from 'react-i18next'

type Props = {
    title?: string
    participants?: Participant[]
    initiallyExpanded?: boolean
    noResultsMessage?: string | null
    isExpandable?: boolean
    isSearchBlock?: boolean
    searchQuery?: string
}

const ExpandableBlock: React.FC<Props> = ({
    title = 'Levantaron la mano',
    participants,
    initiallyExpanded = false,
    noResultsMessage = null,
    isExpandable = true,
    isSearchBlock = false,
    searchQuery = '',
}) => {
    const [isExpanded, setIsExpanded] = useState(isExpandable ? initiallyExpanded : true)
    const contentRef = useRef<HTMLDivElement>(null)
    const { t } = useTranslation('sidebar')

    useEffect(() => {
        if (isExpandable) {
            setIsExpanded(initiallyExpanded)
        } else {
            setIsExpanded(true)
        }
    }, [initiallyExpanded, isExpandable])

    const toggleExpand = () => {
        if (isExpandable) {
            setIsExpanded(!isExpanded)
        }
    }

    const renderContent = () => {
        if (isSearchBlock) {
            if (searchQuery === '') {
                return <div className="no-results">{t('participants.searchStart')}</div>
            } else if (participants && participants.length === 0) {
                return <div className="no-results">{noResultsMessage}</div>
            }
        }

        return participants?.map(participant => (
            <div key={participant.userId} className="participant-container">
                <ParticipantItem participant={participant} />
            </div>
        ))
    }

    return (
        <ExpandableBlockStyled $isExpanded={isExpanded} $isExpandable={isExpandable} $isSearchBlock={isSearchBlock}>
            <div className="heading" onClick={toggleExpand}>
                {title}
                {isExpandable && (
                    <span className="icon">
                        <FontAwesomeIcon icon={['fal', 'angle-down']} />
                    </span>
                )}
            </div>
            <div
                className="content"
                ref={contentRef}
                style={{ maxHeight: isExpanded ? `${contentRef.current?.scrollHeight}px` : '0px' }}
            >
                {renderContent()}
            </div>
        </ExpandableBlockStyled>
    )
}

export default ExpandableBlock

const ExpandableBlockStyled = styled.div<{ $isExpanded: boolean; $isExpandable: boolean; $isSearchBlock: boolean }>`
    border: ${props => `1px solid ${props.theme.color.sidebar.gray1}`};
    border-radius: 10px;
    color: white;
    overflow: hidden;

    .heading {
        padding: 10px 15px;
        display: flex;
        justify-content: space-between;
        font-weight: bold;
        font-size: 12px;
        cursor: ${props => (props.$isExpandable ? 'pointer' : 'default')};
        border-bottom: ${props =>
            props.$isExpanded ? `1px solid ${props.theme.color.sidebar.gray1}` : '1px solid transparent'};
        transition: border-bottom 0.3s ease-in-out;
        color: ${props => `${props.theme.color.gray}`};
    }

    .icon {
        transition: transform 0.3s ease-in-out;
        transform: ${props => (props.$isExpanded ? 'rotate(180deg)' : 'rotate(0deg)')};
    }

    .content {
        transition: max-height 0.3s ease-in-out;
        overflow: hidden;

        .participant-container {
            padding: 0px 15px;
        }

        .no-results {
            padding: 10px 15px;
            color: ${props => `${props.theme.color.gray1}`};
            text-align: center;
            font-size: 12px;
        }
    }

    ${props =>
        props.$isSearchBlock &&
        `
    .content {
      max-height: none !important;
    }
  `}
`
