import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

import { EndedByHost } from '../pages/post-meeting/pages/EndedByHost'
import { ExpeledByHost } from '../pages/post-meeting/pages/ExpeledByHost'
import { External } from '../pages/post-meeting/pages/External'
import MeetingView from '../pages/meeting-view/MeetingView'
import { Moving } from '../pages/post-meeting/pages/Moving'
import { NeedABreak } from '../pages/post-meeting/pages/NeedABreak'
import PostMeeting from '../pages/post-meeting/PostMeeting'
import { PreMeeting } from '../pages/pre-meeting/PreMeeting'
import { PreMeetingProvider } from '../context/PreMeetingContext'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { SocketProvider } from '../context/SocketContext'
import { UIProvider } from '../context/UIContext'
import { ZoomProvider } from '../context/ZoomContext'
import * as Sentry from '@sentry/react'
import ErrorComponent from '../pages/meeting-view/components/Video/ErrorComponent'
import { PermissionProvider } from '../context/PermissionsContext'
import AppHub from '../pages/hub/AppHub'
import { OnboardingProvider } from '../context/OnboardingContext'
import OnboardingComponent from '../pages/onboarding/OnboardingComponent'

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false, // default: true
        },
    },
})

const ProtectedRoutes = () => {
    return (
        <UIProvider>
            <ZoomProvider>
                <SocketProvider>
                    <PermissionProvider>
                        <PreMeetingProvider>
                            <Routes>
                                <Route path="/pre-meeting" element={<PreMeeting />} />
                                <Route
                                    path="/meeting"
                                    element={
                                        <Sentry.ErrorBoundary
                                            fallback={<ErrorComponent errorType="default" payload={null} />}
                                        >
                                            <MeetingView />
                                        </Sentry.ErrorBoundary>
                                    }
                                />
                                <Route path="/post-meeting/" element={<PostMeeting />}>
                                    <Route path="" index element={<NeedABreak />} />
                                    <Route path="ended" index element={<NeedABreak />} />
                                    <Route path="ended by host" element={<EndedByHost />} />
                                    <Route path="expeled by host" element={<ExpeledByHost />} />
                                    <Route path="kicked by host" element={<ExpeledByHost />} />
                                    <Route path="moving" element={<Moving />} />
                                    <Route path="external" element={<External />} />
                                    <Route path="*" element={<NeedABreak />} />
                                </Route>
                            </Routes>
                        </PreMeetingProvider>
                    </PermissionProvider>
                </SocketProvider>
            </ZoomProvider>
        </UIProvider>
    )
}

export const AppRoutes = () => {
    return (
        <BrowserRouter>
            <QueryClientProvider client={queryClient}>
                <OnboardingProvider>
                    <Routes>
                        <Route path="/" element={<AppHub />} />
                        <Route path="/onboarding" element={<OnboardingComponent />} />
                        <Route path="/*" element={<ProtectedRoutes />} />
                    </Routes>
                </OnboardingProvider>
                {/* <ReactQueryDevtools initialIsOpen={false} /> */}
            </QueryClientProvider>
        </BrowserRouter>
    )
}
