import { ChatClient, MediaStream, ZoomClient } from '../types/video-types'
import ZoomVideo, { ConnectionState, ReconnectReason } from '@zoom/videosdk'
import { useCallback, useEffect, useMemo, useReducer, useState } from 'react'

import produce from 'immer'
import { useNavigate } from 'react-router-dom'
import { useUI } from '../../../context/UIContext'

declare global {
    interface Window {
        webEndpoint: string | undefined
        zoomClient: ZoomClient | null
        chatClient: ChatClient | null
        mediaStream: MediaStream | null
        crossOriginIsolated: boolean
        ltClient: any | undefined
    }
}

export const useMediaContext = (zoomClient: ZoomClient, mediaStream: MediaStream | null) => {
    const [mediaState, dispatch] = useReducer(mediaReducer, mediaShape)
    const [isFailover, setIsFailover] = useState<boolean>(false)
    const [sdkLoadReady, setSdkLoadReady] = useState(false)
    const { showToast } = useUI()

    const mediaContext = useMemo(
        () => ({
            ...mediaState,
            isFailover,
            sdkLoadReady,
        }),
        [isFailover, mediaState, sdkLoadReady]
    )

    const navigate = useNavigate()

    const onConnectionChange = useCallback(({ state, reason = 'ended' }: any) => {
        setSdkLoadReady(true)
        if (state === ConnectionState.Fail) {
            window.zoomClient = null
            window.mediaStream = null
            dispatch({ type: 'reset-media' })
            ZoomVideo.destroyClient()
        } else if (state === ConnectionState.Reconnecting) {
            setIsFailover(true)
            if (reason === ReconnectReason.Failover) {
                showToast('Session Disconnected, trying to reconnect')
                window.location.reload()
            } else if (reason === ReconnectReason.BackToMainSession) {
                showToast('Returning to Main Session...')
            }
        } else if (state === ConnectionState.Closed) {
            window.zoomClient = null
            window.mediaStream = null
            dispatch({ type: 'reset-media' })
            navigate(`/post-meeting/${reason}`)
        } else if (state === ConnectionState.Connected) {
            console.log('Connected')
            if (isFailover) {
                setIsFailover(false)
            }
            window.zoomClient = zoomClient
            window.mediaStream = zoomClient.getMediaStream()
            window.chatClient = zoomClient.getChatClient()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onMediaSDKChange = useCallback(
        (payload: { type: MediaShapeKey; action: 'encode' | 'decode'; result: string }) => {
            const { type, action, result } = payload
            dispatch({ type: `${type}-${action}`, payload: result === 'success' })
        },
        []
    )

    useEffect(() => {
        zoomClient.on('connection-change', onConnectionChange)
        zoomClient.on('media-sdk-change', onMediaSDKChange)
        return () => {
            zoomClient.off('connection-change', onConnectionChange)
            zoomClient.off('media-sdk-change', onMediaSDKChange)
        }
    }, [zoomClient, onConnectionChange, onMediaSDKChange])

    return { mediaContext, mediaState }
}

const mediaShape = {
    audio: {
        encode: false,
        decode: false,
    },
    video: {
        encode: false,
        decode: false,
    },
    share: {
        encode: false,
        decode: false,
    },
}

const mediaReducer = produce((draft, action) => {
    switch (action.type) {
        case 'audio-encode': {
            draft.audio.encode = action.payload
            break
        }
        case 'audio-decode': {
            draft.audio.decode = action.payload
            break
        }
        case 'video-encode': {
            draft.video.encode = action.payload
            break
        }
        case 'video-decode': {
            draft.video.decode = action.payload
            break
        }
        case 'share-encode': {
            draft.share.encode = action.payload
            break
        }
        case 'share-decode': {
            draft.share.decode = action.payload
            break
        }
        case 'reset-media': {
            Object.assign(draft, { ...mediaShape })
            break
        }
        default:
            break
    }
}, mediaShape)

type MediaShapeKey = keyof typeof mediaShape
export type MediaShape = typeof mediaShape

type ConnectionChangePayload =
    | {
          state: ConnectionState.Closed
          reason: 'kicked by host' | 'ended by host' | 'expeled by host' | 'moving' | 'external'
      }
    | { state: Omit<ConnectionState, 'Closed'>; reason: 'ended' }

export type ConnectionChangeReason = {
    [Reason in ConnectionChangePayload['reason']]: Reason
}[ConnectionChangePayload['reason']]
