const unsupportedExplorer: ReturnType<typeof getExploreName>[] = ['Opera']
export function getExploreName() {
    const { userAgent } = navigator
    if (userAgent.indexOf('Opera') > -1 || userAgent.indexOf('OPR') > -1) return 'Opera'
    if (userAgent.indexOf('compatible') > -1 && userAgent.indexOf('MSIE') > -1) return 'IE'
    if (userAgent.indexOf('Edge') > -1) return 'Edge'
    if (userAgent.indexOf('Firefox') > -1) return 'Firefox'
    if (userAgent.indexOf('Safari') > -1 && userAgent.indexOf('Chrome') === -1) return 'Safari'
    if (userAgent.indexOf('Chrome') > -1 && userAgent.indexOf('Safari') > -1) return 'Chrome'
    if (!!(window as any).ActiveXObject || 'ActiveXObject' in window) return 'IE>=11'
    return 'Unknown'
}
export const isSupportedExplorer = () => !unsupportedExplorer.includes(getExploreName())
export const isSupportWebCodecs = () => typeof (window as any).MediaStreamTrackProcessor === 'function'
const isIPad = () => /MacIntel/i.test(navigator.platform) && navigator?.maxTouchPoints > 2
export const isIOSMobile = () => {
    const { userAgent } = navigator
    const isIOS = /iPad|iPhone|iPod/i.test(userAgent)
    return isIOS || isIPad()
}
export const isAndroidBrowser = () => /android/i.test(navigator.userAgent)
export const isAndroidOrIOSBrowser = () => isAndroidBrowser() || isIOSMobile()

export const getDeviceType = () => {
    const userAgent = navigator.userAgent
    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent)
    return isMobile ? 'mobile' : 'desktop'
}
