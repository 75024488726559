import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'
import { Tip } from './Tip'

type Props = {
    onClose: () => void
}

export default function CloseFloatingBtn(props: Props) {
    return (
        <>
            <ClosBtnStyled {...props} onClick={props.onClose} id="close-btn-tip">
                <FontAwesomeIcon icon={['fal', 'times']} />
            </ClosBtnStyled>
            <Tip id="close-btn-tip" anchorSelect="#close-btn-tip" content={'Close'} />
        </>
    )
}

const ClosBtnStyled = styled.button`
    background: none;
    border: none;
    color: white;
    font-size: 1.5rem;
    cursor: pointer;
    align-self: flex-end;
    padding: 0;
    margin: 0;
    position: absolute;
    top: 10px;
    right: 15px;
`
