import Chat from './Chat/Chat'
import DayJS from '../../../../helpers/DayJS'
import { Diffusion } from './Diffusion'
import { DiffusionMessage } from '../../../../apis/api-egg'
import { Tabs } from '../../../../components/Tabs'
import styled from 'styled-components'
import { useEffect, useState } from 'react'
import { useZoomContext } from '../../../../context/ZoomContext'
import { useTranslation } from 'react-i18next'

export const Messages = () => {
    const { t } = useTranslation('sidebar')
    const { unReadMessages, setUnReadMessages } = useZoomContext()
    const [tab, setTab] = useState<'chat' | 'diffusion'>(unReadMessages ? unReadMessages.type : 'chat')

    useEffect(() => setUnReadMessages(false), [setUnReadMessages])

    return (
        <>
            <Tabs
                tabs={[
                    { label: t('messages.chat'), value: 'chat' },
                    { label: t('messages.diffusion'), value: 'diffusion' },
                ]}
                defaultSelected={unReadMessages ? unReadMessages.type : undefined}
                onChange={setTab}
            />
            {tab === 'chat' ? <Chat /> : <Diffusion />}
        </>
    )
}

export const MessageHeader = ({ timestamp }: DiffusionMessage) => {
    const { t } = useTranslation('sidebar')
    return (
        <header className="message-header">
            <b>{t('messages.coach')}</b>
            <p className="to">{t('messages.to')}</p>
            <p className="receiver">{t('messages.everyone')}</p>
            <small>
                {DayJS(timestamp).format('HH:mm')} {t('messages.timeUnit')}
            </small>
        </header>
    )
}

export const MessagesStyled = styled.section`
    display: flex;
    flex-direction: column;
    height: 100%;
    main {
        flex: 1;
        overflow-y: scroll;
        scroll-behavior: smooth;
        &::-webkit-scrollbar {
            display: none;
        }
    }
    form.input-block {
        position: relative;

        textarea {
            width: 100%;
            height: 100px;
            padding: 20px;
            box-sizing: border-box;
            margin: 0;
            border: none;
            outline: none;
            border-radius: 0 0 14px 14px;
            font-family: 'Poppins', sans-serif;
            resize: none;
            background-color: ${props => props.theme.color.sidebar.gray1};
            /* border: 1px solid ${props => props.theme.color.sidebar.gray2}; */
            color: white;
            border-top: none;
        }
        button {
            background: none;
            border: none;
            cursor: pointer;
            position: absolute;
            bottom: 15px;
            right: 10px;
            font-size: 22px;
            color: ${props => props.theme.color.lightGray};
            &:disabled {
                opacity: 0.5;
                cursor: not-allowed;
            }
            &:hover {
                color: ${props => props.theme.color.electricBlue};
            }
        }
    }
`
