import { useTranslation } from 'react-i18next'
import { LinkBtn } from '../../../components/Buttons'
import ZoomVideo from '@zoom/videosdk'

export const CloseMeeting = () => {
    const onClose = () => {
        window.close()
        ZoomVideo.destroyClient()
    }

    const { t } = useTranslation('postmeeting')
    return <LinkBtn onClick={() => onClose()}>{t('leaveMeeting')}</LinkBtn>
}
