import styled from 'styled-components'

export const Viewport = styled.div`
    position: relative;
    box-sizing: border-box;
    background-color: rgb(20, 20, 20);
    padding: 20px;
    height: 100vh;
    box-sizing: border-box;
    display: flex;
    .share-container {
        display: none;
        &.in-sharing {
            display: flex;
            flex-grow: 1;
            justify-content: center;
            align-items: center;
            overflow: hidden;
        }
        .share-container-viewport {
            display: inline-block;
            max-width: 100%;
        }
        .share-canvas {
            width: 100%;
            height: 100%;
            border-radius: 10px;
            &.hidden {
                display: none;
            }
        }
    }

    .video-container {
        position: relative;
        width: 100%;
        height: 100%;
        // background: red;

        &.in-sharing {
            width: 264px;
            flex-shrink: 0;
            border-left: 1px solid #333;
        }

        .video-canvas {
            width: 100%;
            height: 100%;
            // background-color: yellow;
        }

        .self-video {
            position: absolute;
            width: 254px;
            height: 143px;
            top: 50px;
            right: 30px;
            z-index: 2;
            display: none;

            &.single-self-video {
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
            }

            &.self-video-show {
                display: block;
            }
        }
    }

    .avatar-list {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
        overflow: hidden;
        padding: 0;
        margin: 0;
    }

    .video-operations {
        position: absolute;
        left: 0;
        bottom: 0;
    }

    .avatar-wrap {
        position: absolute;
        pointer-events: auto;
        list-style: none;
        top: 0;
        left: 0;
    }

    .avatar-wrap-dragging {
        z-index: 10;
    }

    .single-view-avatar {
        top: 0;
        left: 0;
    }

    .self-video-non-sab {
        display: none;
        position: absolute;
        z-index: 1;
    }
`

export const VideoContainer = styled.div`
    .status-text {
        background-color: rgba(0, 0, 0, 0.8);
        color: white;
        font-weight: bold;
        border: 1px solid black;
        /* border-radius: 5px; */
        width: fit-content;
        padding: 10px;
        width: 100%;
        box-sizing: border-box;
        z-index: 1000;
        position: absolute;
        top: 0;
        text-align: center;
    }
`
