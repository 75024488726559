import ReactModal from 'react-modal'
import { ReactNode, useRef } from 'react'
import CloseFloatingBtn from '../CloseFloatingBtn'
import { useClickOutside } from '../../hooks/useClickOutside'

ReactModal.setAppElement('#root')

type Props = {
    children: ReactNode
    isOpen: boolean
    onClose: () => void
}

export const ModalContainer = ({ children, isOpen, onClose }: Props) => {
    const ref = useRef<any>(null)

    useClickOutside(ref, onClose)

    return (
        <ReactModal
            ref={ref}
            className="react-modal-content"
            style={modalStyle}
            isOpen={isOpen}
            shouldCloseOnOverlayClick={false}
        >
            {children}
            <CloseFloatingBtn onClose={onClose} />
        </ReactModal>
    )
}

const modalStyle = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.9)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '20px',
    },
    position: 'relative',
}

export default ModalContainer
