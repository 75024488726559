import { IconName, IconPrefix } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ReactNode } from 'react'
import styled from 'styled-components'

type Props = {
    icon?: [IconPrefix, IconName]
    iconStyle?: Record<any, any>
    msg?: string
    children?: ReactNode
}

const ToastNotification = ({ msg, icon, iconStyle, children }: Props) => {
    return (
        <StyledNotification>
            {icon && <FontAwesomeIcon icon={[icon[0], icon[1]]} className="icon" style={{ ...iconStyle }} />}
            {msg && <span>{msg}</span>}
            {children && <div>{children}</div>}
        </StyledNotification>
    )
}

export default ToastNotification

const StyledNotification = styled.div`
    display: flex;
    align-items: center;
    .icon {
        margin-right: 5px;
        color: #48a0f8;
    }
    @media (max-width: 768px) {
        text-align: center;
    }
`
