import { ComponentProps } from 'react'
import { PulseData } from './PulseData'
import Separator from '../Separator'
import Styles from './GivePulse.module.css'
import { Tip } from '../Tip'
import { useMyPulseData } from '../../pages/meeting-view/hooks/useMyPulseData'
import { usePulses } from '../../pages/meeting-view/hooks/usePulses'
import { useTranslation } from 'react-i18next'

export const GivePulse = (props: ComponentProps<typeof PulseData>) => {
    const { t } = useTranslation('pulses')
    const { canGivePulse, isPending } = useMyPulseData()
    const { givePulse, isFetching } = usePulses()
    const { userIdentity } = props

    const id = `no-remaining-pulses-${userIdentity}`
    const disabled = !canGivePulse || isPending || isFetching
    const onClick = () => !disabled && givePulse(userIdentity)

    return (
        <div data-tooltip-id={id} className={Styles.givePulse}>
            {!canGivePulse && <Tip id={id}>{t('You cannot give more pulses for now.')}</Tip>}
            <div className={`flex no-gap ${Styles.action}`}>
                <button disabled={disabled} onClick={onClick}>
                    +
                </button>
                <Separator className={Styles.separator} />
            </div>

            <PulseData onClick={onClick} className={Styles.pulseData} {...props} />
        </div>
    )
}
