import { ComponentProps } from 'react'

export default function Separator({ align = 'vertical', className, style, ...rest }: Props) {
    return (
        <div
            {...rest}
            style={{
                width: align === 'vertical' ? '1px' : '100%',
                height: align === 'vertical' ? '100%' : '1px',
                backgroundColor: 'rgba(50, 50, 50, 0.8)',
                ...style,
            }}
            className={className}
        />
    )
}

interface Props extends ComponentProps<'div'> {
    align?: 'vertical' | 'horizontal'
}
