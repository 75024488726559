import React, { createContext, useContext, useCallback, useEffect, useRef, useState } from 'react'
import { logMediaError, logToSentry } from '../helpers/sentry'

interface PermissionState {
    camera: boolean
    microphone: boolean
    checking: boolean
    devicesBusy: boolean
    devicesNotFound: boolean
    checkPermissions: () => Promise<boolean>
}

const PermissionContext = createContext<PermissionState | null>(null)

export function PermissionProvider({ children }: { children: React.ReactNode }) {
    const [permissions, setPermissions] = useState({ camera: false, microphone: false })
    const [checking, setChecking] = useState(true)
    const [devicesBusy, setDevicesBusy] = useState(false)
    const [devicesNotFound, setDevicesNotFound] = useState(false)
    const checkingRef = useRef(false)
    const timeoutRef = useRef<NodeJS.Timeout | null>(null)
    const stableCheckingRef = useRef<NodeJS.Timeout | null>(null)
    const deviceCheckTimeoutRef = useRef<NodeJS.Timeout | null>(null)

    const checkPermissions = useCallback(async () => {
        if (checkingRef.current) {
            return permissions.camera && permissions.microphone
        }

        try {
            checkingRef.current = true
            setChecking(true)
            setDevicesBusy(false)
            setDevicesNotFound(false)

            if (stableCheckingRef.current) {
                clearTimeout(stableCheckingRef.current)
            }

            // First check if any devices are available
            const devices = await navigator.mediaDevices.enumerateDevices()
            const hasVideoDevice = devices.some(device => device.kind === 'videoinput')
            const hasAudioDevice = devices.some(device => device.kind === 'audioinput')

            if (!hasVideoDevice || !hasAudioDevice) {
                const missingDevices = []
                if (!hasVideoDevice) missingDevices.push('camera')
                if (!hasAudioDevice) missingDevices.push('microphone')

                logToSentry(`No media devices available: ${missingDevices.join(', ')}`, 'device-status', {
                    status: 'not-found',
                    error: 'No media devices available',
                    errorName: 'DeviceNotFoundError',
                })

                setDevicesNotFound(true)
                setPermissions({ camera: false, microphone: false })
                setChecking(false)
                return false
            }

            const stream = await navigator.mediaDevices.getUserMedia({ audio: true, video: true })
            const videoGranted = stream.getVideoTracks().length > 0
            const audioGranted = stream.getAudioTracks().length > 0

            // Log if either permission is disabled
            if (!videoGranted || !audioGranted) {
                const disabledDevices = []
                if (!videoGranted) disabledDevices.push('camera')
                if (!audioGranted) disabledDevices.push('microphone')
                logToSentry(`Media permissions disabled: ${disabledDevices.join(', ')}`, 'permissions')
            }

            setPermissions({
                camera: videoGranted,
                microphone: audioGranted,
            })

            stream.getTracks().forEach(track => track.stop())

            stableCheckingRef.current = setTimeout(() => {
                setChecking(false)
            }, 1000)

            return videoGranted && audioGranted
        } catch (error) {
            console.info('Permission check failed:', error)
            logMediaError(error, 'permission check')

            const isBusyError =
                error instanceof Error && (error.name === 'NotReadableError' || error.name === 'TrackStartError')

            const isNotFoundError = error instanceof Error && error.name === 'NotFoundError'

            if (isBusyError) {
                console.log('Media devices are busy - they might be in use by another application')
                logToSentry('Media devices are busy', 'device-status', {
                    error: error instanceof Error ? error.message : 'Unknown error',
                    errorName: error instanceof Error ? error.name : 'Unknown error type',
                    status: 'busy',
                })
                setDevicesBusy(true)
                setDevicesNotFound(false)
            } else if (isNotFoundError) {
                console.log('No camera or microphone devices found')
                logToSentry('No media devices found', 'device-status', {
                    error: error instanceof Error ? error.message : 'Unknown error',
                    errorName: error instanceof Error ? error.name : 'Unknown error type',
                    status: 'not-found',
                })
                setDevicesNotFound(true)
                setDevicesBusy(false)
            }

            setPermissions({ camera: false, microphone: false })

            stableCheckingRef.current = setTimeout(() => {
                setChecking(false)
            }, 1000)

            return false
        } finally {
            checkingRef.current = false
        }
    }, [permissions.camera, permissions.microphone])

    // Handle device changes
    useEffect(() => {
        const handleDeviceChange = () => {
            // Clear any existing timeout to prevent multiple rapid checks
            if (deviceCheckTimeoutRef.current) {
                clearTimeout(deviceCheckTimeoutRef.current)
            }

            // Add a small delay to handle multiple rapid device changes
            deviceCheckTimeoutRef.current = setTimeout(() => {
                console.log('Devices changed, rechecking permissions...')
                checkPermissions()
            }, 500)
        }

        navigator.mediaDevices.addEventListener('devicechange', handleDeviceChange)

        return () => {
            navigator.mediaDevices.removeEventListener('devicechange', handleDeviceChange)
            if (deviceCheckTimeoutRef.current) {
                clearTimeout(deviceCheckTimeoutRef.current)
            }
        }
    }, [checkPermissions])

    useEffect(() => {
        const watchPermission = async (name: PermissionName) => {
            try {
                const status = await navigator.permissions.query({ name })
                const listener = () => {
                    if (timeoutRef.current) {
                        clearTimeout(timeoutRef.current)
                    }

                    setChecking(true)

                    timeoutRef.current = setTimeout(() => {
                        checkPermissions()
                    }, 500)
                }

                // Log initial permission state if denied
                if (status.state === 'denied') {
                    logToSentry(`${name} permission denied`, 'permissions')
                }

                status.addEventListener('change', listener)
                return () => status.removeEventListener('change', listener)
            } catch (error) {
                console.error(`Error watching ${name} permission:`, error)
                logToSentry(`Failed to watch ${name} permission`, 'permissions')
                return () => {}
            }
        }

        const cleanupCamera = watchPermission('camera' as PermissionName)
        const cleanupMicrophone = watchPermission('microphone' as PermissionName)

        checkPermissions()

        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current)
            }
            if (stableCheckingRef.current) {
                clearTimeout(stableCheckingRef.current)
            }
            cleanupCamera.then(cleanup => cleanup())
            cleanupMicrophone.then(cleanup => cleanup())
        }
    }, [checkPermissions])

    return (
        <PermissionContext.Provider
            value={{
                ...permissions,
                checking,
                devicesBusy,
                devicesNotFound,
                checkPermissions,
            }}
        >
            {children}
        </PermissionContext.Provider>
    )
}

export const usePermissions = () => {
    const context = useContext(PermissionContext)
    if (!context) {
        throw new Error('usePermissions must be used within a PermissionProvider')
    }
    return context
}
