import { ConfirmationDialogStyled } from './ConfirmationDialog'
import { PrimaryBtnOutline, PrimaryButton } from '../Buttons'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { requestHelp } from '../../apis/api-widget'
import { getSessionParameters } from '../../helpers/urls'
import { extractRoomFromTopic } from '../../helpers/formatters'
import { useUI } from '../../context/UIContext'
import { CommandChannel } from '@zoom/videosdk'

interface HelpRequestModalProps {
    isActive: boolean
    onClose: () => void
    onComplete: () => void
    commandChannel: typeof CommandChannel | null
}

const HelpRequestModal = ({ isActive, onClose, onComplete, commandChannel }: HelpRequestModalProps) => {
    const { t } = useTranslation('meeting')
    const { topic } = getSessionParameters()
    const { showToast } = useUI()
    const queryClient = useQueryClient()
    const teamNumber = extractRoomFromTopic(topic)

    const notifyTeam = async () => {
        const helpMessage = {
            type: 'help-request',
            teamNumber,
            timestamp: new Date().toISOString(),
        }

        try {
            // Send to all participants (no userId means broadcast)
            if (commandChannel) await commandChannel.send(JSON.stringify(helpMessage))
        } catch (error) {
            console.error('Failed to send help request message:', error)
        }
    }

    const { mutate: submitHelpRequest } = useMutation({
        mutationFn: () => requestHelp(teamNumber || 0),
        onSuccess: async () => {
            // First notify all team members
            await notifyTeam()

            // Then update UI and close modal
            queryClient.invalidateQueries({ queryKey: ['Help Request'] })
            showToast(t('helpRequest.toast.success'))
            onComplete()
        },
    })

    const handleSubmit = () => {
        if (!isActive) {
            submitHelpRequest()
        }
        onClose()
    }

    return (
        <ConfirmationDialogStyled>
            <div className="info">
                <h2>{t('helpRequest.modal.title')}</h2>
                <p>{t('helpRequest.modal.copy')}</p>
            </div>

            <div className="actions">
                <PrimaryButton onClick={handleSubmit}>{t('helpRequest.modal.submit')}</PrimaryButton>
                <PrimaryBtnOutline onClick={onClose} colorTheme="light">
                    {t('helpRequest.modal.cancel')}
                </PrimaryBtnOutline>
            </div>
        </ConfirmationDialogStyled>
    )
}

export default HelpRequestModal
