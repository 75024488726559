import { useQuery } from '@tanstack/react-query'
import { useSessionParams } from '../../../hooks/useSessionParams'
import { getMe } from '../../../apis/api-signup'
import { updateSessionParams } from '../../../helpers/urls'
import { useIntercom } from '../../../hooks/useIntercom'

export const useMe = () => {
    const { _id } = useSessionParams()
    const { clientUpdate } = useIntercom()

    const queryKey = ['Me', _id]

    const { data, ...rest } = useQuery({
        queryKey,
        queryFn: async () => {
            try {
                const data = await getMe()
                const user = data?.user
                const name = user?.displayName ?? `${user?.name} ${user?.lastname}`
                clientUpdate({
                    user_id: user.userId,
                    name,
                    avatar: {
                        type: 'avatar',
                        image_url: user.urlImage ?? '',
                    },
                })
                return data
            } catch (error) {}
        },
        enabled: !!_id,
        refetchOnWindowFocus: false,
    })

    data && data.user.displayNameOrName && updateSessionParams({ name: data.user.displayNameOrName })

    return {
        ...data,
        ...rest,
    }
}
