import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { MediaBtnContainer } from '../../../pre-meeting/components/Buttons'
import { Tip } from '../../../../components/Tip'
import useRecording from '../../hooks/useRecording'
import { useZoomContext } from '../../../../context/ZoomContext'
import { useTranslation } from 'react-i18next'
import { useSessionParams } from '../../../../hooks/useSessionParams'

export const Recording = () => {
    const { t } = useTranslation('meeting')
    const { isRecording, isHostOrManager } = useZoomContext()
    const { handleRecording } = useRecording()
    const { room } = useSessionParams()

    if (!isHostOrManager) return null
    if (room === 'teams') return null

    return (
        <div>
            <MediaBtnContainer
                active={isRecording}
                accent
                data-tooltip-id="recording-pre-action"
                onClick={e => {
                    e.stopPropagation()
                    handleRecording()
                }}
            >
                <FontAwesomeIcon icon={[`${isRecording ? 'fas' : 'far'}`, 'circle']} fixedWidth />
            </MediaBtnContainer>
            <Tip
                id="recording-pre-action"
                content={isRecording ? t('recording.stopRecording') : t('recording.startRecording')}
            />
        </div>
    )
}
