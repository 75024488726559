import { useEffect, RefObject } from 'react'
import { VIDEO_FOOTER_ID } from '../pages/meeting-view/util/video-constants'

type Handler = () => void

interface UseClickOutsideOptions {
    excludeFooter?: boolean
    excludeModalContent?: boolean
}

export const useClickOutside = (
    ref: RefObject<any>,
    handler: Handler,
    options: UseClickOutsideOptions = {
        excludeFooter: true,
        excludeModalContent: true,
    }
) => {
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            // Skip if ref is not set
            if (!ref.current) return

            if (options.excludeFooter) {
                const footerElement = document.getElementById(VIDEO_FOOTER_ID)
                if (footerElement?.contains(event.target as Node)) return
            }

            if (options.excludeModalContent) {
                const modalContent = document.getElementsByClassName('ReactModal__Content')
                if (modalContent.length && modalContent[0].contains(event.target as Node)) return
            }

            // For ReactModal, if we reach this point and ref exists, it means
            // the click was outside since we've already checked modalContent
            handler()
        }

        document.addEventListener('click', handleClickOutside, true)
        return () => document.removeEventListener('click', handleClickOutside, true)
    }, [ref, handler, options])
}
