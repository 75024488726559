import { useCallback, useEffect, useMemo, useRef } from 'react'

import { ConfirmationDialog } from '../../components/Modals/ConfirmationDialog'
import Controls from './components/VideoFooter/Controls'
import ErrorComponent from './components/Video/ErrorComponent'
import { LoadingBolt } from '../../components/LoadingBolt'
import MeetingLayout from '../../layouts/MeetingLayout'
import { PermissionModal } from '../../components/Modals/PermissionModal'
import QuitModal from '../../components/Modals/QuitModal'
import { VideoCapturingState } from '@zoom/videosdk'
import VideoSAB from './components/Video/VideoSAB'
import VideoSingle from './components/Video/VideoSingle'
import { persistSessionParameters } from '../../helpers/urls'
import toast from 'react-hot-toast'
import { useActions } from '../../hooks/useActions'
import useCommandChannel from './hooks/useCommandChannel'
import { useJoinSDK } from './hooks/useJoinSDK'
import { useNavigate } from 'react-router-dom'
import { useNetworkQuality } from './hooks/useNetworkQuality'
import useRecording from './hooks/useRecording'
import { useSessionParams } from '../../hooks/useSessionParams'
import { useSettings } from '../../hooks/useSettings'
import { useUI } from '../../context/UIContext'
import { useZoomContext } from '../../context/ZoomContext'
import { SentryFeedbackButton } from './components/Dev/SentryFeedbackButton'
import { useMediaContext } from './hooks/useMediaContext'
import { useTranslation } from 'react-i18next'
import usePermissionChange from '../../hooks/usePermissionChange'
import { getDeviceType } from './util/platform'

const MeetingView = () => {
    persistSessionParameters()

    const zoom = useZoomContext()
    const { zoomClient, confirmationDialog, currentUserInfo, setIsVideoStarted: setIsStartedVideo, mediaStream } = zoom
    const { join, errorType, isJoining, ...restSDK } = useJoinSDK()
    const networkQuality = useNetworkQuality(zoomClient, mediaStream)
    const mediaContext = useMediaContext(zoomClient, mediaStream)
    const selfShareRef = useRef<(HTMLCanvasElement & HTMLVideoElement) | null>(null)
    const { showToast } = useUI()
    const { permissionGranted } = usePermissionChange(zoomClient, mediaStream)
    const { room } = useSessionParams()
    const { settings, isLoading } = useSettings()
    const { hasSingleViewMeeting } = settings
    const navigate = useNavigate()
    const { t } = useTranslation('App')

    useActions()
    useCommandChannel()
    useRecording()

    const VideoComponent = useMemo(() => {
        if (room === 'general') {
            if (
                hasSingleViewMeeting ||
                !mediaStream?.isSupportMultipleVideos() ||
                !mediaStream.isSupportVirtualBackground()
            )
                return VideoSingle
            return VideoSAB
        } else if (room === 'teams') {
            if (!mediaStream?.isSupportMultipleVideos()) return VideoSingle
            return VideoSAB
        }

        return VideoSAB
    }, [hasSingleViewMeeting, mediaStream, room])

    const props = {
        zoomClient,
        selfShareRef,
        mediaStream,
        ...mediaContext,
    }

    useEffect(() => {
        const isPreMeetingDone = JSON.parse(sessionStorage.getItem('isPreMeetingDone') ?? 'false') as boolean
        if (!isPreMeetingDone && getDeviceType() === 'desktop') return navigate('/pre-meeting')
        if (!zoomClient.getSessionInfo().isInMeeting) join()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onVideoCaptureChange = useCallback(
        async (payload: { state: VideoCapturingState }) => {
            setIsStartedVideo(payload.state === VideoCapturingState.Started)
        },
        [setIsStartedVideo]
    )

    useEffect(() => {
        zoomClient.on('video-capturing-change', onVideoCaptureChange)
        return () => {
            zoomClient.off('video-capturing-change', onVideoCaptureChange)
        }
    }, [zoomClient, onVideoCaptureChange])

    useEffect(() => {
        if (!zoomClient || !mediaStream) return
        const checkNetworkQuality = async () => {
            try {
                if (!currentUserInfo || typeof currentUserInfo.userId === 'undefined') return
                const { userId } = currentUserInfo
                const userNetworkQuality = networkQuality[userId]

                if (userNetworkQuality) {
                    const uplink = userNetworkQuality.uplink ?? Number.MAX_VALUE
                    const downlink = userNetworkQuality.downlink ?? Number.MAX_VALUE

                    if (uplink <= 1 || downlink <= 1) {
                        showToast(t('networkIssues'), {
                            id: 'network-quality-info',
                        })
                    } else toast.dismiss('network-quality-info')
                }
            } catch (error) {
                console.error('Error checking network quality:', error)
            }
        }

        checkNetworkQuality()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentUserInfo, mediaStream, networkQuality, showToast, zoomClient])

    if (isJoining || isLoading) return <LoadingBolt />
    if (!permissionGranted) return <ConfirmationDialog modal={<PermissionModal />} isOpen={true} />

    return (
        <MeetingLayout {...restSDK}>
            <div>
                {errorType ? (
                    <ErrorComponent {...{ ...errorType }} />
                ) : (
                    mediaStream && (
                        <>
                            <VideoComponent {...props} />
                            <Controls {...props} />
                        </>
                    )
                )}
            </div>
            {confirmationDialog && <ConfirmationDialog modal={<QuitModal />} isOpen={true} />}
            <SentryFeedbackButton />
        </MeetingLayout>
    )
}

export default MeetingView
