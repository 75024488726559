// @ts-ignore
import './library'
import './styles/global.scss'
import './styles/redesign.scss'

import { AppRoutes } from './router/AppRoutes'
import DayJS from 'dayjs'
import ReactGA from 'react-ga4'
import { Suspense, useEffect } from 'react'
import { ThemeProvider } from 'styled-components'
import { defaultTheme } from './styles/themes/defaultTheme'
import duration from 'dayjs/plugin/duration'
import relativeTime from 'dayjs/plugin/relativeTime'
import { LoadingBolt } from './components/LoadingBolt'
import { useIntercom } from './hooks/useIntercom'

DayJS.extend(relativeTime)
DayJS.extend(duration)

const GoogleAnalyticsID = process.env.REACT_APP_GA_ID
const isProduction = process.env.NODE_ENV === 'production'

const App = () => {
    const { clientBoot } = useIntercom()

    if (GoogleAnalyticsID) {
        ReactGA.initialize([{ trackingId: GoogleAnalyticsID }])
    }

    useEffect(() => {
        clientBoot({
            app_id: isProduction ? 'qu6m3nwu' : 'cw1s2grp',
            action_color: '#FFCD00',
            background_color: '#37474F',
            horizontal_padding: 20, // Horizontal padding
            vertical_padding: 20,
            alignment: 'left',
            hide_default_launcher: true,
        })
    }, [clientBoot])

    return (
        <Suspense fallback={<LoadingBolt />}>
            <ThemeProvider theme={defaultTheme}>
                <AppRoutes />
            </ThemeProvider>
        </Suspense>
    )
}

export default App
