import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const TimerContainer = styled.div`
    position: absolute;
    top: 1rem;
    right: 1rem;
    padding: 0.5rem 1rem;
    background: rgba(0, 0, 0, 0.75);
    border-radius: 4px;
    color: white;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    z-index: 10;
`

const CountdownText = styled.span`
    color: #007bff;
    font-weight: bold;
`

const CloseButton = styled.button`
    background: none;
    border: none;
    color: white;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    opacity: 0.8;
    transition: opacity 0.2s;

    &:hover {
        opacity: 1;
    }
`

interface NextVideoTimerProps {
    seconds: number
    nextVideoTitle?: string
    onCancel: () => void
}

const NextVideoTimer: React.FC<NextVideoTimerProps> = ({ seconds, nextVideoTitle, onCancel }) => {
    const { t } = useTranslation('onboarding')

    if (seconds <= 0) return null

    return (
        <TimerContainer>
            <span>{t('nextVideoTimer.prefix')}</span>
            <CountdownText>{t('nextVideoTimer.seconds', { count: seconds })}</CountdownText>
            <CloseButton onClick={onCancel} aria-label="Cancel autoplay">
                X
            </CloseButton>
        </TimerContainer>
    )
}

interface AutoplayInfo {
    hasNextVideo: boolean
    nextVideoTitle?: string
}

export type { AutoplayInfo }
export default NextVideoTimer
