import { BgType } from '../hooks/useBackgroundEffects'

export const fxItems: BgType[] = [
    {
        label: 'blur.blurBackground',
        deviceId: 'blur',
        key: undefined,
        type: 'option',
        scope: 'public',
    },
    {
        label: 'blur.pulse',
        deviceId: 'https://d1cs69r9y4gokr.cloudfront.net/virtual-backgrounds/Pulse.jpg',
        key: undefined,
        type: 'option',
        scope: 'public',
    },
    {
        label: 'Network',
        deviceId: 'https://d1cs69r9y4gokr.cloudfront.net/virtual-backgrounds/Network.jpg',
        key: undefined,
        type: 'option',
        scope: 'public',
    },
    {
        label: 'NetBalls',
        deviceId: 'https://d1cs69r9y4gokr.cloudfront.net/virtual-backgrounds/NetBalls.jpg',
        key: undefined,
        type: 'option',
        scope: 'public',
    },
    {
        label: 'Earth',
        deviceId: 'https://d1cs69r9y4gokr.cloudfront.net/virtual-backgrounds/Earth.jpg',
        key: undefined,
        type: 'option',
        scope: 'public',
    },
    {
        label: 'Dot Network',
        deviceId: 'https://d1cs69r9y4gokr.cloudfront.net/virtual-backgrounds/Dot-network.jpg',
        key: undefined,
        type: 'option',
        scope: 'public',
    },
    {
        label: 'Creativity',
        deviceId: 'https://d1cs69r9y4gokr.cloudfront.net/virtual-backgrounds/Creativity.jpg',
        key: undefined,
        type: 'option',
        scope: 'public',
    },
    {
        label: 'Fondo MELI',
        deviceId: 'https://d1cs69r9y4gokr.cloudfront.net/virtual-backgrounds/Meli-Bg.jpg',
        key: undefined,
        type: 'option',
        scope: ['6662246654572e9c2f102199', '65a96c12e4af98f8337e3965'],
    },
    {
        label: 'SREvolution',
        deviceId: 'https://d1cs69r9y4gokr.cloudfront.net/virtual-backgrounds/SREvolution.jpg',
        key: undefined,
        type: 'option',
        scope: [
            '66be2d662552adc2ede2f532',
            '66be39d52552ad0b17e2f68b',
            '66be3b1b2552ad1c75e2f694',
            '66be36002552ad2f38e2f632',
            '66be38172552ad9a39e2f679',
            '66be25e32552ad2f48e2f394',
        ],
    },
    {
        label: 'Meli Shipping',
        deviceId: 'https://d1cs69r9y4gokr.cloudfront.net/virtual-backgrounds/Meli-Shipping.jpg',
        key: undefined,
        type: 'option',
        scope: [
            '66b3a4514ba55f4667c39e63',
            '66c3a99d520920497774007a',
            '66c3ace55209204dc574029a',
            '66c3aeb2520920cc4b7402fc',
            '66c3b0ac520920c5aa740308',
            '66c3b1ed5209207b5f740311',
        ],
    },
    {
        label: 'blur.disable',
        deviceId: 'disable-effect',
        key: undefined,
        type: 'option',
        scope: 'public',
    },
]
