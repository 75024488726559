import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { MediaBtnContainer } from '../../../pre-meeting/components/Buttons'
import { Tip } from '../../../../components/Tip'
import { useSessionParams } from '../../../../hooks/useSessionParams'
import { useTranslation } from 'react-i18next'
import useRaisedHands from '../../../../hooks/useRaisedHands'

export const RaiseHand = () => {
    const { t } = useTranslation('meeting') // Use the 'meeting' namespace
    const { toggleRaiseHand, IRaisedMyHand } = useRaisedHands()
    const { role } = useSessionParams()

    if (role !== 'student') return null

    return (
        <div style={{ display: 'flex' }}>
            <MediaBtnContainer
                active={IRaisedMyHand}
                square
                data-tooltip-id="toggle-raise-hand-action"
                onClick={() => toggleRaiseHand()}
            >
                <FontAwesomeIcon icon={['far', 'hand']} />
            </MediaBtnContainer>
            <Tip
                id="toggle-raise-hand-action"
                content={IRaisedMyHand ? t('raiseHand.lowerHand') : t('raiseHand.raiseHand')}
            />
        </div>
    )
}
