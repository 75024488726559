import { MediaStream, ZoomClient } from '../types/video-types'
import { useCallback, useEffect, useState } from 'react'
import { AudioOption, CaptureVideoOption, ExecutedFailure } from '@zoom/videosdk'
import { checkAllEncodingsTrue } from '../../../helpers/formatters'
import { getItem, removeItem, setItem } from '../../../helpers/localStorage'
import { useMediaContext } from './useMediaContext'
import usePermissionChange from '../../../hooks/usePermissionChange'

type Props = {
    mediaStream: MediaStream | null
    zoomClient: ZoomClient
}

const validateSavedDevice = (mediaStream: MediaStream, deviceType: 'audio' | 'video'): { deviceId: string } | null => {
    const storageKey = deviceType === 'audio' ? 'last-mic-id' : 'last-cam-id'
    const savedDevice = getItem<{ deviceId: string }>(storageKey)

    if (!savedDevice) return null

    const deviceList = deviceType === 'audio' ? mediaStream.getMicList() : mediaStream.getCameraList()
    const isDeviceAvailable = deviceList.some(device => device.deviceId === savedDevice.deviceId)

    if (!isDeviceAvailable) {
        removeItem(storageKey)
        if (deviceList.length > 0) {
            const newDevice = { deviceId: deviceList[0].deviceId }
            setItem(storageKey, newDevice)
            return newDevice
        }
        return null
    }

    return savedDevice
}

export const useAutoStartMedia = ({ zoomClient, mediaStream }: Props) => {
    const { mediaState } = useMediaContext(zoomClient, mediaStream)
    const [isAudioStarted, setIsAudioStarted] = useState(false)
    const [isVideoStarted, setIsVideoStarted] = useState(false)
    const { permissionGranted } = usePermissionChange(zoomClient, mediaStream)
    const [isAudioLoading, setIsAudioLoading] = useState(false)
    const [isVideoLoading, setIsVideoLoading] = useState(false)

    const isLoadingError = (error: ExecutedFailure) =>
        error.type === 'INVALID_OPERATION' && error.reason === 'Computer audio has been loading, please wait.'

    const autoStartAudio = useCallback(async (): Promise<void> => {
        if (!zoomClient || !mediaStream || isAudioStarted || isAudioLoading) return

        const startAudioOptions: AudioOption = {
            mute: getItem<boolean>('pre-mute-state') ?? true,
            backgroundNoiseSuppression: true,
        }

        const validatedDevice = validateSavedDevice(mediaStream, 'audio')
        if (validatedDevice) {
            startAudioOptions.microphoneId = validatedDevice.deviceId
        }

        try {
            setIsAudioLoading(true)
            await mediaStream.startAudio(startAudioOptions)
            setIsAudioStarted(true)
        } catch (error) {
            if (isLoadingError(error as ExecutedFailure)) {
                console.warn('Computer audio has been loading, please wait.')
                return
            }

            try {
                const micList = mediaStream.getMicList()
                if (micList.length > 0) {
                    startAudioOptions.microphoneId = micList[0].deviceId || 'default'
                    await mediaStream.startAudio(startAudioOptions)
                    setItem('last-mic-id', { deviceId: micList[0].deviceId })
                    setIsAudioStarted(true)
                }
            } catch (fallbackError) {
                if (isLoadingError(fallbackError as ExecutedFailure)) {
                    console.warn('Computer audio has been loading, please wait.')
                } else {
                    console.error('Failed to start audio with fallback device:', fallbackError)
                }
            }
        } finally {
            setIsAudioLoading(false)
        }
    }, [zoomClient, mediaStream, isAudioStarted, isAudioLoading])

    const isStartingError = (error: ExecutedFailure) =>
        error.type === 'INVALID_OPERATION' && error.reason === 'Camera is starting,please wait.'

    const autoStartVideo = useCallback(async (): Promise<void> => {
        if (!mediaStream || zoomClient.getCurrentUserInfo().bVideoOn || isVideoStarted || isVideoLoading) return

        const startVideoOptions: CaptureVideoOption = {
            mirrored: true,
            hd: true,
            fullHd: true,
        }

        const imageUrl = getItem<string | undefined>('vfx')
        if (mediaStream?.isSupportVirtualBackground() && imageUrl) {
            startVideoOptions.virtualBackground = { imageUrl }
        }

        const validatedDevice = validateSavedDevice(mediaStream, 'video')
        if (validatedDevice) {
            startVideoOptions.cameraId = validatedDevice.deviceId
        }

        try {
            setIsVideoLoading(true)
            await mediaStream.startVideo(startVideoOptions)
            setIsVideoStarted(true)
        } catch (error) {
            if (isStartingError(error as ExecutedFailure)) {
                console.warn('Camera is starting,please wait.')
                return
            }

            try {
                const cameralist = mediaStream.getCameraList()
                if (cameralist.length > 0) {
                    startVideoOptions.cameraId = cameralist[0].deviceId || 'default'
                    await mediaStream.startVideo(startVideoOptions)
                    setItem('last-cam-id', { deviceId: cameralist[0].deviceId })
                    setIsVideoStarted(true)
                }
            } catch (fallbackError) {
                if (isStartingError(fallbackError as ExecutedFailure)) {
                    console.warn('Camera is starting,please wait.')
                } else {
                    console.error('Failed to start video with fallback device:', fallbackError)
                }
            }
        } finally {
            setIsVideoLoading(false)
        }
    }, [mediaStream, zoomClient, isVideoStarted, isVideoLoading])

    const startMedia = useCallback(
        () =>
            Promise.allSettled([autoStartAudio(), autoStartVideo()]).catch(err =>
                console.error('Error starting media', err)
            ),
        [autoStartAudio, autoStartVideo]
    )

    useEffect(() => {
        if (permissionGranted && !isAudioLoading && !isVideoLoading) startMedia()
    }, [permissionGranted, startMedia, isAudioLoading, isVideoLoading])

    return {
        autoStartAudio,
        autoStartVideo,
        isAudioStarted,
        isVideoStarted,
    }
}
