import { Participant } from '@zoom/videosdk'
import { PulseData } from '../Pulse/PulseData'
import { RaisedHand } from '../RaisedHand'
import { UserMic } from './UserMic'

export const UserGeneral = (props: Participant) => (
    <div className="user">
        <div className="top-left">
            <UserMic {...props} />
            <span>{props.displayName}</span>
        </div>
        <div className="top-right">
            <RaisedHand {...props} />
            <PulseData {...props} />
        </div>
    </div>
)
