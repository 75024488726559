import { useState, useEffect } from 'react'
import { useMyAudio } from '../hooks/useMyAudio'
import { usePreMeetingContext } from '../../../context/PreMeetingContext'

export const AudioLevel = () => {
    const [audioLevel, setAudioLevel] = useState(0)
    const { audioStream } = useMyAudio()
    const { isAudioMuted } = usePreMeetingContext()

    useEffect(() => {
        if (!audioStream) return

        const audioContext = new (window.AudioContext || window.AudioContext)()
        const audioSource = audioContext.createMediaStreamSource(audioStream)
        const analyser = audioContext.createAnalyser()
        audioSource.connect(analyser)
        analyser.fftSize = 256
        const bufferLength = analyser.frequencyBinCount
        const dataArray = new Uint8Array(bufferLength)

        const updateAudioLevel = () => {
            if (!isAudioMuted) {
                analyser.getByteFrequencyData(dataArray)
                const average = dataArray.reduce((a, b) => a + b) / bufferLength
                setAudioLevel(Math.min(100, Math.round((average / 255) * 100)))
            } else {
                setAudioLevel(0)
            }
            requestAnimationFrame(updateAudioLevel)
        }
        updateAudioLevel()

        return () => {
            audioContext.close()
        }
    }, [audioStream, isAudioMuted])

    return <progress max="100" value={audioLevel} />
}
