import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useOnboarding } from '../../../context/OnboardingContext'
import { useBackgroundMusic } from '../hooks/useBackgroundMusic'
import AudioControls from './AudioControls'
import Logger from '../../../helpers/logger'

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 400px;
    padding: 2rem;
    text-align: center;
`

const Title = styled.h2`
    font-size: 2rem;
    color: white;
    margin-bottom: 1rem;
`

const CourseStartTime = styled.div`
    font-size: 1.2rem;
    color: #007bff;
    margin-bottom: 1rem;
    font-family: monospace;
`

const Subtitle = styled.p`
    font-size: 1.2rem;
    color: #999;
    margin-bottom: 2rem;
    max-width: 600px;
`

const TimerContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2rem;
`

const TimeDisplay = styled.div`
    font-size: 2.5rem;
    font-weight: bold;
    color: white;
    font-family: monospace;
    margin-bottom: 1rem;
`

const LoadingSpinner = styled.div`
    width: 70px;
    height: 70px;
    border: 5px solid #f3f3f3;
    border-top: 5px solid #007bff;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    margin-bottom: 30px;
    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`

interface CourseStartScreenProps {
    onCourseStart: () => void
}

const CourseStartScreen: React.FC<CourseStartScreenProps> = ({ onCourseStart }) => {
    const { t } = useTranslation('onboarding')
    const { config } = useOnboarding()
    const [remainingTime, setRemainingTime] = useState<number>(0)
    const [isTimeUp, setIsTimeUp] = useState(false)

    // Initialize background music with autoplay set to false
    const { isMuted, toggleMute, isPlaying } = useBackgroundMusic({
        isTimeUp,
        autoplay: false,
    })

    useEffect(() => {
        if (!config) return

        const calculateRemainingTime = () => {
            const currentTimeMs = Date.now()
            const courseStartMs = config.onboardingConfig.courseStartTime * 1000 // Convert to milliseconds
            const remainingMs = courseStartMs - currentTimeMs
            const remainingSeconds = Math.floor(remainingMs / 1000)

            Logger.debug('CourseStartScreen', 'Course start countdown:', {
                courseStartTime: new Date(courseStartMs).toLocaleString(),
                currentTime: new Date(currentTimeMs).toLocaleString(),
                remainingSeconds,
                debug: {
                    courseStartMs,
                    currentTimeMs,
                    remainingMs,
                },
            })

            return remainingSeconds > 0 ? remainingSeconds : 0
        }

        // Initial calculation
        const initial = calculateRemainingTime()
        setRemainingTime(initial)
        setIsTimeUp(initial <= 0)

        // Update every second
        const timer = setInterval(() => {
            const remaining = calculateRemainingTime()
            setRemainingTime(remaining)

            if (remaining <= 0) {
                setIsTimeUp(true)
                onCourseStart()
                clearInterval(timer)
            }
        }, 1000)

        return () => {
            clearInterval(timer)
        }
    }, [config, onCourseStart])

    const formatTime = (seconds: number): string => {
        if (seconds <= 0) return '0:00'
        const mins = Math.floor(seconds / 60)
        const secs = Math.floor(seconds % 60)
        return `${mins}:${secs.toString().padStart(2, '0')}`
    }

    const formatStartTime = (timestamp: number): string => {
        // Convert seconds to milliseconds for Date object
        const date = new Date(timestamp * 1000)
        return date.toLocaleTimeString('en-US', {
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
        })
    }

    if (!config) return null

    return (
        <Container>
            <AudioControls
                isMuted={isMuted}
                onMuteToggle={toggleMute}
                className="absolute top-4 right-4"
                icon={isPlaying ? (isMuted ? 'volume-slash' : 'volume') : 'volume-xmark'}
            />
            <TimerContainer>
                <LoadingSpinner />
                <TimeDisplay>{formatTime(remainingTime)}</TimeDisplay>
            </TimerContainer>
            <Title>
                {t('courseStart.title')}
                <CourseStartTime>
                    {t('courseStart.startTime', {
                        time: formatStartTime(config.onboardingConfig.courseStartTime),
                    })}
                </CourseStartTime>
            </Title>
            <Subtitle>{t('courseStart.subtitle')}</Subtitle>
        </Container>
    )
}

export default CourseStartScreen
