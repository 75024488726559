import styled from 'styled-components'
import { PrimaryBtnBlue } from '../../../components/Buttons'
import { useTranslation } from 'react-i18next'

type Props = {}

const DevicesNotFound = (props: Props) => {
    const { t } = useTranslation('premeeting')

    return (
        <DevicesNotFoundContainer>
            <h1>{t('permissions.noDevicesFound')}</h1>
            <p>{t('permissions.pleaseConnectDevices')}</p>
            <PrimaryBtnBlue
                style={{ fontSize: '1rem', width: 'auto', marginTop: '15px' }}
                onClick={() => window.location.reload()}
            >
                {t('permissions.refreshPage')}
            </PrimaryBtnBlue>
        </DevicesNotFoundContainer>
    )
}

export default DevicesNotFound

const DevicesNotFoundContainer = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    background: var(--color-primary);
    border-radius: var(--video-border-radius);
    overflow: hidden;
    aspect-ratio: 16 / 9;
    box-shadow: 0 0 100px 0 rgba(255, 100, 124, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    padding: 15px;
    box-sizing: border-box;
    flex-direction: column;
    gap: 10px;

    h1 {
        font-size: 1.24rem;
        text-align: center;
        font-weight: normal;
    }

    p {
        text-align: center;
        margin: 0;
    }
`
