import { MessageHeader, MessagesStyled } from './Messages'
import { TextMessage, VideoMessage } from '../../../../apis/api-egg'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Item } from './Chat/ChatItem'
import Styles from './Messages.module.css'
import { useBottom } from '../../../../hooks/useBottom'
import { useTranslation } from 'react-i18next'
import { useUI } from '../../../../context/UIContext'
import { useZoomContext } from '../../../../context/ZoomContext'

export const Diffusion = () => {
    const { diffusion } = useZoomContext()
    const ref = useBottom()

    return (
        <MessagesStyled>
            <main ref={ref}>
                {diffusion.map(item => (
                    <Item key={item.timestamp}>
                        <MessageHeader {...item} />
                        {item.type === 'video' ? <Video {...item} /> : item.type === 'text' ? <Text {...item} /> : null}
                    </Item>
                ))}
            </main>
        </MessagesStyled>
    )
}

const Video = (video: VideoMessage) => {
    const { openPip } = useUI()
    const { muteLocalAudio, unMuteLocalAudio } = useZoomContext()
    const { t } = useTranslation('sidebar')

    return (
        <div
            className={Styles['video-message']}
            onClick={() =>
                openPip({
                    ...video,
                    onMute: muteLocalAudio,
                    onUnmute: unMuteLocalAudio,
                })
            }
        >
            <FontAwesomeIcon icon={['fas', 'circle-play']} />
            {t('diffusion.videoMessage')}
        </div>
    )
}

const Text = ({ message, ...rest }: TextMessage) => <p {...rest}>{message}</p>
