import { Participant, ChatMessage as ZoomChatMessage } from '@zoom/videosdk'
import { getSessionItem } from '../../../../../helpers/localStorage'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { useZoomContext } from '../../../../../context/ZoomContext'
import toast from 'react-hot-toast'
import useParticipants from '../../../../../hooks/useParticipants'

export interface ChatMessage extends ZoomChatMessage {
    isFeatured: boolean
}

export type ChatUserItem = Pick<Participant, 'userId' | 'displayName' | 'isHost' | 'isManager'>

const everyone: ChatUserItem = { userId: 0, displayName: 'Everyone', isHost: false, isManager: false }

export const useChat = () => {
    const { chatClient, zoomClient, currentUserInfo } = useZoomContext()
    const { participants } = useParticipants()
    const receivers = useMemo(
        () => [everyone, ...participants.filter(p => p.userId !== currentUserInfo?.userId)] as ChatUserItem[],
        [currentUserInfo?.userId, participants]
    )
    const [history, setHistory] = useState<ChatMessage[]>(() =>
        (chatClient?.getHistory() ?? []).map((message: ZoomChatMessage) => ({ ...message, isFeatured: false }))
    )
    // Initialize the state for the selected receiver ID
    // It uses a combination of local storage and the current receivers list
    const state = useState(() => {
        // Retrieve the saved receiver ID from local storage, defaulting to 0 if not found
        const savedReceiverId = +getSessionItem<string>('receiverId') ?? 0
        // Return the saved ID if it's 0 (send to all) or if it exists in the current receivers list
        // Otherwise, default to 0 (send to all)
        return savedReceiverId === 0 || participants.some(r => r.userId === savedReceiverId) ? savedReceiverId : 0
    })
    // Destructure the state to get the current receiver ID and the setter function
    const [receiverId, setReceiverId] = state

    const sendMessage = useCallback(
        async (message: string | undefined, receiverId: number) => {
            if (!message || !chatClient) return
            try {
                if (receiverId === 0) {
                    // Send to everyone
                    await chatClient.sendToAll(message)
                } else {
                    await chatClient.send(message, receiverId)
                }
            } catch (error) {
                console.error('Error sending message:', error)
                toast.error('Error enviando mensaje. Intentalo nuevamente en un instante')
            }
        },
        [chatClient]
    )

    const onChatMessage = (message: ChatMessage) => setHistory(old => [...old, message])

    useEffect(() => {
        zoomClient.on('chat-on-message', onChatMessage)
        return () => {
            zoomClient.off('chat-on-message', onChatMessage)
        }
    }, [zoomClient, receiverId, setReceiverId])

    return {
        history,
        receivers,
        state,
        sendMessage,
    }
}
