import React, { useCallback, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { MediaBtnContainer } from '../../../pre-meeting/components/Buttons'
import { SidebarModeType } from '../../types/types'
import { Tip } from '../../../../components/Tip'
import { useZoomContext } from '../../../../context/ZoomContext'
import { useTranslation } from 'react-i18next'

export const ChatIcon = () => {
    const { t } = useTranslation('meeting')
    const { sidebarMode, unReadMessages, setSidebarMode, zoomClient, setUnReadMessages } = useZoomContext()

    const onToggleSidebar = useCallback(
        (toggle: SidebarModeType) => {
            setSidebarMode(oldSidebarMode => {
                return oldSidebarMode === toggle ? null : toggle
            })
        },
        [setSidebarMode]
    )

    const onChatMessage = useCallback(
        () => sidebarMode !== 'messages' && setUnReadMessages({ type: 'chat', status: true }),
        [setUnReadMessages, sidebarMode]
    )

    useEffect(() => {
        zoomClient.on('chat-on-message', onChatMessage)
        return () => {
            zoomClient.off('chat-on-message', onChatMessage)
        }
    }, [onChatMessage, zoomClient])

    return (
        <div>
            <MediaBtnContainer
                active={sidebarMode === 'messages'}
                square
                data-tooltip-id="chat-action"
                onClick={e => {
                    e.stopPropagation()
                    onToggleSidebar('messages')
                }}
                style={{ position: 'relative' }}
            >
                <div style={{ position: 'relative' }}>
                    <FontAwesomeIcon icon={['far', 'message-dots']} />
                    {unReadMessages && (
                        <FontAwesomeIcon
                            icon={['fas', 'circle']}
                            style={{
                                color: '#FF647C',
                                position: 'absolute',
                                top: -3,
                                right: -7,
                                fontSize: '12px',
                            }}
                        />
                    )}
                </div>
            </MediaBtnContainer>
            <Tip
                id="chat-action"
                content={sidebarMode === 'messages' ? t('chat.hideMessages') : t('chat.showMessages')}
            />
        </div>
    )
}
