import { Tooltip as ReactToolTips } from 'react-tooltip'
import { PropsWithChildren, ComponentProps } from 'react'

export const Tip = ({
    children,
    id,
    ...rest
}: { id: string } & PropsWithChildren & ComponentProps<typeof ReactToolTips>) => (
    <ReactToolTips
        id={id}
        place="top"
        className={`${rest.className}`}
        {...rest}
        style={{ borderRadius: '12px', maxWidth: '80vw' }}
        offset={20}
    >
        <section className="tooltip--container">{children}</section>
    </ReactToolTips>
)
