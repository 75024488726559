import * as Sentry from '@sentry/react'
import { PropsWithChildren, createContext, useContext, useState } from 'react'
import toast, { type ToastOptions } from 'react-hot-toast'
import { AppToaster } from '../components/AppToaster'
import { Pip, PipType } from '../components/Pip'
import { getMessageFromError } from '../helpers/errors'
import { ErrorType } from '../pages/meeting-view/components/Video/ErrorComponent'

const UIContext = createContext<UIContextType | undefined>(undefined)
UIContext.displayName = 'UIContext'

const UIProvider = ({ children }: PropsWithChildren) => {
    const [error, setError] = useState<ErrorType | null>(null)
    const [pip, setPip] = useState<PipType | null>(null)
    const showToast = toast

    const openPip = (pip: PipType) => {
        const { mute = true, onClose, onMute, onUnmute, ...rest } = pip
        if (mute) onMute?.()

        setPip({
            ...rest,
            mute,
            onMute: () => {
                onMute?.()
                setPip(old => ({ ...old, mute: true }))
            },
            onUnmute: () => {
                onUnmute?.()
                setPip(old => ({ ...old, mute: false }))
            },
            onClose: () => {
                if (mute) onUnmute?.()
                onClose?.()
                setPip(null)
            },
        })
    }

    const showError = (error: unknown, options?: ToastOptions) => {
        const message = getMessageFromError(error)
        Sentry.captureMessage(message)
        return toast.error(message, options)
    }

    const closePip = () => pip?.onClose?.()

    const values = {
        showToast,
        showError,
        error,
        setError,
        openPip,
        closePip,
        pip,
    }

    return (
        <UIContext.Provider value={values}>
            {children}
            <AppToaster />
            <Pip />
        </UIContext.Provider>
    )
}

const useUI = () => {
    const context = useContext(UIContext)
    if (context === undefined) {
        throw new Error('useUI must be used within a UIProvider')
    }
    return context
}

export { UIProvider, useUI }

export interface UIContextType {
    showToast: typeof toast
    showError: (error: unknown, options?: ToastOptions) => string
    error: ErrorType | null
    setError: (error: ErrorType | null) => void
    pip: PipType | null
    openPip: (props: PipType) => void
    closePip: () => void
}
