import {
    ChatClient,
    CommandChannelClient,
    MediaStream,
    Participant,
    RecordingClient,
    ZoomClient,
} from '../pages/meeting-view/types/video-types'
import {
    Dispatch,
    ReactNode,
    SetStateAction,
    createContext,
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react'
import ZoomVideo, { ExecutedFailure, LoggerClient, SessionInfo } from '@zoom/videosdk'

import { DiffusionMessage } from '../apis/api-egg'
import { MeetingRoom } from '../pages/meeting-view/api'
import { useLocation } from 'react-router-dom'
import { useUI } from './UIContext'
import { Pip } from '../components/Pip'
import { logFeatureRequirements, logSystemRequirements } from '../helpers/sentry'

const Context = createContext({} as ZoomContextValue)
Context.displayName = 'ZoomContext'

const zoomClient = ZoomVideo.createClient()
console.info('Feature Requirements', ZoomVideo.checkFeatureRequirements())
logFeatureRequirements(ZoomVideo.checkFeatureRequirements())
console.info('System Requirements', ZoomVideo.checkSystemRequirements())
logSystemRequirements(ZoomVideo.checkSystemRequirements())

export const ZoomProvider = ({ children }: { children: ReactNode }) => {
    const [isStartedScreenShare, setIsStartedScreenShare] = useState(false)
    const [currentUserInfo, setCurrentUserInfo] = useState<Participant | undefined>(undefined)
    const [currentSessionInfo, setCurrentSessionInfo] = useState<SessionInfo | undefined>(undefined)
    const [isStartingVideo, setIsStartingVideo] = useState(false)
    const [mediaStream, setMediaStream] = useState<MediaStream | null>(null)
    const [sidebarMode, setSidebarMode] = useState<SidebarModeType>(null)
    const [chatClient, setChatClient] = useState<ChatClient | null>(null)
    const [commandChannel, setCommandChannel] = useState<CommandChannelClient | null>(null)
    const [recordingClient, setRecordingClient] = useState<RecordingClient | null>(null)
    const [loggerClient, setLoggerClient] = useState<typeof LoggerClient | null>(null)
    const [unReadMessages, setUnReadMessages] = useState<UnreadMessageType>(false)
    const [isVideoStarted, setIsVideoStarted] = useState(false)
    const [isAudioStarted, setIsAudioStarted] = useState(false)
    const [isRecording, setIsRecording] = useState(false)
    const [confirmationDialog, setConfirmationDialog] = useState<ReactNode | undefined>(undefined)
    const [diffusion, setDiffusion] = useState(getContent)
    const [isLocalAudioMuted, setIsLocalAudioMuted] = useState(false)
    const [raisedHands, setRaisedHands] = useState<RaiseHandItem[]>([])
    const [hasRaisedHands, setHasRaisedHands] = useState<(Participant & { raiseHandOrder: number })[]>([])
    const [isHost, setIsHost] = useState(false)
    const [isHostOrManager, setIsHostOrManager] = useState(false)
    const timer = useRef<NodeJS.Timeout>()

    const { pathname } = useLocation()
    const { showError, showToast, pip } = useUI()

    const muteLocalAudio = useCallback(
        () => mediaStream?.muteAllUserAudioLocally().catch(showError),
        [mediaStream, showError]
    )

    const unMuteLocalAudio = useCallback(
        () => mediaStream?.unmuteAllUserAudioLocally().catch(showError),
        [mediaStream, showError]
    )

    const toggleMuteLocalAudio = async () => {
        try {
            if (isLocalAudioMuted) await unMuteLocalAudio()
            else await muteLocalAudio()
            setIsLocalAudioMuted(old => !old)
        } catch (error) {
            console.error('Unable to resume audio on users')
        }
    }

    const newDiffusionMessage = ({ timestamp, ...data }: DiffusionMessage) =>
        setDiffusion(old => old.concat({ ...data, timestamp: timestamp ?? Date.now() }))

    useEffect(() => {
        async function start() {
            try {
                await zoomClient.init('en-US', 'Global', {
                    webEndpoint: 'zoom.us',
                    stayAwake: true,
                    leaveOnPageUnload: true,
                    patchJsMedia: true,
                })
                setMediaStream(zoomClient.getMediaStream())
                setChatClient(zoomClient.getChatClient())
                setCommandChannel(zoomClient.getCommandClient())
                setRecordingClient(zoomClient.getRecordingClient())
                setLoggerClient(zoomClient.getLoggerClient())
            } catch (error) {
                console.error('Error initializing Zoom client', error)
            }
        }
        start()
    }, [])

    useEffect(() => {
        if (currentUserInfo) {
            const isOriginalHost = zoomClient.isOriginalHost()
            if (isOriginalHost)
                zoomClient.reclaimHost().then(() => {
                    showToast('You are the host of the meeting')
                })
            setIsHost(currentUserInfo.isHost || isOriginalHost)
            setIsHostOrManager(currentUserInfo.isHost || currentUserInfo.isManager)
        }
    }, [currentUserInfo, showToast])

    useEffect(() => {
        if (pathname.includes('post-meeting')) setIsStartedScreenShare(false)
    }, [pathname])

    useEffect(() => setContent(diffusion), [diffusion])

    const props: ZoomContextValue = {
        isStartedScreenShare,
        setIsStartedScreenShare,
        currentUserInfo,
        setCurrentUserInfo,
        currentSessionInfo,
        setCurrentSessionInfo,
        isStartingVideo,
        setIsStartingVideo,
        zoomClient,
        sidebarMode,
        setSidebarMode,
        chatClient,
        setChatClient,
        commandChannel,
        setCommandChannel,
        recordingClient,
        setRecordingClient,
        unReadMessages,
        setUnReadMessages,
        loggerClient,
        setLoggerClient,
        isVideoStarted,
        isAudioStarted,
        setIsVideoStarted,
        setIsAudioStarted,
        mediaStream,
        setMediaStream,
        isHost,
        isHostOrManager,
        isRecording,
        setIsRecording,
        confirmationDialog,
        setConfirmationDialog,
        diffusion,
        setDiffusion,
        toggleMuteLocalAudio,
        muteLocalAudio,
        unMuteLocalAudio,
        raisedHands,
        setRaisedHands,
        hasRaisedHands,
        setHasRaisedHands,
        newDiffusionMessage,
        timer,
    }

    return (
        <Context.Provider value={props}>
            {children}
            {pip?.src && <Pip {...pip} />}
        </Context.Provider>
    )
}

export const useZoomContext = () => useContext(Context)

export interface ZoomContextValue {
    isStartedScreenShare: boolean
    setIsStartedScreenShare: Dispatch<SetStateAction<boolean>>
    currentUserInfo?: Participant
    setCurrentUserInfo: Dispatch<SetStateAction<Participant | undefined>>
    currentSessionInfo?: SessionInfo
    setCurrentSessionInfo: Dispatch<SetStateAction<SessionInfo | undefined>>
    isStartingVideo: boolean
    setIsStartingVideo: Dispatch<SetStateAction<boolean>>
    zoomClient: ZoomClient
    sidebarMode: SidebarModeType
    setSidebarMode: Dispatch<SetStateAction<SidebarModeType>>
    chatClient: ChatClient | null
    setChatClient: Dispatch<SetStateAction<ChatClient | null>>
    commandChannel: CommandChannelClient | null
    setCommandChannel: Dispatch<SetStateAction<CommandChannelClient | null>>
    recordingClient: RecordingClient | null
    setRecordingClient: Dispatch<SetStateAction<RecordingClient | null>>
    unReadMessages: UnreadMessageType
    setUnReadMessages: Dispatch<SetStateAction<UnreadMessageType>>
    loggerClient: typeof LoggerClient | null
    setLoggerClient: Dispatch<SetStateAction<typeof LoggerClient | null>>
    isVideoStarted: boolean
    isAudioStarted: boolean
    setIsVideoStarted: Dispatch<SetStateAction<boolean>>
    setIsAudioStarted: Dispatch<SetStateAction<boolean>>
    mediaStream: MediaStream | null
    setMediaStream: Dispatch<SetStateAction<MediaStream | null>>
    isHost: boolean
    isHostOrManager: boolean
    isRecording: boolean
    setIsRecording: Dispatch<SetStateAction<boolean>>
    confirmationDialog: ReactNode | undefined
    setConfirmationDialog: Dispatch<SetStateAction<ReactNode | undefined>>
    diffusion: DiffusionMessage[]
    setDiffusion: Dispatch<SetStateAction<DiffusionMessage[]>>
    toggleMuteLocalAudio: () => Promise<void>
    muteLocalAudio: () => Promise<string | ExecutedFailure> | undefined
    unMuteLocalAudio: () => Promise<string | ExecutedFailure> | undefined
    raisedHands: RaiseHandItem[]
    setRaisedHands: Dispatch<SetStateAction<RaiseHandItem[]>>
    hasRaisedHands: (Participant & { raiseHandOrder: number })[]
    setHasRaisedHands: Dispatch<SetStateAction<(Participant & { raiseHandOrder: number })[]>>
    newDiffusionMessage: ({ timestamp, ...data }: DiffusionMessage) => void
    timer: React.MutableRefObject<NodeJS.Timeout | undefined>
}

export type SidebarModeType = 'messages' | 'widget' | 'participants' | null

export interface MeetingParams {
    topic: string
    zoomToken: string
    widgetToken: string
    name: string
    room?: MeetingRoom
    generalToken?: string
    teamsToken?: string
    _algorithmId?: string
}

export const getContent = () => JSON.parse(sessionStorage.getItem('content') ?? '[]') as DiffusionMessage[]

export const setContent = (contents: DiffusionMessage[]) => sessionStorage.setItem('content', JSON.stringify(contents))

export const addContent = (content: DiffusionMessage) => setContent([...getContent(), content])

type UnreadMessageType = { type: 'chat' | 'diffusion'; status: true } | false

interface RaiseHandItem {
    userId: number
    order: number
}
