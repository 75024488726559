import { useState, useEffect, useCallback, useMemo } from 'react'
import { useOnboarding } from '../../../context/OnboardingContext'

interface TimeInfo {
    remainingTime: number | null
    teamsReadyTime: number | null
    isTimeUp: boolean
}

export const useRemainingTime = (): TimeInfo => {
    const { config } = useOnboarding()
    const [timeInfo, setTimeInfo] = useState<TimeInfo>({
        remainingTime: null,
        teamsReadyTime: null,
        isTimeUp: false,
    })

    // Memoize the calculation of mandatory videos length
    const mandatoryVideosLength = useMemo(() => {
        if (!config) return 0
        return config.dailyContent.onboarding.videos
            .filter(
                video =>
                    (video.metadata.phase === 'pre' || video.metadata.phase === 'main') &&
                    video.metadata.category !== 'icebreaker'
            )
            .reduce((sum, video) => sum + video.length, 0)
    }, [config])

    // Memoize the teams ready time calculation
    const teamsReadyTimeMs = useMemo(() => {
        if (!config) return null
        const {
            courseStartTime,
            timeSettings: { extraTime },
        } = config.onboardingConfig
        const startTimeMs = courseStartTime * 1000
        return startTimeMs + (mandatoryVideosLength + extraTime) * 1000
    }, [config, mandatoryVideosLength])

    // Memoize the calculation function
    const calculateRemainingTime = useCallback(() => {
        if (!config || !teamsReadyTimeMs) return null

        const currentTimeMs = Date.now()
        const remainingSeconds = Math.floor((teamsReadyTimeMs - currentTimeMs) / 1000)

        // Only log every 30 seconds or on significant changes
        if (remainingSeconds % 30 === 0) {
            console.log('Time status:', {
                courseStartTime: new Date(config.onboardingConfig.courseStartTime * 1000).toLocaleString(),
                teamsReadyTime: new Date(teamsReadyTimeMs).toLocaleString(),
                currentTime: new Date(currentTimeMs).toLocaleString(),
                remainingTime: `${Math.floor(remainingSeconds / 60)}:${(remainingSeconds % 60)
                    .toString()
                    .padStart(2, '0')}`,
                mandatoryVideosLength: `${Math.floor(mandatoryVideosLength / 60)}:${(mandatoryVideosLength % 60)
                    .toString()
                    .padStart(2, '0')}`,
                extraTime: `${Math.floor(config.onboardingConfig.timeSettings.extraTime / 60)}:${(
                    config.onboardingConfig.timeSettings.extraTime % 60
                )
                    .toString()
                    .padStart(2, '0')}`,
            })
        }

        return {
            remainingTime: remainingSeconds > 0 ? remainingSeconds : 0,
            teamsReadyTime: teamsReadyTimeMs,
            isTimeUp: remainingSeconds <= 0,
        }
    }, [config, teamsReadyTimeMs, mandatoryVideosLength])

    useEffect(() => {
        // Initial calculation
        setTimeInfo(calculateRemainingTime() || timeInfo)

        // Update every second
        const timer = setInterval(() => {
            setTimeInfo(calculateRemainingTime() || timeInfo)
        }, 1000)

        return () => clearInterval(timer)
    }, [calculateRemainingTime])

    return timeInfo
}
