import { decodeMeetingToken, MeetingRoom } from '../pages/meeting-view/api'
import { getSessionParameters, updateSessionParams } from '../helpers/urls'
import { useNavigate } from 'react-router-dom'
import { useQuery, useQueryClient } from '@tanstack/react-query'

import { getMeetingData } from '../apis/api-hca'
import { useCallback, useState } from 'react'
import { useSessionParams } from './useSessionParams'
import { useUI } from '../context/UIContext'
import { useTranslation } from 'react-i18next'
import ToastNotification from '../pages/meeting-view/components/Misc/ToastNotification'
import { LinkBtnNotification } from '../components/Buttons'
import { setSessionItem } from '../helpers/localStorage'
import { useZoomContext } from '../context/ZoomContext'
import { error } from 'console'

export const useChangeMeeting = () => {
    const { showToast } = useUI()
    const navigate = useNavigate()
    const queryClient = useQueryClient()
    const { t } = useTranslation('notifications')
    const { timer } = useZoomContext()
    const [roomToSwitch, setRoomToSwitch] = useState<MeetingRoom | null>(null)

    const changeMeeting = useCallback(
        async (newRoom: MeetingRoom, { immediate = false }: Options = {}) => {
            console.log('Runs changeMeeting')
            queryClient.invalidateQueries({ queryKey: ['MeetingData'] })
            const data = await getMeetingData(getSessionParameters()._algorithmId)
            const meeting = data[`${newRoom}_room`]

            const errorNotification = (
                <ToastNotification>
                    <div>
                        {t('error.switchMeetError')}
                        <LinkBtnNotification onClick={() => changeMeeting(roomToSwitch || 'general')}>
                            {t('switchMeeting.goNow')}
                        </LinkBtnNotification>
                    </div>
                </ToastNotification>
            )

            if (!meeting) return showToast.error(errorNotification, { duration: Infinity, id: 'switch-meeting-error' })

            const { token, url } = meeting
            if (!token && !url)
                return showToast.error(errorNotification, { duration: Infinity, id: 'switch-meeting-error' })

            // Always update session params
            updateSessionParams({ room: newRoom })

            if (token) {
                const { room, tpc: topic } = decodeMeetingToken(token)
                updateSessionParams({ room, topic })
            }

            queryClient.invalidateQueries({ queryKey: ['Team', 'Timeline'] })
            showToast.dismiss('switch-meeting-error')

            if (!immediate) {
                navigate('/post-meeting/moving')
                await new Promise(resolve => setTimeout(resolve, 2000))
            }

            // Add a small delay before navigation
            await new Promise(resolve => setTimeout(resolve, 100))

            // eslint-disable-next-line no-restricted-globals
            if (url?.startsWith(location.origin) || url?.startsWith('https://develop.')) {
                const { pathname, search } = new URL(url)
                navigate(pathname + search)
            } else {
                navigate('/post-meeting/external')
                window.open(url, 'external-meeting')
            }
        },
        [navigate, queryClient, roomToSwitch, showToast, t]
    )

    const manuallySwitch = (room: MeetingRoom) => {
        clearTimeout(timer.current)
        setSessionItem('manually-switched', true)
        changeMeeting(room)
    }

    //@ts-ignore
    window.changeMeeting = changeMeeting

    return { changeMeeting, manuallySwitch, setRoomToSwitch }
}

export const useMeetingData = () => {
    const { role } = useSessionParams()
    const queryKey = ['Meeting Data']

    const { data, ...rest } = useQuery({
        queryKey,
        queryFn: () => getMeetingData(getSessionParameters()._algorithmId),
        staleTime: 1000 * 60 * 60 * 3, // 3 hours
        enabled: role === 'student',
    })

    return { ...data, ...rest }
}

interface Options {
    immediate?: boolean
}
