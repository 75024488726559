import { AudioLevel } from './AudioLevel'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { MediaBtnContainer } from './Buttons'
import { StyledDropdownOptions } from '../../meeting-view/components/Misc/StyledDropdownOptions'
import Styles from './PreMeetingAudioControls.module.css'
import { Tip } from '../../../components/Tip'
import { useMyAudio } from '../hooks/useMyAudio'
import { usePreMeetingContext } from '../../../context/PreMeetingContext'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { usePermissions } from '../../../context/PermissionsContext'

export const PreMeetingAudioControls = () => {
    const { microphones, switchMicrophone, toggleMute } = useMyAudio()
    const { activeMic, activeSpeaker, isAudioMuted } = usePreMeetingContext()
    const [isOpen, setIsOpen] = useState(false)
    const { t } = useTranslation('premeeting')
    const { microphone, camera } = usePermissions()
    const permissionGranted = microphone && camera

    return (
        <>
            <MediaBtnContainer active={isAudioMuted} accent disabled={!permissionGranted}>
                <div onClick={toggleMute}>
                    {isAudioMuted ? (
                        <FontAwesomeIcon icon={['far', 'microphone-slash']} fixedWidth />
                    ) : (
                        <FontAwesomeIcon icon={['far', 'microphone']} fixedWidth />
                    )}
                </div>
                <div className={`anchor ${Styles.chevron}`} id="mic-pre-tooltip">
                    <FontAwesomeIcon icon={['fas', 'chevron-up']} flip={isOpen ? 'vertical' : undefined} fixedWidth />
                </div>
            </MediaBtnContainer>
            {permissionGranted && (
                <Tip
                    id="mic-pre-tooltip-ele"
                    anchorSelect="#mic-pre-tooltip"
                    positionStrategy="fixed"
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    clickable
                    globalCloseEvents={{ escape: true, clickOutsideAnchor: true }}
                    role="dialog"
                    className="wide-tooltip"
                >
                    <div className={Styles.tooltip}>
                        <StyledDropdownOptions>
                            <div className="devices-group">
                                <h3 className="label">{t('audioControls.microphones')}</h3>
                                {microphones.map(({ deviceId, label }) => {
                                    const isSelected = activeMic?.deviceId === deviceId
                                    return (
                                        <li key={deviceId} className={isSelected ? Styles.selected : undefined}>
                                            <button
                                                className={isSelected ? 'active' : ''}
                                                onClick={() => switchMicrophone(deviceId)}
                                            >
                                                {label}
                                            </button>
                                        </li>
                                    )
                                })}
                            </div>
                            <div className="devices-group">
                                <h3 className="label">{t('audioControls.selectedSpeaker')}</h3>
                                <li className={`${Styles.selected} non-clickable`}>
                                    <button className="active">{activeSpeaker?.label}</button>
                                </li>
                            </div>
                            <div className="devices-group">
                                <h3 className="label">{t('audioControls.audioMonitor')}</h3>
                                <AudioLevel />
                            </div>
                        </StyledDropdownOptions>
                    </div>
                </Tip>
            )}
        </>
    )
}
