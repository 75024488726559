import { Bolt } from '../Bolt'
import { Participant } from '../../pages/meeting-view/types/video-types'
import { ToastOptions } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'

export const PulseNotification = ({ me, giver, receiver }: PulseProps & { me?: Participant }) => {
    const { t } = useTranslation('pulses')

    return me?.userIdentity === receiver.profileId
        ? t('You received a Pulse of {{ displayName }}', { ...giver })
        : t('{{ displayName }} received a Pulse', { ...receiver })
}

export const PulseOptions: ToastOptions = {
    icon: <Bolt />,
    style: {
        background: 'linear-gradient(172deg, rgb(255, 100, 124) 12%, rgb(0, 163, 255) 98.91%)',
        border: 0,
    },
}

export interface PulseProps {
    giver: UserPulse
    receiver: UserPulse
}

interface UserPulse {
    profileId: string
    displayName: string
    urlImage?: string
}
