import { useState, useCallback, useEffect } from 'react'
import { Participant } from '../pages/meeting-view/types/video-types'
import { useZoomContext } from '../context/ZoomContext'

const MAX_PARTICIPANTS = 1000

const useParticipants = () => {
    const { zoomClient, hasRaisedHands, setHasRaisedHands } = useZoomContext()
    const [participants, setParticipants] = useState<Participant[]>([])
    const [collaborators, setCollaborators] = useState<Participant[]>([])
    const [totalUserCount, setTotalUserCount] = useState<number>(0)

    const isCollaborator = (participant: Participant) => participant.isHost || participant.isManager

    const updateCollaborators = useCallback((updatedParticipants: Participant[]) => {
        setCollaborators(updatedParticipants.filter(isCollaborator))
    }, [])

    const updateParticipants = useCallback(
        (updatedParticipants: Partial<Participant> | Partial<Participant>[]) => {
            setParticipants(prev => {
                const updated = Array.isArray(updatedParticipants) ? updatedParticipants : [updatedParticipants]
                const newParticipants = prev.map(p => {
                    const update = updated.find(u => u.userId === p.userId)
                    if (update) {
                        return { ...p, ...update }
                    }
                    return p
                })

                // Always update collaborators
                const updatedCollaborators = newParticipants.filter(isCollaborator)

                // For non-collaborators, only update if they're already in the list or if we haven't reached MAX_PARTICIPANTS
                const updatedNonCollaborators = newParticipants
                    .filter(p => !isCollaborator(p))
                    .slice(0, MAX_PARTICIPANTS - updatedCollaborators.length)

                const finalParticipants = [...updatedCollaborators, ...updatedNonCollaborators]
                updateCollaborators(finalParticipants)
                return finalParticipants
            })
        },
        [updateCollaborators]
    )

    const addParticipants = useCallback(
        (newParticipants: Participant | Participant[]) => {
            setParticipants(prev => {
                const toAdd = Array.isArray(newParticipants) ? newParticipants : [newParticipants]

                // Always add collaborators
                const newCollaborators = toAdd.filter(isCollaborator)

                // For non-collaborators, only add if we haven't reached MAX_PARTICIPANTS
                const availableSlots = MAX_PARTICIPANTS - prev.length
                const newNonCollaborators = toAdd.filter(p => !isCollaborator(p)).slice(0, Math.max(0, availableSlots))

                const updatedParticipants = [...prev, ...newCollaborators, ...newNonCollaborators]

                updateCollaborators(updatedParticipants)
                return updatedParticipants
            })
            setTotalUserCount(prevCount => prevCount + (Array.isArray(newParticipants) ? newParticipants.length : 1))
        },
        [updateCollaborators]
    )

    const removeParticipants = useCallback(
        (removedParticipants: Participant | Participant[]) => {
            setParticipants(prev => {
                const toRemove = Array.isArray(removedParticipants) ? removedParticipants : [removedParticipants]
                const updatedParticipants = prev.filter(p => !toRemove.some(removed => removed.userId === p.userId))
                updateCollaborators(updatedParticipants)
                return updatedParticipants
            })
            setTotalUserCount(
                prevCount => prevCount - (Array.isArray(removedParticipants) ? removedParticipants.length : 1)
            )
        },
        [updateCollaborators]
    )

    useEffect(() => {
        if (!zoomClient) return
        const initialParticipants = zoomClient.getAllUser()
        setParticipants(initialParticipants.slice(0, MAX_PARTICIPANTS))
        setTotalUserCount(initialParticipants.length)
        updateCollaborators(initialParticipants)

        zoomClient.on('user-added', addParticipants)
        zoomClient.on('user-removed', removeParticipants)
        zoomClient.on('user-updated', updateParticipants)

        return () => {
            zoomClient.off('user-added', addParticipants)
            zoomClient.off('user-removed', removeParticipants)
            zoomClient.off('user-updated', updateParticipants)
        }
    }, [zoomClient, addParticipants, removeParticipants, updateParticipants, updateCollaborators])

    return {
        participants,
        setParticipants,
        collaborators,
        hasRaisedHands,
        totalUserCount,
        isCollaborator,
        setHasRaisedHands,
    }
}

export default useParticipants
