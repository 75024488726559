import { useCallback, useEffect, useState } from 'react'

import { AudioChangeAction } from '@zoom/videosdk'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { MediaBtnContainer } from '../../../pre-meeting/components/Buttons'
import { MediaDevice } from '../../types/video-types'
import { StyledDropdownOptions } from '../Misc/StyledDropdownOptions'
import { Tip } from '../../../../components/Tip'
import { getItem } from '../../../../helpers/localStorage'
import { useTimeline } from '../../../../hooks/useTimeline'
import { useUI } from '../../../../context/UIContext'
import { useZoomContext } from '../../../../context/ZoomContext'
import { useTranslation } from 'react-i18next'
import CloseFloatingBtn from '../../../../components/CloseFloatingBtn'
import { getDeviceType } from '../../util/platform'

export const Microphone = () => {
    const { t } = useTranslation('meeting')
    const [activeMicrophone, setActiveMicrophone] = useState<MediaDevice | undefined>(undefined)
    const [mics, setMics] = useState<MediaDevice[] | undefined>(undefined)
    const { mediaStream, zoomClient, isAudioStarted, setIsAudioStarted } = useZoomContext()
    const { showToast } = useUI()
    const { currentEvent, isLoading } = useTimeline()
    const [isMuted, setIsMuted] = useState(true)
    const [audioEnabled, setAudioEnabled] = useState(false)
    const type: 'mobile' | 'desktop' = getDeviceType()

    const [isOpen, setIsOpen] = useState(false)

    const getMicList = useCallback(async () => {
        if (!mediaStream) return
        const micList = mediaStream.getMicList()
        const lastMic = getItem('last-mic-id') as MediaDevice | null
        const mic = micList.find(mic => mic.deviceId === lastMic?.deviceId)
        setMics(micList)
        setActiveMicrophone(mic || micList[0])
    }, [mediaStream])

    useEffect(() => {
        if (!mediaStream) return
        getMicList()
    }, [getMicList, mediaStream])

    useEffect(() => {
        zoomClient.on('device-change', getMicList)
        return () => {
            zoomClient.off('device-change', getMicList)
        }
    }, [getMicList, zoomClient])

    const handleChevronClick = (e: React.MouseEvent) => {
        e.preventDefault()
        e.stopPropagation()
        setIsOpen(() => !isOpen)
    }

    const switchMic = async (device: MediaDevice) => {
        if (!mediaStream) return
        const { deviceId, label } = device
        if (deviceId !== activeMicrophone?.deviceId) {
            await mediaStream.switchMicrophone(deviceId)
            setActiveMicrophone(device)
            showToast(t('microphone.selectedMicToast', { label }))
        }
    }

    const isAudioEnable = useCallback(() => {
        if (isLoading) return false
        if (zoomClient.isHost() || zoomClient.isManager()) return true
        if (currentEvent?.room === 'teams') return true
        return currentEvent?.settings.enabledMic !== false
    }, [currentEvent?.room, currentEvent?.settings.enabledMic, zoomClient, isLoading])

    useEffect(() => {
        setAudioEnabled(isAudioEnable())
    }, [isAudioEnable])

    const onMicrophoneClick = useCallback(async () => {
        if (isAudioStarted) {
            if (isMuted) {
                await mediaStream?.unmuteAudio()
                setIsMuted(false)
            } else {
                await mediaStream?.muteAudio()
                setIsMuted(true)
            }
        } else {
            await mediaStream?.startAudio({ mute: true, backgroundNoiseSuppression: true }).catch(error => {
                console.log('Error starting audio', error)
            })
            // await mediaStream?.muteAudio()
            setIsMuted(true)
            setIsAudioStarted(true)
        }
    }, [isAudioStarted, isMuted, mediaStream, setIsMuted, setIsAudioStarted])

    const onHostAudioMuted = useCallback(
        (payload: any) => {
            const { action } = payload
            if (action === AudioChangeAction.Join) {
                setIsAudioStarted(true)
            } else if (action === AudioChangeAction.Leave) {
                setIsAudioStarted(false)
            } else if (action === AudioChangeAction.Muted) {
                setIsMuted(true)
            } else if (action === AudioChangeAction.Unmuted) {
                setIsMuted(false)
            }
        },
        [setIsMuted, setIsAudioStarted]
    )

    useEffect(() => {
        const initMuted = getItem('pre-mute-state') as boolean | null
        if (initMuted !== null) {
            setIsMuted(initMuted)
        }
    }, [])

    useEffect(() => {
        zoomClient.on('current-audio-change', onHostAudioMuted)
        return () => {
            zoomClient.off('current-audio-change', onHostAudioMuted)
        }
    }, [zoomClient, onHostAudioMuted])

    return (
        <div>
            <MediaBtnContainer active={isMuted} accent as="div" pulse={!isAudioStarted}>
                <button
                    data-tooltip-id="mic-pre-action"
                    onClick={e => {
                        e.stopPropagation()
                        onMicrophoneClick()
                    }}
                    disabled={!audioEnabled}
                >
                    {isAudioStarted ? (
                        isMuted ? (
                            <FontAwesomeIcon icon={['far', 'microphone-slash']} fixedWidth />
                        ) : (
                            <FontAwesomeIcon icon={['far', 'microphone']} fixedWidth />
                        )
                    ) : (
                        <FontAwesomeIcon icon={['far', 'volume-slash']} fixedWidth />
                    )}
                </button>
                {type === 'desktop' && (
                    <button id="mic-pre-tooltip" onClick={handleChevronClick}>
                        <FontAwesomeIcon
                            icon={['fas', 'chevron-up']}
                            flip={isOpen ? 'vertical' : undefined}
                            fixedWidth
                        />
                    </button>
                )}
            </MediaBtnContainer>

            <Tip
                id="mic-pre-action"
                content={
                    isAudioStarted
                        ? t(isMuted ? 'microphone.unmuteMicrophone' : 'microphone.muteMicrophone')
                        : t('microphone.startAudio')
                }
            />

            <Tip
                id="mic-pre-tooltip-ele"
                anchorSelect="#mic-pre-tooltip"
                isOpen={isOpen}
                setIsOpen={() => setIsOpen(prev => !prev)}
                globalCloseEvents={{ escape: true, clickOutsideAnchor: true }}
                imperativeModeOnly={true}
                clickable
                role="dialog"
                className="wide-tooltip"
                openOnClick={true}
            >
                <div>
                    <StyledDropdownOptions>
                        <div className="devices-group">
                            <h3 className="label">{t('microphone.microphones')}</h3>
                            {mics?.map(device => {
                                const { deviceId, label } = device
                                const isSelected = activeMicrophone?.deviceId === deviceId
                                return (
                                    <li key={deviceId}>
                                        <button
                                            className={isSelected ? 'active' : ''}
                                            onClick={() => switchMic(device)}
                                        >
                                            {label}
                                        </button>
                                    </li>
                                )
                            })}
                        </div>
                        <CloseFloatingBtn onClose={() => setIsOpen(false)} />
                    </StyledDropdownOptions>
                </div>
            </Tip>
        </div>
    )
}
