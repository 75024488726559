import { ConfirmationDialogStyled } from './ConfirmationDialog'
import { PrimaryBtnOutline } from '../Buttons'
import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'
import chrome from '../../assets/images/chrome.png'
import edge from '../../assets/images/edge.png'

export const PermissionModal = () => {
    const { t } = useTranslation('premeeting')
    const browserInfo = useMemo(() => {
        const userAgent = navigator.userAgent.toLowerCase()
        const isEdge = /edg/.test(userAgent)
        return {
            image: isEdge ? edge : chrome,
            helpUrl: isEdge
                ? 'https://support.microsoft.com/en-us/microsoft-edge/camera-and-microphone-permissions-in-microsoft-edge-9c7fb3c4-f19f-4f73-9656-0f182b86dee'
                : 'https://support.google.com/chrome/answer/2693767?hl=es',
            browserName: isEdge ? 'Microsoft Edge' : 'Google Chrome',
        }
    }, [])

    return (
        <ConfirmationDialogStyled>
            <div className="info">
                <h2>{t('permissionModal.title')}</h2>
                <div>
                    <p>{t('permissionModal.insufficientPermissions')}</p>
                    <p>{t('permissionModal.allowAccessAndReload')}</p>
                </div>
            </div>
            <div className="actions">
                <PrimaryBtnOutline onClick={() => window.location.reload()} colorTheme={'light'}>
                    {t('permissionModal.rejoinButton')}
                </PrimaryBtnOutline>
            </div>
            <div className="link">
                <a href={browserInfo.helpUrl} target="_blank" rel="noopener noreferrer">
                    {t('permissionsModal.moreInfoLink')}
                </a>
            </div>
        </ConfirmationDialogStyled>
    )
}
