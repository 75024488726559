import EggLogo from '../../assets/egg_logo.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { LoadingBolt } from '../../components/LoadingBolt'
import { MediaBtnContainer } from './components/Buttons'
import { MyVideo } from './components/MyVideo'
import { PreMeetingAudioControls } from './components/PreMeetingAudioControls'
import { PreMeetingCameraControls } from './components/PreMeetingCameraControls'
import Styles from './PreMeeting.module.css'
import { persistSessionParameters } from '../../helpers/urls'
import { useMyAudio } from './hooks/useMyAudio'
import { useTranslation } from 'react-i18next'
import { useZoomContext } from '../../context/ZoomContext'
import { useMe } from '../meeting-view/hooks/useMe'
import { useUserCourses } from '../meeting-view/hooks/useCourses'
import * as Sentry from '@sentry/react'
import { capitalizeAll } from '../../helpers/formatters'
import { LanguageSelector } from '../../components/LanguageSelector'
import { SentryFeedbackButton } from '../meeting-view/components/Dev/SentryFeedbackButton'
import NoPermission from './components/NoPermission'
import { usePermissions } from '../../context/PermissionsContext'
import DevicesBusy from './components/DevicesBusy'
import { useNavigate } from 'react-router-dom'
import DevicesNotFound from './components/DevicesNotFound'

export const PreMeeting = () => {
    persistSessionParameters()
    useZoomContext()
    const { user } = useMe()
    const { currentCourse } = useUserCourses()
    const navigate = useNavigate()
    user && Sentry.setUser({ email: user.email, id: user.id, username: user.displayNameOrName })
    const { camera, microphone, checking, devicesBusy, devicesNotFound } = usePermissions()
    const permissionGranted = camera && microphone
    useMyAudio()
    const { t } = useTranslation('premeeting')

    if (checking) return <LoadingBolt />

    return (
        <div className={Styles.container}>
            <section className={Styles.section}>
                <div className={Styles.vendorContainer} style={{ paddingBottom: '10px' }}>
                    <img src={EggLogo} alt="Egg Logo" height={'50px'} />
                </div>
                <h1>
                    {currentCourse
                        ? `${capitalizeAll(currentCourse?.courseName) ?? ''} / ${
                              capitalizeAll(user?.organizationName) ?? ''
                          }`
                        : t('join-title')}
                </h1>
                <div className={Styles.preview}>
                    {devicesBusy && <DevicesBusy />}
                    {devicesNotFound && <DevicesNotFound />}
                    {!devicesBusy && !devicesNotFound && !permissionGranted && <NoPermission />}
                    {!devicesBusy && !devicesNotFound && permissionGranted && <MyVideo />}
                </div>
                <nav>
                    <PreMeetingAudioControls />
                    <PreMeetingCameraControls />
                    <MediaBtnContainer
                        active={true}
                        accent={true}
                        wide={true}
                        disabled={!permissionGranted}
                        className="wide accent"
                        onClick={() => {
                            navigate('/meeting')
                            sessionStorage.setItem('isPreMeetingDone', 'true')
                        }}
                        style={{ fontWeight: 'bold' }}
                        id="join-meeting-btn"
                    >
                        <FontAwesomeIcon
                            className={'base-icon'}
                            icon={['fas', 'circle-video']}
                            fixedWidth
                            style={{ marginRight: '5px' }}
                        />
                        <span>{t('Live')}</span>
                    </MediaBtnContainer>
                </nav>
            </section>
            <LanguageSelector label="" />
            <SentryFeedbackButton />
        </div>
    )
}
