import './video.scss'

import { VideoContainer, Viewport } from './Viewport'
import { useEffect, useRef, useState } from 'react'

import { MediaContext } from '../../types/video-types'
import Pagination from '../Pagination/Pagination'
import UserVideo from '../../../../components/UserVideo/UserVideo'
import _ from 'lodash'
import classnames from 'classnames'
import { isShallowEqual } from '../../util/util'
import { useActiveSpeaker } from '../../hooks/useActiveVideo'
import { useAutoStartMedia } from '../../hooks/useAutoStartMedia'
import { useCanvasDimension } from '../../hooks/useCanvasDimension'
import { useGalleryLayout } from '../../hooks/useGalleryLayout'
import { usePagination } from '../../hooks/usePagination'
import { useShare } from '../../hooks/useShare'
import { useZoomContext } from '../../../../context/ZoomContext'

type Props = {
    selfShareRef: any
    mediaContext: MediaContext
}

const VideoSAB = ({ selfShareRef, mediaContext }: Props) => {
    const {
        video: { decode: isVideoDecodeReady },
    } = mediaContext

    const { mediaStream, zoomClient } = useZoomContext()
    useAutoStartMedia({ zoomClient, mediaStream })
    const videoRef = useRef<HTMLCanvasElement | null>(null)
    const shareRef = useRef<HTMLCanvasElement | null>(null)
    const shareContainerRef = useRef<HTMLDivElement | null>(null)
    const [containerDimension, setContainerDimension] = useState({
        width: 0,
        height: 0,
    })
    const [shareViewDimension, setShareViewDimension] = useState({
        width: 0,
        height: 0,
    })
    const canvasDimension = useCanvasDimension(mediaStream, videoRef)
    const activeSpeakerId = useActiveSpeaker(zoomClient)

    const { page, pageSize, totalPage, totalSize, setPage } = usePagination(zoomClient, canvasDimension)
    const { isRecieveSharing, isStartedShare, sharedContentDimension } = useShare(zoomClient, mediaStream, shareRef)

    const { visibleParticipants, layout: videoLayout } = useGalleryLayout(
        videoRef,
        canvasDimension,
        isVideoDecodeReady,
        {
            page,
            pageSize,
            totalPage,
            totalSize,
            setPage,
        },
        activeSpeakerId
    )

    const isSharing = isRecieveSharing || isStartedShare

    useEffect(() => {
        const currentRef = shareContainerRef.current
        if (currentRef && isSharing) {
            const resizeObserver = new ResizeObserver(
                _.debounce(() => {
                    const { width, height } = currentRef.getBoundingClientRect()
                    setContainerDimension({ width, height })
                }, 100)
            )

            resizeObserver.observe(currentRef)
            return () => resizeObserver.disconnect()
        }
    }, [isSharing])

    useEffect(() => {
        if (isSharing && shareContainerRef.current) {
            const { width, height } = sharedContentDimension
            const { width: containerWidth, height: containerHeight } = containerDimension
            const ratio = Math.min(containerWidth / width, containerHeight / height, 1)
            // Add validation
            const newWidth = Math.max(1, Math.floor(width * ratio))
            const newHeight = Math.max(1, Math.floor(height * ratio))
            setShareViewDimension({
                width: newWidth,
                height: newHeight,
            })
        }
    }, [isSharing, sharedContentDimension, containerDimension])

    useEffect(() => {
        if (!isShallowEqual(shareViewDimension, sharedContentDimension)) {
            mediaStream?.updateSharingCanvasDimension(shareViewDimension.width, shareViewDimension.height)
        }
    }, [mediaStream, sharedContentDimension, shareViewDimension])

    return (
        <VideoContainer>
            <Viewport>
                <div className={`share-container ${isSharing ? 'in-sharing' : ''}`} ref={shareContainerRef}>
                    <div
                        className="share-container-viewport"
                        style={{
                            width: `${shareViewDimension.width}px`,
                            height: `${shareViewDimension.height}px`,
                        }}
                    >
                        <div style={{ display: isStartedShare ? 'none' : 'block' }}>
                            <canvas className={`share-canvas ${isStartedShare ? 'hidden' : ''}`} ref={shareRef} />
                        </div>
                        {mediaStream?.isStartShareScreenWithVideoElement() ? (
                            <video
                                className={classnames('share-canvas', {
                                    hidden: isRecieveSharing,
                                })}
                                ref={selfShareRef}
                            />
                        ) : (
                            <canvas
                                className={classnames('share-canvas', {
                                    hidden: isRecieveSharing,
                                })}
                                ref={selfShareRef}
                            />
                        )}
                    </div>
                </div>
                <div className={classnames('video-container', { 'in-sharing': isSharing })}>
                    <canvas className="video-canvas" id="video-canvas" width="1280" height="720" ref={videoRef} />
                    <ul className="avatar-list">
                        {visibleParticipants.map((user, index) => {
                            if (index > videoLayout.length - 1) return null
                            const dimension = videoLayout[index]
                            const { width, height, x, y } = dimension
                            const { height: canvasHeight } = canvasDimension
                            return (
                                <UserVideo
                                    key={user.userId}
                                    {...user}
                                    style={{
                                        width: `${width - 10}px`,
                                        height: `${height}px`,
                                        top: `${canvasHeight - y - height}px`,
                                        left: `${x + 5}px`,
                                        borderRadius: '5px',
                                    }}
                                />
                            )
                        })}
                    </ul>
                </div>
                {totalPage > 1 && (
                    <Pagination page={page} totalPage={totalPage} setPage={setPage} inSharing={isSharing} />
                )}
            </Viewport>
        </VideoContainer>
    )
}

export default VideoSAB
