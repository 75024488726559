import { GivePulse } from '../Pulse/GivePulse'
import { Participant } from '@zoom/videosdk'
import { RaisedHand } from '../RaisedHand'
import { UserMic } from './UserMic'

export const UserTeams = (props: Participant) => (
    <div className="user">
        <div className="top-left">
            <UserMic {...props} />
            <span>{props.displayName}</span>
        </div>
        <div className="top-right">
            <RaisedHand {...props} />
            <GivePulse {...props} />
        </div>
    </div>
)
