import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { MediaBtnContainer } from '../../../pre-meeting/components/Buttons'
import { Tip } from '../../../../components/Tip'
import { useSessionParams } from '../../../../hooks/useSessionParams'
import { useTranslation } from 'react-i18next'
import ConfirmationDialog from '../../../../components/Modals/ConfirmationDialog'
import HelpRequestModal from '../../../../components/Modals/HelpRequestModal'
import { useState } from 'react'
import { useZoomContext } from '../../../../context/ZoomContext'

export const HelpRequest = () => {
    const { t } = useTranslation('meeting')
    const { role, room } = useSessionParams()
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [isHelpActive, setIsHelpActive] = useState(false)
    const { commandChannel } = useZoomContext()

    if (role !== 'student' || room !== 'teams') return null

    const handleToggleHelp = () => {
        if (!isHelpActive) {
            setIsModalOpen(true)
        } else {
            setIsHelpActive(false)
        }
    }

    const handleRequestComplete = () => {
        setIsModalOpen(false)
        setIsHelpActive(true)
    }

    return (
        <div className="flex">
            <MediaBtnContainer
                active={isHelpActive}
                square
                data-tooltip-id="help-request-tooltip"
                onClick={handleToggleHelp}
            >
                <FontAwesomeIcon icon={['far', 'user-headset']} />
            </MediaBtnContainer>

            <Tip
                id="help-request-tooltip"
                content={t(isHelpActive ? 'helpRequest.tooltip.active' : 'helpRequest.tooltip.inactive')}
            />

            {isModalOpen && (
                <ConfirmationDialog
                    modal={
                        <HelpRequestModal
                            isActive={isHelpActive}
                            onClose={() => setIsModalOpen(false)}
                            onComplete={handleRequestComplete}
                            commandChannel={commandChannel}
                        />
                    }
                    isOpen={true}
                />
            )}
        </div>
    )
}
