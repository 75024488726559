import { PrimaryBtnOutline } from '../../../../components/Buttons'
import SquareButton from '../../../../components/SquareButton'
import { signInToAdmin } from '../../../../apis/api-egg'
import styled from 'styled-components'
import { useHostFunctions } from '../../hooks/useHostFunctions'
import { useSessionParams } from '../../../../hooks/useSessionParams'
import { useMemo, useState } from 'react'
import { useTimeline } from '../../../../hooks/useTimeline'
import { useZoomContext } from '../../../../context/ZoomContext'
import { useTranslation } from 'react-i18next'
import useParticipants from '../../../../hooks/useParticipants'
import ExpandableBlock from './ExpandableBlock'

const Participants = () => {
    const { t } = useTranslation('sidebar')
    const { enableMic, disableMic } = useHostFunctions()
    const { isHostOrManager } = useZoomContext()
    const { participants, collaborators, hasRaisedHands } = useParticipants()
    const { _id: userId, _classId, room } = useSessionParams()
    const { currentEvent } = useTimeline()
    const { settings } = currentEvent || {}
    const { enabledMic } = settings || {}
    const [searchQuery, setSearchQuery] = useState('')

    const filteredParticipants = useMemo(() => {
        if (searchQuery === '') return []
        return participants.filter(({ displayName }) => displayName.toLowerCase().includes(searchQuery.toLowerCase()))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchQuery])

    const noResultsMessage =
        searchQuery !== '' && filteredParticipants.length === 0 ? t('participants.searchNoResult') : null

    return (
        <ParticipantsStyled>
            <div className="top">
                <div className="search">
                    <SquareButton disabled icon={['far', 'magnifying-glass']} dark />
                    <input
                        type="text"
                        onChange={({ target: { value } }) => setSearchQuery(value)}
                        placeholder={t('participants.searchPlaceholder')}
                    />
                </div>
                <div className="participants">
                    <ExpandableBlock
                        title={t('participants.searchResults')}
                        participants={filteredParticipants}
                        initiallyExpanded={true}
                        noResultsMessage={noResultsMessage}
                        isExpandable={false}
                        isSearchBlock
                        searchQuery={searchQuery}
                    />
                    {hasRaisedHands.length > 0 && (
                        <ExpandableBlock
                            title={t('participants.raisedHands')}
                            participants={hasRaisedHands}
                            initiallyExpanded={true}
                        />
                    )}
                    {collaborators.length > 0 && (
                        <ExpandableBlock
                            title={t('participants.collaborators')}
                            participants={collaborators}
                            initiallyExpanded={true}
                        />
                    )}
                    {room === 'teams' && <ExpandableBlock title={t('participants.rest')} participants={participants} />}
                </div>
            </div>
            {isHostOrManager && (
                <div className="bottom">
                    <PrimaryBtnOutline colorTheme="light" fz="sm" onClick={enabledMic ? disableMic : enableMic}>
                        {enabledMic ? t('participants.muteAll') : t('participants.enableMicrophones')}
                    </PrimaryBtnOutline>
                    <PrimaryBtnOutline
                        colorTheme="light"
                        fz="sm"
                        className="manage-meetings"
                        onClick={() => userId && _classId && signInToAdmin(userId, _classId)}
                    >
                        {t('participants.manageMeetings')}
                    </PrimaryBtnOutline>
                </div>
            )}
        </ParticipantsStyled>
    )
}

export default Participants

const ParticipantsStyled = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    /* padding: 30px; */
    box-sizing: border-box;
    .participants {
        display: flex;
        flex-direction: column;
        height: auto;
        gap: 10px;
    }
    .top {
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow-y: scroll;
        &::-webkit-scrollbar {
            width: 0; /* Hide the scrollbar */
            background: transparent; /* Optionally, you can set it to transparent */
        }
        .search {
            display: grid;
            grid-template-columns: 44px auto;
            margin-bottom: 20px;
            grid-gap: 3px;
            button {
                margin-left: 0;
            }
            input {
                width: 100%;
                border-radius: 12px;
                border: none;
                background-color: ${props => props.theme.color.sidebar.gray1};
                border: ${props => props.theme.color.sidebar.gray2};
                color: white;
                height: 40px;
                padding: 0 20px;
                box-sizing: border-box;
                margin: 4px 0;
                outline-style: solid;
                outline-color: #444444;
            }
        }
    }
    .bottom {
        flex: 1;
        display: flex;
        padding: 20px 0 !important;
        padding-bottom: 0 !important;
        gap: 10px;
    }
`
