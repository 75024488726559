import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Participant } from '@zoom/videosdk'

export const UserMic = ({ audio, muted }: Pick<Participant, 'audio' | 'muted'>) =>
    audio === 'computer' &&
    muted && (
        <FontAwesomeIcon
            icon={['far', 'microphone-slash']}
            style={{ color: '#FF647C', textShadow: '0px 2px 3px #202124' }}
        />
    )
