import { useCallback, useEffect } from 'react'
import { CommandChannelMsg } from '@zoom/videosdk'
import { useUI } from '../../../context/UIContext'
import { useZoomContext } from '../../../context/ZoomContext'
import { useTranslation } from 'react-i18next'
import useRaisedHands from '../../../hooks/useRaisedHands'

const useCommandChannel = () => {
    const { t } = useTranslation('notifications')
    const { showToast } = useUI()
    const { zoomClient, currentUserInfo, raisedHands } = useZoomContext()
    const { toggleRaiseHand } = useRaisedHands()

    const handleTextMsg = useCallback(
        (payload: CommandChannelMsg) => {
            try {
                const data = JSON.parse(payload.text)
                // Handle JSON messages
                switch (data.type) {
                    case 'disableMic':
                        if (!zoomClient.isManager())
                            showToast(t('commandChannel.hostMutedParticipants'), { id: 'disableMic' })
                        break
                    case 'enableMic':
                        if (!zoomClient.isManager())
                            showToast(t('commandChannel.hostEnabledMicrophones'), { id: 'enableMic' })
                        break
                    case 'toggle-raise-hand':
                        const { userId, isHandRaised, options } = data
                        if (!isHandRaised && !raisedHands.some(item => item.userId === userId)) return // Avoid duplicates
                        if (userId !== currentUserInfo?.userId || options.force)
                            toggleRaiseHand(userId, { notify: false, ...options })
                        break
                    case 'help-request':
                        // Don't show toast to the user who requested help
                        if (payload.senderId !== currentUserInfo?.userId) {
                            const teamText = data.teamNumber ? ` (Team ${data.teamNumber})` : ''
                            showToast(t('commandChannel.helpRequested', { team: teamText }), {
                                id: `help-request-${data.timestamp}`,
                                duration: 5000, // Show for 5 seconds
                            })
                        }
                        break
                    default:
                        return console.warn('Unknown command channel message', data)
                }
            } catch (error) {}
        },
        [currentUserInfo?.userId, raisedHands, showToast, toggleRaiseHand, zoomClient, t]
    )

    useEffect(() => {
        zoomClient.on('command-channel-message', handleTextMsg)
        return () => {
            zoomClient.off('command-channel-message', handleTextMsg)
        }
    }, [zoomClient, handleTextMsg])

    return {
        handleTextMsg,
    }
}

export default useCommandChannel
