import toast, { Toaster, useToasterStore } from 'react-hot-toast'

import { useEffect } from 'react'

const TOAST_LIMIT = 5

export const AppToaster = () => {
    const { toasts } = useToasterStore()

    useEffect(() => {
        toasts
            .filter(({ visible }) => visible)
            .filter((_, i) => i >= TOAST_LIMIT)
            .forEach(({ id }) => toast.dismiss(id))
    }, [toasts])

    return (
        <Toaster
            containerStyle={{
                top: '2.12rem',
            }}
            toastOptions={{
                className: 'toast-content',
                style: {
                    color: '#FFF',
                    fontSize: '15px',
                    fontWeight: 600,
                    borderRadius: '0.625rem',
                    border: '1px solid #414141',
                    background: 'rgba(47, 48, 50, 0.7)',
                    backdropFilter: 'blur(4px)',
                    width: 'max-content',
                    maxWidth: '100%',
                    padding: '5px 10px',
                    textAlign: 'center',
                },
            }}
        />
    )
}
