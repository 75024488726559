import { useState, useEffect, useMemo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { getItem, setItem, removeItem } from '../helpers/localStorage'
import { fxItems } from '../helpers/fx'
import { useTimeline } from '../hooks/useTimeline'
import { useZoomContext } from '../context/ZoomContext'
import { usePreMeetingContext } from '../context/PreMeetingContext'

export type BgType = {
    label: string
    deviceId: string
    key: undefined
    type: 'option'
    scope: 'public' | string[]
}

export const useBackgroundEffects = (isPreMeeting = false) => {
    const { t } = useTranslation('meeting')
    const { mediaStream, zoomClient } = useZoomContext()
    const { localVideoTrack: video, isInVBMode, setIsInVBMode, canvasRef, videoRef } = usePreMeetingContext()
    const { _courseId } = useTimeline()

    // Always start with effects disabled in pre-meeting
    const [isFx, setIsFx] = useState(isPreMeeting ? false : !!getItem('vfx'))
    const [bgs, setBgs] = useState<BgType[]>([])
    const [selectedEffect, setSelectedEffect] = useState<string | undefined>(isPreMeeting ? undefined : getItem('vfx'))

    const menuItems = useMemo(() => {
        return fxItems.map(item => ({
            ...item,
            label: t(item.label),
        }))
    }, [t])

    useEffect(() => {
        if (_courseId) {
            const filteredBgs = menuItems.filter(
                bg => bg.scope === 'public' || (Array.isArray(bg.scope) && bg.scope.includes(_courseId))
            )
            setBgs(filteredBgs)
        } else {
            const filteredBgs = menuItems.filter(bg => bg.scope === 'public')
            setBgs(filteredBgs)
        }
    }, [_courseId, menuItems])

    const switchEffect = useCallback(
        async (effect: string) => {
            if (isPreMeeting) {
                if (isInVBMode) {
                    if (effect === 'disable-effect') {
                        await video.stop()
                        setIsInVBMode(false)
                        removeItem('vfx')
                        setIsFx(false)
                        setSelectedEffect(undefined)
                        return
                    } else {
                        await video
                            .updateVirtualBackground(effect)
                            .then(() => {
                                setItem('vfx', effect)
                                setIsFx(true)
                                setSelectedEffect(effect)
                            })
                            .catch(error => {
                                console.log('Error updating virtual background', error)
                            })
                    }
                } else {
                    if (effect === 'disable-effect') {
                        return
                    } else {
                        if (canvasRef.current) {
                            video.isVideoStarted && (await video.stop())
                            setIsInVBMode(true)
                            setItem('vfx', effect)
                            setIsFx(true)
                            setSelectedEffect(effect)
                        }
                    }
                }
            } else {
                if (effect === 'disable-effect') {
                    mediaStream?.updateVirtualBackgroundImage(undefined)
                    removeItem('vfx')
                    setIsFx(false)
                    setSelectedEffect(undefined)
                } else {
                    await mediaStream?.updateVirtualBackgroundImage(effect)
                    setItem('vfx', effect)
                    setIsFx(true)
                    setSelectedEffect(effect)
                }
            }
        },
        [isPreMeeting, isInVBMode, video, setIsInVBMode, canvasRef, mediaStream]
    )

    const handleSetFxClick = () => {
        const vfx: string | undefined = getItem('vfx')
        if (!isFx) {
            switchEffect(vfx || 'blur')
        } else {
            switchEffect('disable-effect')
        }
    }

    const onFxClick = (fx: BgType) => {
        switchEffect(fx.deviceId)
    }

    return {
        isFx,
        bgs,
        handleSetFxClick,
        onFxClick,
        isVirtualBackgroundSupported: isPreMeeting
            ? zoomClient?.getMediaStream().isSupportVirtualBackground() ?? false
            : mediaStream?.isSupportVirtualBackground() ?? false,
        selectedEffect,
    }
}
