import { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useZoomContext } from '../../../context/ZoomContext'
import { RecordingStatus } from '@zoom/videosdk'
import { useUI } from '../../../context/UIContext'
import ToastNotification from '../components/Misc/ToastNotification'
import { useSessionParams } from '../../../hooks/useSessionParams'

const useRecording = () => {
    const { t } = useTranslation('meeting')
    const { room } = useSessionParams()
    const { recordingClient, setIsRecording, commandChannel, zoomClient } = useZoomContext()
    const { showToast } = useUI()

    const handleRecording = useCallback(async () => {
        const recordingStatus = recordingClient?.getCloudRecordingStatus()
        const isTeamsRoom = room === 'teams'

        switch (recordingStatus) {
            case RecordingStatus.Recording:
                await recordingClient?.stopCloudRecording()
                console.log(t('recording.stoppedLog'))
                commandChannel?.send(t('recording.coachStoppedRecording'))
                if (!isTeamsRoom) {
                    showToast(t('recording.recordingStopped'))
                }
                setIsRecording(false)
                return
            case RecordingStatus.Stopped:
                if (!recordingClient?.canStartRecording()) return
                recordingClient?.startCloudRecording()
                commandChannel?.send(t('recording.coachStartedRecording'))
                if (!isTeamsRoom) {
                    showToast(
                        <ToastNotification
                            icon={['fas', 'circle']}
                            iconStyle={{ color: '#ff647c' }}
                            msg={t('recording.recordingInProgress')}
                        />,
                        {
                            id: 'record-start',
                        }
                    )
                }
                setIsRecording(true)
                console.log(t('recording.startedLog'))
                return
        }
    }, [commandChannel, recordingClient, setIsRecording, showToast, t, room])

    const onRecordingChange = useCallback(
        (payload?: RecordingStatus) => {
            const isTeamsRoom = room === 'teams'

            switch (payload) {
                case RecordingStatus.Recording:
                    setIsRecording(true)
                    if (!isTeamsRoom) {
                        showToast(
                            <ToastNotification
                                icon={['fas', 'circle']}
                                iconStyle={{ color: '#ff647c' }}
                                msg={
                                    zoomClient.isHost()
                                        ? t('recording.recordingInProgress')
                                        : t('recording.coachRecordingInProgress')
                                }
                            />,
                            {
                                id: 'record-start',
                            }
                        )
                    }
                    return
                case RecordingStatus.Stopped:
                    setIsRecording(false)
                    return
            }
        },
        [setIsRecording, showToast, zoomClient, t, room]
    )

    useEffect(() => {
        zoomClient.on('recording-change', onRecordingChange)
        return () => {
            zoomClient.off('recording-change', onRecordingChange)
        }
    }, [zoomClient, onRecordingChange])

    return {
        handleRecording,
    }
}

export default useRecording
