import { ApiHCA } from '../../apis/api-hca'
import Axios from 'axios'
import { getSessionParameters } from '../../helpers/urls'
import jwtDecode from 'jwt-decode'

const ApiStats = Axios.create({
    baseURL: process.env.REACT_APP_STATS_API,
})

export const getZoomToken = async ({
    _classId,
    _profileId,
    ...rider
}: GetZoomTokenProps): Promise<GetZoomTokenResponse> =>
    ApiHCA.post(`sdk_session/sessionId?course=${_classId}&user_id=${_profileId}&${getRiderQuery(rider)}`, {})
        .then(({ data }) => data)
        .catch(() => ({}))

const getRiderQuery = ({ tableType = 't', tableNumber = 0, role }: Partial<GetZoomTokenProps>) =>
    `rider_table=${tableType}_${Math.max(0, tableNumber - 1)}&rider_flag=${role !== 'student'}`

export const sendZoomEvents = (props: SendEventsProps) =>
    ApiStats.post(`/zoom/events`, props, {
        headers: {
            'access-token': getSessionParameters().widgetToken ?? '',
        },
    }).catch(() => ({}))

export const sendQosFeedback = (props: SendQosFeedbackProps) =>
    ApiHCA.post(`sdk_session/qos_feedback`, props).catch(() => ({}))

export const getGeneralData = async (type: MeetingRoom) => {
    const { widgetToken } = getSessionParameters()
    const { _classId, _profileId } = jwtDecode<{ _classId: string; _profileId: string }>(widgetToken ?? '')

    const { data } = await ApiHCA.post<GetZoomTokenResponse>(
        `sdk_session/sessionId?course=${_classId}&user_id=${_profileId}&general_meeting=${type === 'general'}`
    )
    return data.general_session
}

export const joinMeeting = (meetingToken: string) => {
    const { widgetToken = '', name } = getSessionParameters()
    const { tpc, room } = decodeMeetingToken(meetingToken)

    sessionStorage.setItem('meeting-type', room)

    window.location.href = `${window.location.origin}/meeting?topic=${tpc}&zoomToken=${meetingToken}&widgetToken=${widgetToken}&name=${name}`
}

export const decodeMeetingToken = (meetingToken: string) => {
    const { tpc, ...rest } = jwtDecode<MeetingTokenPayload>(meetingToken)

    return {
        tpc,
        room: tpc.includes('@') ? 'teams' : 'general',
        ...rest,
    } as const
}

type GetZoomTokenProps = {
    general_session?: {
        data: {
            general_meet: string
        }
        meetingType: 'zoom-sdk' | 'zoom-sdk-auto'
        method: 'manual'
    }
    _classId: string
    _profileId: string
    role: string
    tableNumber?: number
    tableType?: 't' | 'm' // Normal table or mentor table
}

interface GetZoomTokenResponse {
    general_session?: {
        data: {
            generalMeet: string
        }
        meetingType: 'zoom-sdk' | 'zoom-sdk-auto'
        method: 'manual'
    }
    token: string
    topic: string
    password: string | null
}

interface SendEventsProps {
    media: Partial<ZoomEventDTO>
    timestamp: number
    meetingUUID: string
}

interface SendQosFeedbackProps {
    profile_id: string
    session_id: string
    score: number
    comment: string | null
}

export interface ZoomEventDTO {
    video: {
        state: boolean
    }
    audio: {
        state: boolean
    }
    speaker: {
        state: 'started' | 'stopped'
    }
}

export type ZoomEvents = Partial<{
    [Key in keyof ZoomEventDTO]: ZoomEventDTO[Key]['state']
}>

export type MeetingRoom = 'teams' | 'general'

interface MeetingTokenPayload {
    app_key: string
    tpc: string
    role_type: string
    session_key: string
    user_identity: string
    version: number
    iat: number
    exp: number
    room?: MeetingRoom
}
