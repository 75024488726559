import { useCallback, useEffect, useRef } from 'react'
import { usePreMeetingContext } from '../../../context/PreMeetingContext'

interface UseVideoPlaybackProps {
    isVideoReady: boolean
    setIsVideoReady: (ready: boolean) => void
}

export const useVideoPlayback = ({ isVideoReady, setIsVideoReady }: UseVideoPlaybackProps) => {
    // Stores the latest timeout ID for cleanup
    const timeoutRef = useRef<NodeJS.Timeout>()
    const { videoRef, isInVBMode } = usePreMeetingContext()

    const playVideo = useCallback(async () => {
        if (!videoRef.current || !isVideoReady) return

        try {
            await new Promise<void>(resolve => {
                timeoutRef.current = setTimeout(async () => {
                    try {
                        if (videoRef.current) {
                            const playPromise = videoRef.current.play()
                            if (playPromise) {
                                await playPromise
                            }
                        }
                    } catch (error) {
                        console.error('Error during video playback:', error)
                    } finally {
                        resolve()
                    }
                }, 100)
            })
        } catch (error) {
            console.error('Error in video playback promise:', error)
        }
    }, [isVideoReady, videoRef])

    // Handle video playback when ready
    useEffect(() => {
        if (isVideoReady && !isInVBMode) {
            playVideo()
        }

        // Cleanup timeout on unmount or when dependencies change
        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current)
            }
        }
    }, [isVideoReady, isInVBMode, playVideo])

    // Handle video element events
    useEffect(() => {
        const videoElement = videoRef.current
        if (!videoElement) return

        const handleCanPlay = () => setIsVideoReady(true)
        const handleError = (error: ErrorEvent) => {
            console.error('Video element error:', error)
            setIsVideoReady(false)
        }

        videoElement.addEventListener('canplay', handleCanPlay)
        videoElement.addEventListener('error', handleError)

        return () => {
            videoElement.removeEventListener('canplay', handleCanPlay)
            videoElement.removeEventListener('error', handleError)
        }
    }, [videoRef, setIsVideoReady])

    return {
        playVideo,
    }
}
