import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useRemainingTime } from '../hooks/useRemainingTime'

const TimerContainer = styled.div`
    position: fixed;
    top: 1rem;
    right: 1rem;
    padding: 0.75rem 1rem;
    background: rgba(0, 0, 0, 0.75);
    border-radius: 8px;
    color: white;
    /* font-family: monospace; */
    z-index: 1000;
`

const TimeText = styled.div`
    font-size: 1rem;
    color: #007bff;
`

const StatusText = styled.div`
    font-size: 0.875rem;
    color: #fff;
    margin-top: 0.1rem;
`

const TeamsReadyTimer = () => {
    const { t } = useTranslation('onboarding')
    const { remainingTime, teamsReadyTime, isTimeUp } = useRemainingTime()

    const formatTime = (seconds: number | null): string => {
        if (seconds === null || seconds <= 0) return '0:00'
        const mins = Math.floor(seconds / 60)
        const secs = Math.floor(seconds % 60)
        return t('teamsTimer.timeFormat', {
            minutes: mins,
            seconds: secs.toString().padStart(2, '0'),
        })
    }

    const formatTeamsReadyTime = (timestamp: number | null): string => {
        if (!timestamp) return ''
        const date = new Date(timestamp)
        return date.toLocaleTimeString('en-US', {
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
        })
    }

    if (!teamsReadyTime) return null

    return (
        <TimerContainer>
            {isTimeUp ? (
                <>
                    <TimeText>{t('teamsTimer.ready')}</TimeText>
                    <StatusText>{t('teamsTimer.completeToJoin')}</StatusText>
                </>
            ) : (
                <>
                    <TimeText>{formatTime(remainingTime)}</TimeText>
                    <StatusText>
                        {t('teamsTimer.readyAt', {
                            time: formatTeamsReadyTime(teamsReadyTime),
                        })}
                    </StatusText>
                </>
            )}
        </TimerContainer>
    )
}

export default TeamsReadyTimer
